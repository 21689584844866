import React from 'react';
import axios from 'axios';
import './StartListXC.css';
class StartListXC extends React.Component {

    state = {
        meetName: this.props.meetName,
        session: this.props.session,
        eventNumber: this.props.eventNumber,
        roundNumber: this.props.roundNumber,
        eventName: this.props.eventName,
        eventGender: undefined,
        teams: [],
        roster: [],
        eventRoster: []
    }

    componentDidMount() {

        this.setState({eventName: filterName(this.state.eventName)});

        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.props.meetName} }).then((response) => {
            this.setState({roster: response.data});
            this.setState({eventRoster: getEventRoster(response.data, this.state.eventNumber)});
        });

        axios.get("https://liveresults.trxctiming.com/api/teams", { params: {id: this.props.meetName} }).then(response => {
            this.setState({teams: response.data});
        });
    }

    componentDidUpdate(prevProps) {

        if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber){

            this.setState({session: this.props.session, eventNumber: this.props.eventNumber, roundNumber: this.props.roundNumber, eventName: filterName(this.props.eventName), teams: []});

            axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.props.meetName} }).then((response) => {
                this.setState({roster: response.data});
                this.setState({eventRoster: getEventRoster(response.data, this.state.eventNumber)});
            });

            axios.get("https://liveresults.trxctiming.com/api/teams", { params: {id: this.props.meetName} }).then(response => {
                this.setState({teams: response.data});
            });
        }
    }

    componentWillUnmount() {

    }

    render() {
        if(this.state.eventRoster.length>0){
            return <div className="startListScreenXC">
                <div className="eventNameXC">{"Entries for " + this.state.meetName.replaceAll('_', " ")}</div>
                <table className="startListTableXC">
                    <thead>
                        <th className="bibXC">Bib#</th>
                        <th className="logoColumnXC"></th>
                        <th className="athleteXC">Athlete</th>
                        <th className="xcTeam">Team</th>
                        <th className="genderXC">Gender</th>
                    </thead>
                    <tbody>{this.state.eventRoster.map(athlete => <tr>
                        <td className="bibXC">{athlete[0]}</td>
                        <td className="logoColumnXC"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} alt="" className="logoXC"></img></td>
                        <td className="athleteXC">{athlete[1] + ", " + athlete[2] + getGrade(athlete[0], this.state.roster)}</td>
                        <td className="xcTeam">{athlete[3]}</td>
                        <td className="genderXC">{athlete[4]}</td>
                    </tr>)}</tbody>
                </table>
            </div>
        }else{
            return <div className="startListScreenXC">
                <div className="eventNameXC">{"Entries for " + this.state.meetName.replaceAll('_', " ")}</div>
                <table className="startListTableXC">
                    <thead>
                        <th className="bibXC">Bib#</th>
                        <th className="logoColumnXC"></th>
                        <th className="athleteXC">Athlete</th>
                        <th className="xcTeam">Team</th>
                        <th className="genderXC">Gender</th>
                    </thead>
                    <tbody>{this.state.roster.map(athlete => <tr>
                        <td className="bibXC">{athlete[0]}</td>
                        <td className="logoColumnXC"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} alt="" className="logoXC"></img></td>
                        <td className="athleteXC">{athlete[1] + ", " + athlete[2] + getGrade(athlete[0], this.state.roster)}</td>
                        <td className="xcTeam">{athlete[3]}</td>
                        <td className="genderXC">{athlete[4]}</td>
                    </tr>)}</tbody>
                </table>
            </div>
        }
    }

}

function getEventRoster(_roster_, _eventNumber_){
    let updatedRoster = [];
    for(let i=0; i<_roster_.length; i++){
        if(parseInt(_roster_[i][5])===_eventNumber_){
            updatedRoster.push(_roster_[i]);
        }
    }
    return updatedRoster;
}

function filterName(_name_){
    let updatedName = _name_;
    if(updatedName.includes("(M)")){
        updatedName = updatedName.replace("(M)", "Men's");
    }else if(updatedName.includes("(W)")){
        updatedName = updatedName.replace("(W)", "Women's");
    }else if(updatedName.includes("(B)")){
        updatedName = updatedName.replace("(B)", "Boys'");
    }else if(updatedName.includes("(G)")){
        updatedName = updatedName.replace("(G)", "Girls'");
    }
    if(updatedName.includes("CC")){
        updatedName = updatedName.replace("CC", "Cross Country");
    }
    return updatedName + " - Start List";
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return "  (" + grade + ")";
        }
    }
    return "";
}

export default StartListXC;
