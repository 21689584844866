import React from 'react';
import axios from 'axios';
import './LiveResultsField.css';
import './StartListField.css';

class LiveResultsField extends React.Component {

    state = {
        meetName: this.props.meetName,
        session: this.props.session,
        eventNumber: this.props.eventNumber,
        roundNumber: this.props.roundNumber,
        eventName: this.props.eventName,
        eventGender: this.props.eventGender,
        divisions: this.props.divisions,
        eventRecord: this.props.eventRecord,
        teams: [],
        totalFlights: 0,
        status: 0,
        system: undefined,
        headerAttempts : ["Attempt 1", "Attempt 2", "Attempt 3"],
        results: [],
        roster: [],
        entries: []
    }

    componentDidMount() {
        this.setState({eventName: filterName(this.state.eventName)});

        axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.state.meetName, event: this.state.eventNumber, round: this.state.roundNumber, session: this.state.session} }).then((response) => {
            this.setState({entries: response.data});
        });

        axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Field"} }).then((response) => {
            if(response.data.length>0){
                this.setState({system: response.data[0][5]});
                let maxAttempts = getMaxAttempts(response.data);
                let totalFlights = getFlights(response.data);
                if(maxAttempts>3){
                    let updatedAttempts = [];
                    for(let i=0; i<maxAttempts; i++){
                        updatedAttempts[i] = "Attempt " + (i+1);
                    }
                    this.setState({headerAttempts: updatedAttempts})
                }
                this.setState({results: filterData(response.data, maxAttempts), totalFlights: totalFlights});
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});
        });

        axios.get("https://liveresults.trxctiming.com/api/teams", { params: {id: this.state.meetName} }).then(response => {
            this.setState({teams: response.data});
        });

        //set interval for live results updates
        this.interval = setInterval(() => {

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0 && response.data.length>=this.state.results.length){
                    this.setState({system: response.data[0][5]});
                    let maxAttempts = getMaxAttempts(response.data);
                    let totalFlights = getFlights(response.data);
                    if(maxAttempts>3){
                        let updatedAttempts = [];
                        for(let i=0; i<maxAttempts; i++){
                            updatedAttempts[i] = "Attempt " + (i+1);
                        }
                        this.setState({headerAttempts: updatedAttempts})
                    }
                    this.setState({results: filterData(response.data, maxAttempts), totalFlights: totalFlights});
                }
            });

        }, 15000); //15s

    }

    componentDidUpdate(prevProps) {
        if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber){

            this.setState({session: this.props.session, eventNumber: this.props.eventNumber, roundNumber: this.props.roundNumber, divisions: this.props.divisions, eventName: filterName(this.props.eventName), headerAttempts : ["Attempt 1", "Attempt 2", "Attempt 3"], results: [], totalFlights: 0, status: 0, eventGender: this.props.eventGender, eventRecord: this.state.eventRecord});

            axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.props.meetName, event: this.props.eventNumber, round: this.props.roundNumber, session: this.props.session} }).then((response) => {
                this.setState({entries: response.data});
            });

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.props.meetName, session: this.props.session, event: this.props.eventNumber, round: this.props.roundNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0){
                    this.setState({system: response.data[0][5]});
                    let maxAttempts = getMaxAttempts(response.data);
                    let totalFlights = getFlights(response.data);
                    if(maxAttempts>3){
                        let updatedAttempts = [];
                        for(let i=0; i<maxAttempts; i++){
                            updatedAttempts[i] = "Attempt " + (i+1);
                        }
                        this.setState({headerAttempts: updatedAttempts})
                    }
                    this.setState({results: filterData(response.data, maxAttempts), totalFlights: totalFlights});
                }
            });

        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return <div className="eventResultsField">
            <p className="eventNameField">{this.state.eventName}</p>
            <div className="eventRecordXC" style={{whiteSpace: 'pre-line'}}>{filterRecord(this.state.eventRecord)}</div>
            {getHeaderButtons(this)}
            {this.state.status===-1 ? getStartListTable(this.state.entries, this.state.roster) : getResultsTable(this.state.headerAttempts, this.state.results, this.state.status, this.state.roster, this.state.system, this.state.eventName, this.state.totalFlights, this.state.teams, this.state.eventGender, this.state.divisions)}
        </div>
    }

}

function filterRecord(_record_){
    let updatedRecord = "";
    if(_record_===undefined || _record_==="" || _record_==="NULL"){
        return updatedRecord;
    }
    if(_record_[_record_.length-1]===';'){
        updatedRecord = _record_.substring(0, _record_.length-1);
    }
    return updatedRecord.replaceAll(';', '\n');
}

function getHeaderButtons(_this_){
    return <table className="heatButtonsDesktop"><tr>
        <td className="heatButtonDesktop" onClick={() => _this_.setState({status: -1})}>{'Start List'}</td>
        <td className="heatButtonDesktop" onClick={() => _this_.setState({status: 0})}>{'Compiled'}</td>
        {_this_.state.totalFlights>1 ? <td className="heatButtonDesktop" onClick={() => _this_.setState({status: 1})}>{'By Flight'}</td> : null}
        {_this_.state.divisions.split(',').length>1 && _this_.state.divisions.toUpperCase()!=="JV,Varsity" ? <td className="heatButtonDesktop" onClick={() => _this_.setState({status: 2})}>{'By Division'}</td> : null}
    </tr></table>;
}

function getStartListTable(_entries_, _roster_){
    return <table className="startListTableField">
        <thead>
            <th className="flightField">Flight</th>
            <th className="logoColumnField"></th>
            <th className="athleteField">Athlete</th>
            <th className="fieldTeam">Team</th>
            {getEntryHeader(_entries_)}
        </thead>
        <tbody>{_entries_.map(entry => <tr>
            <td className="flightField">{entry[3] + '-' + entry[6]}</td>
            <td className="logoColumnField"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + entry[8] + ".png"} alt={entry[8]} className="logoField"></img></td>
            <td className="athleteField">{entry[7] + getGrade(entry[5], _roster_)}</td>
            <td className="fieldTeam">{entry[8]}</td>
            {getEntry(entry[9])}
        </tr>)}</tbody>
    </table>;
}

function getResultsTable(_headerAttempts_, _results_, _status_, _roster_, _system_, _eventName_, _totalFlights_, _teams_, _gender_, _divisions_){
  if(_status_===0){
    return <table className="resultTableField">
            <thead>
                <th className="placeField">Place</th>
                <th className="flightField">Flight</th>
                <th className="logoColumnField"></th>
                <th className="athleteField">Athlete</th>
                {_headerAttempts_.map( attempt => <th className="attemptFieldDesktop">{attempt}</th> )}
                <th className="markField">Mark</th>
            </thead>
            <tbody>{_results_.map( athlete => <tr className="resultTableFieldRow">
                <td className="placeField">{athlete[0]}</td>
                <td className="flightField">{athlete[1] + '-' + athlete[2]}</td>
                <td className="logoColumnField"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} className="logoField" alt=""></img></td>
                <td className="athleteFieldDesktop"><table>
                    <tr><td className="athleteRowName">{athlete[4] + getGrade(athlete[7], _roster_)}</td></tr>
                    <tr><td className="athleteRowTeam">{athlete[3]}</td></tr>
                </table></td>
                {athlete[5].map( attempt => <td className="attemptFieldDesktop"  style={getHighlight(getAttemptMark(attempt), getMark(athlete[0], athlete[6]))}><table>
                    <tr><td className="markRowDesktop">{getAttemptMark(attempt)}</td></tr>
                    <tr><td className="attemptConversion">{getMarkConversion(athlete[0], attempt, _system_, _eventName_)}</td></tr>
                    <tr><td className="windRowDesktop">{getAttemptWind(attempt)}</td></tr>
                </table></td> )}
                <td><table>
                    <tr><td className="markField">{getMark(athlete[0], athlete[6])}</td></tr>
                    <tr><td className="markFieldConversion">{getMarkConversion(athlete[0], athlete[6], _system_, _eventName_)}</td></tr>
                </table></td>
            </tr>)}</tbody>
      </table>
  }else if(_status_===1){
      let _byFlightResults_ = [];
      for(let i=1; i<=_totalFlights_; i++){
          _byFlightResults_[i-1] = filterByFlight(_results_, i);
      }
      return _byFlightResults_.map( (flightResults, index) => <div><button className="flightButton" onClick={() => displayHeat(index)}>{'Flight ' + (index+1)}</button>
          <div className="heatResults" id={index} style={{display: 'block'}}>
              <table className="resultTableField">
                  <thead>
                      <th className="placeField">Place</th>
                      <th className="flightField">Flight</th>
                      <th className="logoColumnField"></th>
                      <th className="athleteField">Athlete</th>
                      {_headerAttempts_.map( attempt => <th className="attemptFieldDesktop">{attempt}</th>)}
                      <th className="markField">Mark</th>
                  </thead>
                  <tbody>{flightResults.map( athlete => <tr className="resultTableFieldRow">
                      <td className="placeField">{athlete[0]}</td>
                      <td className="flightField">{athlete[1] + '-' + athlete[2]}</td>
                      <td className="logoColumnField"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} className="logoField" alt=""></img></td>
                      <td className="athleteFieldDesktop"><table>
                          <tr><td className="athleteRowName">{athlete[4] + getGrade(athlete[7], _roster_)}</td></tr>
                          <tr><td className="athleteRowTeam">{athlete[3]}</td></tr>
                      </table></td>
                      {athlete[5].map( attempt => <td className="attemptFieldDesktop"  style={getHighlight(getAttemptMark(attempt), getMark(athlete[0], athlete[6]))}><table>
                          <tr><td className="markRowDesktop">{getAttemptMark(attempt)}</td></tr>
                          <tr><td className="attemptConversion">{getMarkConversion(athlete[0], attempt, _system_, _eventName_)}</td></tr>
                          <tr><td className="windRowDesktop">{getAttemptWind(attempt)}</td></tr>
                      </table></td> )}
                      <td><table>
                          <tr><td className="markField">{getMark(athlete[0], athlete[6])}</td></tr>
                          <tr><td className="markFieldConversion">{getMarkConversion(athlete[0], athlete[6], _system_, _eventName_)}</td></tr>
                      </table></td>
                  </tr>)}</tbody>
              </table>
          </div>
      </div>);
  }else if(_status_===2){
    let _byDivisionResults_ = [];
    for(let i=1; i<=_divisions_.split(',').length; i++){
        _byDivisionResults_[i-1] = filterByDivision(_results_, i, _teams_, _gender_);
    }
    return _byDivisionResults_.map( (flightResults, index) => <div><button className="flightButton" onClick={() => displayHeat(index)}>{_divisions_.split(',')[index]}</button>
        <div className="heatResults" id={index} style={{display: 'block'}}>
            <table className="resultTableField">
                <thead>
                    <th className="placeField">Place</th>
                    <th className="flightField">Flight</th>
                    <th className="logoColumnField"></th>
                    <th className="athleteField">Athlete</th>
                    {_headerAttempts_.map( attempt => <th className="attemptFieldDesktop">{attempt}</th>)}
                    <th className="markField">Mark</th>
                </thead>
                <tbody>{flightResults.map( athlete => <tr className="resultTableFieldRow">
                    <td className="placeField">{getDivisionPlace(flightResults, athlete[0])}</td>
                    <td className="flightField">{athlete[1] + '-' + athlete[2]}</td>
                    <td className="logoColumnField"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} className="logoField" alt=""></img></td>
                    <td className="athleteFieldDesktop"><table>
                        <tr><td className="athleteRowName">{athlete[4] + getGrade(athlete[7], _roster_)}</td></tr>
                        <tr><td className="athleteRowTeam">{athlete[3]}</td></tr>
                    </table></td>
                    {athlete[5].map( attempt => <td className="attemptFieldDesktop"  style={getHighlight(getAttemptMark(attempt), getMark(athlete[0], athlete[6]))}><table>
                        <tr><td className="markRowDesktop">{getAttemptMark(attempt)}</td></tr>
                        <tr><td className="attemptConversion">{getMarkConversion(athlete[0], attempt, _system_, _eventName_)}</td></tr>
                        <tr><td className="windRowDesktop">{getAttemptWind(attempt)}</td></tr>
                    </table></td> )}
                    <td><table>
                        <tr><td className="markField">{getMark(athlete[0], athlete[6])}</td></tr>
                        <tr><td className="markFieldConversion">{getMarkConversion(athlete[0], athlete[6], _system_, _eventName_)}</td></tr>
                    </table></td>
                </tr>)}</tbody>
            </table>
        </div>
    </div>);
  }
}

function displayHeat(_heat_){
    if(document.getElementById(_heat_).style.display==="block"){
        document.getElementById(_heat_).style.display = "none";
    }else if(document.getElementById(_heat_).style.display==="none"){
        document.getElementById(_heat_).style.display = "block";
    }
    return;
}

function getDivisionPlace(_flightResults_, _place_){
    if(_place_==="-" || _place_==="DNS"){
        return _place_;
    }
    let place = 1;
    for(let i=0; i<_flightResults_.length; i++){
        if(_flightResults_[i][0]===_place_){
            return place;
        }else{
            place++;
        }
    }
    return _place_;
}

function getHighlight(_cMark, _bestMark_){
    if(_cMark===_bestMark_){
      return {'background-color':'yellow'};
    }
    return {'background-color':'lightyellow'};
}

function getEntryHeader(_entries_){
    if(_entries_.length<=0){
        return;
    }
    if(_entries_[0][9]!==undefined && _entries_[0][9]!=="" && _entries_[0][9]!=="NULL" && _entries_[0][9]!==null){
        return <th className="fieldEntry">Entry Mark</th>;
    }else {
        return;
    }
}

function getEntry(_entry_){
      if(_entry_!==undefined && _entry_!=="" && _entry_!=="NULL" && _entry_!==null){
          return <td className="fieldEntry">{_entry_}</td>;
      }
}

function filterByFlight(_results_, _flight_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][1]===_flight_ || _flight_===0){
          updatedResults.push(_results_[i]);
        }
    }
    return updatedResults;
}

function filterByDivision(_results_, _division_, _teams_, _gender_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        if(checkTeamDivision(_results_[i][3], _teams_, _division_, _gender_)){
          updatedResults.push(_results_[i]);
        }
    }
    return updatedResults;
}

function checkTeamDivision(_team_, _teams_, _division_, _gender_){
    let gender = '';
    if(_gender_==="Women"){
        gender = 'F';
    }else if(_gender_==="Men"){
        gender = 'M';
    }
    for(let i=0; i<_teams_.length; i++){
        if(_teams_[i][0]===_team_ && _teams_[i][1]===gender && _teams_[i][2]===_division_){
            return true;
        }
    }
    return false;
}

function getFlights(_results_){
    let totalFlights = 0;
    //let flightArray = [];
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][3]>totalFlights){
            totalFlights = _results_[i][3];
        }
    }
    /*flightArray.push("Start List");
    flightArray.push("Compiled");
    for (let i=1; i<=totalFlights; i++){
        flightArray.push("Flight " + i);
    }*/
    return totalFlights;
}

function getMarkConversion(_place_, _bestMark_, _system_, _eventName_){
    if(_place_==="" || _place_==="-" || _place_===null || _place_===undefined || _place_==="DNS" || _bestMark_==="X" || _bestMark_==="F" || _bestMark_==="-" || _bestMark_==="P"){
        return;
    }
    if(_system_==="Metric"){
        let englishMark = Math.floor((parseFloat(_bestMark_) + 0.004999) * 39.3700787402 * 100) / 100;
        let englishMarkString = "";

        let markFeet = parseInt(englishMark/12);
        let markInches = parseFloat(englishMark-markFeet*12).toFixed(2);

        if(markInches-parseInt(markInches)>0.75){
            markInches = markInches - (markInches - parseInt(markInches) - 0.75);
        }else if(markInches-parseInt(markInches)<0.75 && markInches-parseInt(markInches)>0.50){
            markInches = markInches - (markInches - parseInt(markInches) - 0.50);
        }else if(markInches-parseInt(markInches)<0.50 && markInches-parseInt(markInches)>0.25){
            markInches = markInches - (markInches - parseInt(markInches) - 0.25);
        }else if(markInches-parseInt(markInches)<0.25 && markInches-parseInt(markInches)>0.00){
            markInches = markInches - (markInches - parseInt(markInches));
        }

        if(!isNaN(markFeet) && !isNaN(markInches)){
            if(_eventName_.toUpperCase().includes("DISC") || _eventName_.toUpperCase().includes("JAV") || _eventName_.toUpperCase().includes("HAMMER")){
                englishMarkString = markFeet.toString() + "-" + parseFloat(markInches).toFixed(0);
                return englishMarkString;
            }else{
                englishMarkString = markFeet.toString() + "-" + parseFloat(markInches).toFixed(2);
                return englishMarkString;
            }
        }
    }
    return "";
}

function getMark(_place_, _bestMark_){
    if(_place_==="" || _place_==="-" || _place_===null || _place_===undefined || _place_==="DNS"){
        return "NM";
    }
    return _bestMark_;
}

function filterName(_name_){

    let updatedName = _name_;
    if(updatedName.includes("(M)")){
        updatedName = updatedName.replace("(M)", "Men's");
    }else if(updatedName.includes("(W)")){
        updatedName = updatedName.replace("(W)", "Women's");
    }else if(updatedName.includes("(B)")){
        updatedName = updatedName.replace("(B)", "Boys'");
    }else if(updatedName.includes("(G)")){
        updatedName = updatedName.replace("(G)", "Girls'");
    }
    return updatedName + " - Results";
}

function filterData(_results_, _maxAttempts_) {

    let filteredData = [];
    _results_.map( athlete => filteredData.push([getFieldPlace(athlete[7]), athlete[3], athlete[10], athlete[12], athlete[11], getAttempts(athlete[7], athlete[13], _maxAttempts_), getBestMark(athlete[7], athlete[13], athlete[5]), athlete[9]]) );
    return filteredData;
}

function getFieldPlace(_place_) {

    if(_place_===999999) {
        return "DNS";
    }else if(_place_===999998) {
        return "-";
    }else{
        return _place_;
    }
}

function getAttempts(_place_, _attempts_, _maxAttempts_) {

    let updatedAttempts = [];
    if(_place_===999999){
        for(let i=0; i<_maxAttempts_; i++){
            updatedAttempts[i] = "";
        }
        return updatedAttempts;
    }else{
        let attempts = _attempts_.replaceAll('F', 'X').split(',');
        for(let i=0; i<attempts.length; i+=2){
            if(attempts[i+1]==="-0.0" || attempts[i]==="F" || attempts[i]==="X" || attempts[i]==="-" || attempts[i]==="P"){
                if(attempts[i]!==undefined){
                    updatedAttempts[i/2] = attempts[i];
                }
            }else{
                if(attempts[i]!==undefined && attempts[i+1]!==undefined){
                    updatedAttempts[i/2] = attempts[i] + " (" + attempts[i+1] + ")";
                }
            }
        }
        if(updatedAttempts.length<_maxAttempts_){
            for(let i=updatedAttempts.length; i<_maxAttempts_; i++){
                updatedAttempts[i] = "";
            }
        }
    }
    return updatedAttempts;
}

function getBestMark(_place_, _attempts_, _system_) {
    if(_system_!=="English"){
        let bestMark = "0";
        let attempts = _attempts_.split(',');
        if(_place_===999999){
            return "";
        }
        for(let i=0; i<attempts.length; i+=2){
            let cAttempt = parseFloat(attempts[i]);
            let best =  parseFloat(bestMark);
            if(cAttempt > best){
                bestMark = attempts[i];
            }
        }
        if(bestMark==="0"){
            return "";
        }
        return bestMark;
    }else{
        let bestMark = "0";
        let attempts = _attempts_.split(',');
        if(_place_===999999){
            return "";
        }
        for(let i=0; i<attempts.length; i+=2){
            if(attempts[i]!=="F" && attempts[i]!=="-" && attempts[i]!=="P" && attempts[i]!=="X" && attempts[i]!==undefined && attempts[i]!==""){
                let cFeet = parseInt(attempts[i].substring(0, attempts[i].indexOf("-")));
                let cInches =  parseFloat(attempts[i].substring(attempts[i].indexOf("-")+1));
                let bFeet = parseInt(bestMark.substring(0, bestMark.indexOf("-")));
                let bInches =  parseFloat(bestMark.substring(bestMark.indexOf("-")+1));
                if(cFeet>bFeet || bestMark==="0"){
                    bestMark = attempts[i];
                }else if(cFeet===bFeet && cInches>bInches){
                    bestMark = attempts[i];
                }
            }
        }
        if(bestMark==="0"){
            return "";
        }
        return bestMark;
    }
}

function getMaxAttempts(_results_){
    let maxAttempts = 3;
    for(let i=0; i<_results_.length; i++){
        let attempts = _results_[i][13].split(',');
        if(attempts.length/2>maxAttempts && attempts.length/2>3 && attempts.length/2<7){
            maxAttempts = attempts.length/2;
        }
    }
    return maxAttempts;
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return "  (" + grade + ")";
        }
    }
    return "";
}

function getAttemptMark(_attempt_){
    if(_attempt_.includes(' ')){
        return _attempt_.substring(0, _attempt_.indexOf(' '));
    }
    return _attempt_;
}

function getAttemptWind(_attempt_){
    if(_attempt_.includes(' ')){
        return _attempt_.substring(_attempt_.indexOf(' ')+1);
    }
    return '';
}

export default LiveResultsField;
