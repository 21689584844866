import React from 'react';
import axios from 'axios';
import './TeamScoresPhone.css';

class TeamScoresPhone extends React.Component {

  state = {
      meetName: this.props.meetName,
      scoring: this.props.scoringInfo[2],
      maxScorers: this.props.scoringInfo[1],
      relayScoring: undefined,
      maleTeams: [[]],
      femaleTeams: [[]],
      divisions: this.props.divisions,
      loading: false
  }

  async componentDidMount() {

    if(this.state.scoring!==undefined && this.state.scoring.includes(";")){
        this.setState({relayScoring: this.state.scoring.substring(this.state.scoring.indexOf(";"))});
    }else{
        this.setState({relayScoring: this.state.scoring});
    }

    let maleTeams = [];
    let femaleTeams = [];

    await axios.get("https://liveresults.trxctiming.com/api/teams", { params: {id: this.state.meetName} }).then(response => {
        if(this.state.divisions==="JV,Varsity"){
            for(let i=0; i<2; i++){
                maleTeams.push(getTeams(response.data, 'M', 1));
                femaleTeams.push(getTeams(response.data, 'F', 1));
            }
        }else{
            for(let i=0; i<this.state.divisions.split(',').length; i++){
                maleTeams.push(getTeams(response.data, 'M', i+1));
                femaleTeams.push(getTeams(response.data, 'F', i+1));
            }
        }
    });

    await axios.get("https://liveresults.trxctiming.com/api/scoringSchedule", { params: {id: this.state.meetName} }).then(async (response) => {
        let schedule = response.data;

        if(schedule.length>0){
            for(let i=0; i<schedule.length; i++){
                let eventNumber = schedule[i][2];
                let lastRound = schedule[i][7];
                //let eventStatus = schedule[i][11];
                let eventType = schedule[i][10];
                let eventGender = schedule[i][5][1];
                if(eventGender!=="M" && eventGender!=="W" && eventGender!=="B" && eventGender!=="G"){
                    if(schedule[i][5].toUpperCase().includes("DEC") || schedule[i][5].toUpperCase().includes("HEP") || schedule[i][5].toUpperCase().includes("PEN")){
                        if(schedule[i][5].toUpperCase().includes("(W)") || schedule[i][5].toUpperCase().includes("(G)") || schedule[i][5].toUpperCase().includes("GIRLS") || schedule[i][5].toUpperCase().includes("WOMEN")){
                            eventGender = "W";
                        }else if(schedule[i][5].toUpperCase().includes("(M)") || schedule[i][5].toUpperCase().includes("(B)") || schedule[i][5].toUpperCase().includes("MEN") || schedule[i][5].toUpperCase().includes("BOYS")){
                            eventGender = "M";
                        }
                    }else{
                        eventGender = schedule[i][5][0];
                    }
                }
                let jv = false;
                if(schedule[i][5].toUpperCase().includes("JV") || schedule[i][5].toUpperCase().includes("JUNIOR VARSITY")){
                    jv = true;
                }
                let eventScoring = parseInt(schedule[i][12]);
                let eventStatus = parseInt(schedule[i][11]);
                if(eventScoring>0 && eventStatus>=3){
                    await axios.get("https://liveresults.trxctiming.com/api/scoringResults", { params: {id: this.state.meetName, event: eventNumber, lastRound: lastRound, type: eventType} }).then(async (response) => {
                        if( (eventGender==="M" || eventGender==="B") && eventType==="Track" && response.data.length>0 ){
                            if(eventScoring===1){
                                if(this.state.divisions==="JV,Varsity" && jv){
                                    maleTeams[0] = setTrackScores(maleTeams[0], this.state.scoring, this.state.maxScorers, response.data);
                                }else if(this.state.divisions==="JV,Varsity"){
                                    maleTeams[1] = setTrackScores(maleTeams[1], this.state.scoring, this.state.maxScorers, response.data);
                                }else{
                                    for(let i=0; i<maleTeams.length; i++){
                                        maleTeams[i] = setTrackScores(maleTeams[i], this.state.scoring, this.state.maxScorers, response.data);
                                    }
                                }
                            }else if(eventScoring===2){
                              if(this.state.divisions==="JV,Varsity" && jv){
                                  maleTeams[0] = setRelayScores(maleTeams[0], this.state.relayScoring, this.state.maxScorers, response.data);
                              }else if(this.state.divisions==="JV,Varsity"){
                                  maleTeams[1] = setRelayScores(maleTeams[1], this.state.relayScoring, this.state.maxScorers, response.data);
                              }else{
                                  for(let i=0; i<maleTeams.length; i++){
                                      maleTeams[i] = setRelayScores(maleTeams[i], this.state.relayScoring, this.state.maxScorers, response.data);
                                  }
                              }
                            }else if(eventScoring===3){
                                let trackMultiResults = [];
                                let fieldMultiResults = [];
                                await axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.state.meetName, event: eventNumber, type: "Field"} }).then(async (response) => {
                                    if(response.data.length>0){
                                        fieldMultiResults = filterFieldData(response.data);
                                    }
                                    await axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.state.meetName, event: eventNumber, type: "Track"} }).then((response) => {
                                        if(response.data.length>0){
                                          trackMultiResults = filterTrackData(response.data);
                                        }
                                        if(this.state.divisions==="JV,Varsity" && jv){
                                            maleTeams[0] = setMultisScores(maleTeams[0], this.state.scoring, this.state.maxScorers, trackMultiResults, fieldMultiResults);
                                        }else if(this.state.divisions==="JV,Varsity"){
                                            maleTeams[1] = setMultisScores(maleTeams[1], this.state.scoring, this.state.maxScorers, trackMultiResults, fieldMultiResults);
                                        }else{
                                            for(let i=0; i<maleTeams.length; i++){
                                                maleTeams[i] = setMultisScores(maleTeams[i], this.state.scoring, this.state.maxScorers, trackMultiResults, fieldMultiResults);
                                            }
                                        }
                                    });
                                });
                            }
                        }else if( (eventGender==="M" || eventGender==="B") && eventType==="Field" && response.data.length>0 ){
                            if(eventScoring===1){
                                if(this.state.divisions==="JV,Varsity" && jv){
                                    maleTeams[0] = setFieldScores(maleTeams[0], this.state.scoring, this.state.maxScorers, response.data);
                                }else if(this.state.divisions==="JV,Varsity"){
                                    maleTeams[1] = setFieldScores(maleTeams[1], this.state.scoring, this.state.maxScorers, response.data);
                                }else{
                                    for(let i=0; i<maleTeams.length; i++){
                                        maleTeams[i] = setFieldScores(maleTeams[i], this.state.scoring, this.state.maxScorers, response.data);
                                    }
                                }
                            }
                        }else if( (eventGender==="W" || eventGender==="G") && eventType==="Track" && response.data.length>0 ){
                            if(eventScoring===1){
                                if(this.state.divisions==="JV,Varsity" && jv){
                                    femaleTeams[0] = setTrackScores(femaleTeams[0], this.state.scoring, this.state.maxScorers, response.data);
                                }else if(this.state.divisions==="JV,Varsity"){
                                    femaleTeams[1] = setTrackScores(femaleTeams[1], this.state.scoring, this.state.maxScorers, response.data);
                                }else{
                                    for(let i=0; i<femaleTeams.length; i++){
                                        femaleTeams[i] = setTrackScores(femaleTeams[i], this.state.scoring, this.state.maxScorers, response.data);
                                    }
                                }
                            }else if(eventScoring===2){
                                if(this.state.divisions==="JV,Varsity" && jv){
                                    femaleTeams[0] = setRelayScores(femaleTeams[0], this.state.relayScoring, this.state.maxScorers, response.data);
                                }else if(this.state.divisions==="JV,Varsity"){
                                    femaleTeams[1] = setRelayScores(femaleTeams[1], this.state.relayScoring, this.state.maxScorers, response.data);
                                }else{
                                    for(let i=0; i<femaleTeams.length; i++){
                                        femaleTeams[i] = setRelayScores(femaleTeams[i], this.state.relayScoring, this.state.maxScorers, response.data);
                                    }
                                }
                            }else if(eventScoring===3){
                                let trackMultiResults = [];
                                let fieldMultiResults = [];
                                await axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.state.meetName, event: eventNumber, type: "Field"} }).then(async (response) => {
                                    if(response.data.length>0){
                                        fieldMultiResults = filterFieldData(response.data);
                                    }
                                    await axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.state.meetName, event: eventNumber, type: "Track"} }).then((response) => {
                                        if(response.data.length>0){
                                          trackMultiResults = filterTrackData(response.data);
                                        }
                                        if(this.state.divisions==="JV,Varsity" && jv){
                                            femaleTeams[0] = setMultisScores(femaleTeams[0], this.state.scoring, this.state.maxScorers, trackMultiResults, fieldMultiResults);
                                        }else if(this.state.divisions==="JV,Varsity"){
                                            femaleTeams[1] = setMultisScores(femaleTeams[1], this.state.scoring, this.state.maxScorers, trackMultiResults, fieldMultiResults);
                                        }else{
                                            for(let i=0; i<femaleTeams.length; i++){
                                                femaleTeams[i] = setMultisScores(femaleTeams[i], this.state.scoring, this.state.maxScorers, trackMultiResults, fieldMultiResults);
                                            }
                                        }
                                    });
                                });
                            }
                        }else if( (eventGender==="W" || eventGender==="G") && eventType==="Field" && response.data.length>0 ){
                            if(eventScoring===1){
                                if(this.state.divisions==="JV,Varsity" && jv){
                                    femaleTeams[0] = setFieldScores(femaleTeams[0], this.state.scoring, this.state.maxScorers, response.data);
                                }else if(this.state.divisions==="JV,Varsity"){
                                    femaleTeams[1] = setFieldScores(femaleTeams[1], this.state.scoring, this.state.maxScorers, response.data);
                                }else{
                                    for(let i=0; i<femaleTeams.length; i++){
                                        femaleTeams[i] = setFieldScores(femaleTeams[i], this.state.scoring, this.state.maxScorers, response.data);
                                    }
                                }
                            }
                        }
                    });
                }
            }
            this.setState({maleTeams: maleTeams, femaleTeams: femaleTeams});
        }

    });

    this.interval = setInterval(async () => {

      let maleTeams = [];
      let femaleTeams = [];

      await axios.get("https://liveresults.trxctiming.com/api/teams", { params: {id: this.state.meetName} }).then(response => {
          if(this.state.divisions==="JV,Varsity"){
              for(let i=0; i<2; i++){
                  maleTeams.push(getTeams(response.data, 'M', 1));
                  femaleTeams.push(getTeams(response.data, 'F', 1));
              }
          }else{
              for(let i=0; i<this.state.divisions.split(',').length; i++){
                  maleTeams.push(getTeams(response.data, 'M', i+1));
                  femaleTeams.push(getTeams(response.data, 'F', i+1));
              }
          }
      });

      await axios.get("https://liveresults.trxctiming.com/api/scoringSchedule", { params: {id: this.state.meetName} }).then(async (response) => {
          let schedule = response.data;

          if(schedule.length>0){
              for(let i=0; i<schedule.length; i++){
                  let eventNumber = schedule[i][2];
                  let lastRound = schedule[i][7];
                  //let eventStatus = schedule[i][11];
                  let eventType = schedule[i][10];
                  let eventGender = schedule[i][5][1];
                  if(eventGender!=="M" && eventGender!=="W" && eventGender!=="B" && eventGender!=="G"){
                      if(schedule[i][5].toUpperCase().includes("DEC") || schedule[i][5].toUpperCase().includes("HEP") || schedule[i][5].toUpperCase().includes("PEN")){
                          if(schedule[i][5].toUpperCase().includes("(W)") || schedule[i][5].toUpperCase().includes("(G)") || schedule[i][5].toUpperCase().includes("GIRLS") || schedule[i][5].toUpperCase().includes("WOMEN")){
                              eventGender = "W";
                          }else if(schedule[i][5].toUpperCase().includes("(M)") || schedule[i][5].toUpperCase().includes("(B)") || schedule[i][5].toUpperCase().includes("MEN") || schedule[i][5].toUpperCase().includes("BOYS")){
                              eventGender = "M";
                          }
                      }else{
                          eventGender = schedule[i][5][0];
                      }
                  }
                  let jv = false;
                  if(schedule[i][5].toUpperCase().includes("JV") || schedule[i][5].toUpperCase().includes("JUNIOR VARSITY")){
                      jv = true;
                  }
                  let eventScoring = parseInt(schedule[i][12]);
                  let eventStatus = parseInt(schedule[i][11]);
                  if(eventScoring>0 && eventStatus>=3){
                      await axios.get("https://liveresults.trxctiming.com/api/scoringResults", { params: {id: this.state.meetName, event: eventNumber, lastRound: lastRound, type: eventType} }).then(async (response) => {
                          if( (eventGender==="M" || eventGender==="B") && eventType==="Track" && response.data.length>0 ){
                              if(eventScoring===1){
                                  if(this.state.divisions==="JV,Varsity" && jv){
                                      maleTeams[0] = setTrackScores(maleTeams[0], this.state.scoring, this.state.maxScorers, response.data);
                                  }else if(this.state.divisions==="JV,Varsity"){
                                      maleTeams[1] = setTrackScores(maleTeams[1], this.state.scoring, this.state.maxScorers, response.data);
                                  }else{
                                      for(let i=0; i<maleTeams.length; i++){
                                          maleTeams[i] = setTrackScores(maleTeams[i], this.state.scoring, this.state.maxScorers, response.data);
                                      }
                                  }
                              }else if(eventScoring===2){
                                if(this.state.divisions==="JV,Varsity" && jv){
                                    maleTeams[0] = setRelayScores(maleTeams[0], this.state.relayScoring, this.state.maxScorers, response.data);
                                }else if(this.state.divisions==="JV,Varsity"){
                                    maleTeams[1] = setRelayScores(maleTeams[1], this.state.relayScoring, this.state.maxScorers, response.data);
                                }else{
                                    for(let i=0; i<maleTeams.length; i++){
                                        maleTeams[i] = setRelayScores(maleTeams[i], this.state.relayScoring, this.state.maxScorers, response.data);
                                    }
                                }
                              }else if(eventScoring===3){
                                  let trackMultiResults = [];
                                  let fieldMultiResults = [];
                                  await axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.state.meetName, event: eventNumber, type: "Field"} }).then(async (response) => {
                                      if(response.data.length>0){
                                          fieldMultiResults = filterFieldData(response.data);
                                      }
                                      await axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.state.meetName, event: eventNumber, type: "Track"} }).then((response) => {
                                          if(response.data.length>0){
                                            trackMultiResults = filterTrackData(response.data);
                                          }
                                          if(this.state.divisions==="JV,Varsity" && jv){
                                              maleTeams[0] = setMultisScores(maleTeams[0], this.state.scoring, this.state.maxScorers, trackMultiResults, fieldMultiResults);
                                          }else if(this.state.divisions==="JV,Varsity"){
                                              maleTeams[1] = setMultisScores(maleTeams[1], this.state.scoring, this.state.maxScorers, trackMultiResults, fieldMultiResults);
                                          }else{
                                              for(let i=0; i<maleTeams.length; i++){
                                                  maleTeams[i] = setMultisScores(maleTeams[i], this.state.scoring, this.state.maxScorers, trackMultiResults, fieldMultiResults);
                                              }
                                          }
                                      });
                                  });
                              }
                          }else if( (eventGender==="M" || eventGender==="B") && eventType==="Field" && response.data.length>0 ){
                              if(eventScoring===1){
                                  if(this.state.divisions==="JV,Varsity" && jv){
                                      maleTeams[0] = setFieldScores(maleTeams[0], this.state.scoring, this.state.maxScorers, response.data);
                                  }else if(this.state.divisions==="JV,Varsity"){
                                      maleTeams[1] = setFieldScores(maleTeams[1], this.state.scoring, this.state.maxScorers, response.data);
                                  }else{
                                      for(let i=0; i<maleTeams.length; i++){
                                          maleTeams[i] = setFieldScores(maleTeams[i], this.state.scoring, this.state.maxScorers, response.data);
                                      }
                                  }
                              }
                          }else if( (eventGender==="W" || eventGender==="G") && eventType==="Track" && response.data.length>0 ){
                              if(eventScoring===1){
                                  if(this.state.divisions==="JV,Varsity" && jv){
                                      femaleTeams[0] = setTrackScores(femaleTeams[0], this.state.scoring, this.state.maxScorers, response.data);
                                  }else if(this.state.divisions==="JV,Varsity"){
                                      femaleTeams[1] = setTrackScores(femaleTeams[1], this.state.scoring, this.state.maxScorers, response.data);
                                  }else{
                                      for(let i=0; i<femaleTeams.length; i++){
                                          femaleTeams[i] = setTrackScores(femaleTeams[i], this.state.scoring, this.state.maxScorers, response.data);
                                      }
                                  }
                              }else if(eventScoring===2){
                                  if(this.state.divisions==="JV,Varsity" && jv){
                                      femaleTeams[0] = setRelayScores(femaleTeams[0], this.state.relayScoring, this.state.maxScorers, response.data);
                                  }else if(this.state.divisions==="JV,Varsity"){
                                      femaleTeams[1] = setRelayScores(femaleTeams[1], this.state.relayScoring, this.state.maxScorers, response.data);
                                  }else{
                                      for(let i=0; i<femaleTeams.length; i++){
                                          femaleTeams[i] = setRelayScores(femaleTeams[i], this.state.relayScoring, this.state.maxScorers, response.data);
                                      }
                                  }
                              }else if(eventScoring===3){
                                  let trackMultiResults = [];
                                  let fieldMultiResults = [];
                                  await axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.state.meetName, event: eventNumber, type: "Field"} }).then(async (response) => {
                                      if(response.data.length>0){
                                          fieldMultiResults = filterFieldData(response.data);
                                      }
                                      await axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.state.meetName, event: eventNumber, type: "Track"} }).then((response) => {
                                          if(response.data.length>0){
                                            trackMultiResults = filterTrackData(response.data);
                                          }
                                          if(this.state.divisions==="JV,Varsity" && jv){
                                              femaleTeams[0] = setMultisScores(femaleTeams[0], this.state.scoring, this.state.maxScorers, trackMultiResults, fieldMultiResults);
                                          }else if(this.state.divisions==="JV,Varsity"){
                                              femaleTeams[1] = setMultisScores(femaleTeams[1], this.state.scoring, this.state.maxScorers, trackMultiResults, fieldMultiResults);
                                          }else{
                                              for(let i=0; i<femaleTeams.length; i++){
                                                  femaleTeams[i] = setMultisScores(femaleTeams[i], this.state.scoring, this.state.maxScorers, trackMultiResults, fieldMultiResults);
                                              }
                                          }
                                      });
                                  });
                              }
                          }else if( (eventGender==="W" || eventGender==="G") && eventType==="Field" && response.data.length>0 ){
                              if(eventScoring===1){
                                  if(this.state.divisions==="JV,Varsity" && jv){
                                      femaleTeams[0] = setFieldScores(femaleTeams[0], this.state.scoring, this.state.maxScorers, response.data);
                                  }else if(this.state.divisions==="JV,Varsity"){
                                      femaleTeams[1] = setFieldScores(femaleTeams[1], this.state.scoring, this.state.maxScorers, response.data);
                                  }else{
                                      for(let i=0; i<femaleTeams.length; i++){
                                          femaleTeams[i] = setFieldScores(femaleTeams[i], this.state.scoring, this.state.maxScorers, response.data);
                                      }
                                  }
                              }
                          }
                      });
                  }
              }
              this.setState({maleTeams: maleTeams, femaleTeams: femaleTeams});
          }

      });

    }, 60000);

  }

  componentDidUpdate() {

  }

  componentWillUnmount() {
      //clearInterval(this.interval);
  }

  render() {
      if(this.state.femaleTeams[0].length>0 && this.state.maleTeams[0].length>0 && !this.state.loading){
          return <div className="teamScoresPhone">
              {this.state.maleTeams.map( (division, index) => <div>
                  <button className="teamScoresButtonPhone" onClick={() => displaySession("menScores"+index)}>{"Men - " + getDivisionTtile(this.state.divisions, index)}</button>
                  <div className="teamScoresDivPhone" id={"menScores"+index} style={{display: 'block'}}>
                      <table className="teamScoresTablePhone"><tbody>
                          {setPlace(rankTeams(this.state.maleTeams[index])).map( team => <tr><td className="teamScoreTablePhone">{getTeamData(team)}</td></tr>)}
                      </tbody></table>
                  </div>
              </div>)}
              {this.state.femaleTeams.map( (division, index) => <div>
                  <button className="teamScoresButtonPhone" onClick={() => displaySession("womenScores"+index)}>{"Women - " + getDivisionTtile(this.state.divisions, index)}</button>
                  <div className="teamScoresDivPhone" id={"womenScores"+index} style={{display: 'block'}}>
                      <table className="teamScoresTablePhone"><tbody>
                          {setPlace(rankTeams(this.state.femaleTeams[index])).map( team => <tr><td className="teamScoreTablePhone">{getTeamData(team)}</td></tr>)}
                      </tbody></table>
                  </div>
              </div>)}
          </div>;
      }else if(this.state.maleTeams[0].length>0 && !this.state.loading){
          return <div className="teamScoresPhone">
              {this.state.maleTeams.map( (division, index) => <div>
                  <button className="teamScoresButtonPhone" onClick={() => displaySession("menScores"+index)}>{"Men - " + getDivisionTtile(this.state.divisions, index)}</button>
                  <div className="teamScoresDivPhone" id={"menScores"+index} style={{display: 'block'}}>
                      <table className="teamScoresTablePhone"><tbody>
                          {setPlace(rankTeams(this.state.maleTeams[index])).map( team => <tr><td className="teamScoreTablePhone">{getTeamData(team)}</td></tr>)}
                      </tbody></table>
                  </div>
              </div>)}
          </div>;
      }else if(this.state.femaleTeams[0].length>0 && !this.state.loading){
          return <div className="teamScoresPhone">
              {this.state.femaleTeams.map( (division, index) => <div>
                  <button className="teamScoresButtonPhone" onClick={() => displaySession("womenScores"+index)}>{"Women - " + getDivisionTtile(this.state.divisions, index)}</button>
                  <div className="teamScoresDivPhone" id={"womenScores"+index} style={{display: 'block'}}>
                      <table className="teamScoresTablePhone"><tbody>
                          {setPlace(rankTeams(this.state.femaleTeams[index])).map( team => <tr><td className="teamScoreTablePhone">{getTeamData(team)}</td></tr>)}
                      </tbody></table>
                  </div>
              </div>)}
          </div>;
      }else{
          return<div></div>;
      }
  }


}

function getDivisionTtile(_divisions_, _cDvision_){
    if(_divisions_===undefined || _divisions_===null || _divisions_==="NULL" || _divisions_.split(',').length<=1){
        return 'Team Scores';
    }else{
        let divisions = _divisions_.split(',');
        return divisions[_cDvision_];
    }
}

function displaySession(_session_){
    if(document.getElementById(_session_).style.display==="block"){
        document.getElementById(_session_).style.display = "none";
    }else if(document.getElementById(_session_).style.display==="none"){
        document.getElementById(_session_).style.display = "block";
    }
    return;
}

/*function getTitle(_teams_, _gender_){
    if(_teams_.length>0){
        return <td key={parseInt(Math.random()*100000)} className="teamScoresTitlePhone">{_gender_ + " Scores"}</td>;
    }else{
        return;
    }
}*/

function getTeams(_data_, _gender_, _division_) {

    let teamsArray = [];
    for(let i=0; i<_data_.length; i++){
        if(_data_[i][1]===_gender_ && _data_[i][2]===_division_){
            teamsArray.push([_data_[i][0], 0, "-"]);
        }
    }
    return teamsArray;
}

function setTrackScores(_teams_, _points_, _maxScorers_, _eventResults_) {
    let updatedTeams = _teams_;
    let pointsArray = _points_.split(',');
    let eventResults = [];
    for(let i=0; i<_eventResults_.length; i++){
        if(checkAthlete(_eventResults_, i, _maxScorers_, _teams_)){
            eventResults.push(_eventResults_[i]);
        }
    }
    for(let i=0; i<eventResults.length; i++){
        let place = eventResults[i][8];
        let team = eventResults[i][12];
        let time = eventResults[i][13];
        if(place<999990){
            updatedTeams = assignPoints(updatedTeams, team, getPointsTrack(eventResults, pointsArray, time));
        }
    }
    return updatedTeams;
}

function checkAthlete(_eventResults_, _cResultPosition_, _maxScorers_, _teams_){
    let n = 0;
    for(let i=0; i<_teams_.length; i++){

        if(_eventResults_[_cResultPosition_][12].toUpperCase()===_teams_[i][0].toUpperCase()){
            for(let j=0; j<_eventResults_.length; j++){
                if(_eventResults_[j][12].toUpperCase()===_eventResults_[_cResultPosition_][12].toUpperCase()){
                    n++;
                }

                if(n<=_maxScorers_ || _maxScorers_===0){
                    if(_cResultPosition_===j){
                        return true;
                    }
                } else {
                    return false;
                }
            }
        }
    }
    return false;
}

function setRelayScores(_teams_, _points_, _maxScorers_, _eventResults_) {
    let updatedTeams = _teams_;
    let pointsArray = _points_.split(',');
    let relayResults = [];
    for(let i=0; i<_eventResults_.length; i++){
        if(_eventResults_[i][11].includes("'A'")){
            relayResults.push(_eventResults_[i]);
        }
    }
    for(let i=0; i<relayResults.length; i++){
        let place = relayResults[i][8];
        let team = relayResults[i][12];
        let time = relayResults[i][13];
        if(place<999990){
            updatedTeams = assignPoints(updatedTeams, team, getPointsTrack(relayResults, pointsArray, time));
        }
    }
    return updatedTeams;
}

function setMultisScores(_teams_, _points_, _maxScorers_, _trackResults_, _fieldResults_) {
    let updatedTeams = _teams_;
    let multiResults = getCompiledResults(_fieldResults_, _trackResults_);
    let pointsArray = _points_.split(',');
    let eventResults = [];
    for(let i=0; i<multiResults.length; i++){
        if(multiResults[i][0]<999990){
            eventResults.push(multiResults[i]);
        }
    }
    for(let i=0; i<eventResults.length; i++){
        let place = eventResults[i][0];
        let team = eventResults[i][3];
        if(place<999990){
            if(pointsArray[place-1]>0){
                updatedTeams = assignPoints(updatedTeams, team, pointsArray[place-1]);
            }
        }
    }
    return updatedTeams;
}

function setFieldScores(_teams_, _points_, _maxScorers_, _eventResults_) {
    let updatedTeams = _teams_;
    let pointsArray = _points_.split(',');
    let eventResults = [];
    for(let i=0; i<_eventResults_.length; i++){
        if(checkAthlete(_eventResults_, i, _maxScorers_, _teams_)){
            eventResults.push(_eventResults_[i]);
        }
    }
    for(let i=0; i<eventResults.length; i++){
        let place = eventResults[i][7];
        let team = eventResults[i][12];
        if(place<999990){
            updatedTeams = assignPoints(updatedTeams, team, getPointsField(eventResults, pointsArray, place));
        }
    }
    return updatedTeams;
}

function assignPoints(_teams_, _team_, _points_) {
    let updatedTeams = _teams_;
    for(let i=0; i<updatedTeams.length; i++){
        if(updatedTeams[i][0].toUpperCase()===_team_.toUpperCase()){
            let cPoints = parseFloat(updatedTeams[i][1]);
            let pointsToAdd = parseFloat(_points_);
            let totalPoints = cPoints + pointsToAdd;
            updatedTeams[i][1] = totalPoints;
        }
    }
    return updatedTeams;
}

function getPointsTrack(_eventResults_, _points_, _time_) {
    let place;
    let n = 0;
    for(let i=0; i<_eventResults_.length; i++){
        if(_eventResults_[i][13]===_time_ && place===undefined){
            place = i;
            n++;
        } else if(_eventResults_[i][13]===_time_){
            n++;
        }
    }
    if(n===0 || place===undefined || place>=_points_.length){
        return 0;
    }else if(n===1 && place!==undefined){
        return _points_[place];
    }else if(place!==undefined){
        let totalPoints = 0;
        for(let i=0; i<n; i++){
            if(place+i<_points_.length){
                totalPoints += parseInt(_points_[place+i]);
            }
        }
        return totalPoints/n;
    }
}

function getPointsField(_eventResults_, _points_, _place_) {
    let place;
    let n = 0;
    for(let i=0; i<_eventResults_.length; i++){
        if(_eventResults_[i][7]===_place_ && place===undefined){
            place = i;
            n++;
        } else if(_eventResults_[i][7]===_place_){
            n++;
        }
    }
    if(n===0 || place===undefined || place>=_points_.length){
        return 0;
    }else if(n===1 && place!==undefined){
        return _points_[place];
    }else if(place!==undefined){
        let totalPoints = 0;
        for(let i=0; i<n; i++){
            if(place+i<_points_.length){
                totalPoints += parseInt(_points_[place+i]);
            }
        }
        return totalPoints/n;
    }
}

function rankTeams(_teams_) {
    let teamsArray = _teams_;
    for(let i=0; i<teamsArray.length-1; i++){
        for(let j=i+1; j<teamsArray.length; j++){
            if(teamsArray[i][1]<teamsArray[j][1]){
                let tempArray = teamsArray[i];
                teamsArray[i] = teamsArray[j];
                teamsArray[j] = tempArray;
            }
        }
    }
    return teamsArray;
}

function setPlace(_teams_) {
    let updatedTeams = _teams_;
    let prevPlace = 0;
    let prevScore = 0;
    for(let i=0; i<updatedTeams.length; i++){
        if(updatedTeams[i][1]>0){
            if(updatedTeams[i][1]===prevScore){
                updatedTeams[i][2] = prevPlace;
            }else{
                updatedTeams[i][2] = i+1;
                prevPlace = i+1;
                prevScore = updatedTeams[i][1];
            }
        }
    }
    return updatedTeams;
}

function filterFieldData(_results_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        let bestMark = getBestMark(_results_[i][6], _results_[i][13]);
        updatedResults.push([_results_[i][2], _results_[i][9], _results_[i][11], _results_[i][12], bestMark, getFieldScore(_results_[i][8], bestMark, _results_[i][4])]);
    }
    return updatedResults;
}

function filterTrackData(_results_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        let time = roundTime(_results_[i][13]);
        updatedResults.push([_results_[i][2], _results_[i][9], _results_[i][11], _results_[i][12], time, getTrackScore(_results_[i][8], time, _results_[i][4])]);
    }
    return updatedResults;
}

function getFieldScore(_place_, _mark_, _eventName_) {
    let score = 0;
    if(_place_===999995 || _place_===999996 || _place_===999997 || _place_===999998 || _place_===999999 || _place_==="" || _mark_==="" || _mark_==="X" || _mark_==="NH"){
        return score;
    }
    if(_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("MAN") || _eventName_.toUpperCase().includes("(M)") || _eventName_.toUpperCase().includes("(B)") || _eventName_.toUpperCase().includes("BOY")){
        if(_eventName_.toUpperCase().includes("P.V.") || _eventName_.toUpperCase().includes("PV") || _eventName_.toUpperCase().includes("POLE VAULT")){
            score = Math.floor( 0.2797 * Math.pow(parseFloat(_mark_)*100 - 100.00, 1.35) );
        }else if(_eventName_.toUpperCase().includes("H.J.") || _eventName_.toUpperCase().includes("HJ") || _eventName_.toUpperCase().includes("HIGH JUMP")){
            score = Math.floor( 0.8465 * Math.pow(parseFloat(_mark_)*100 - 75.00, 1.42) );
        }else if(_eventName_.toUpperCase().includes("SHOT") || _eventName_.toUpperCase().includes("SP") || _eventName_.toUpperCase().includes("S. P.") || _eventName_.toUpperCase().includes("S.P.")){
            score = Math.floor( 51.39 * Math.pow(parseFloat(_mark_) - 1.50, 1.05) );
        }else if(_eventName_.toUpperCase().includes("LONG") || _eventName_.toUpperCase().includes("LJ") || _eventName_.toUpperCase().includes("L. J.") || _eventName_.toUpperCase().includes("L.J.")){
            score = Math.floor( 0.14354 * Math.pow(parseFloat(_mark_)*100 - 220.00, 1.40) );
        }else if(_eventName_.toUpperCase().includes("DISC")){
            score = Math.floor( 12.91 * Math.pow(parseFloat(_mark_) - 4.00, 1.10) );
        }else if(_eventName_.toUpperCase().includes("JAV")){
            score = Math.floor( 10.14 * Math.pow(parseFloat(_mark_) - 7.00, 1.08) );
        }
    }else if(_eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("WOMAN") || _eventName_.toUpperCase().includes("(W)") || _eventName_.toUpperCase().includes("(G)") || _eventName_.toUpperCase().includes("GIRL")){
        if(_eventName_.toUpperCase().includes("H.J.") || _eventName_.toUpperCase().includes("HJ") || _eventName_.toUpperCase().includes("HIGH JUMP")){
            score = Math.floor( 1.84523 * Math.pow(parseFloat(_mark_)*100 - 75.00, 1.348) );
        }else if(_eventName_.toUpperCase().includes("SHOT") || _eventName_.toUpperCase().includes("SP") || _eventName_.toUpperCase().includes("S. P.") || _eventName_.toUpperCase().includes("S.P.")){
            score = Math.floor( 56.0211 * Math.pow(parseFloat(_mark_) - 1.50, 1.05) );
        }else if(_eventName_.toUpperCase().includes("LONG") || _eventName_.toUpperCase().includes("LJ") || _eventName_.toUpperCase().includes("L. J.") || _eventName_.toUpperCase().includes("L.J.")){
            score = Math.floor( 0.188807 * Math.pow(parseFloat(_mark_)*100 - 210.00, 1.41) );
        }else if(_eventName_.toUpperCase().includes("JAV")){
            score = Math.floor( 15.9803 * Math.pow(parseFloat(_mark_) - 3.80, 1.04) );
        }
    }
    return score;
}

function getTrackScore(_place_, _mark_, _eventName_) {
    let score = 0;
    if(_place_===999995 || _place_===999996 || _place_===999997 || _place_===999998 || _place_===999999 || _place_==="" || _mark_===""){
        return score;
    }
    let timeInSeconds = getSeconds(_mark_);
    if(_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("MAN") || _eventName_.toUpperCase().includes("(M)") || _eventName_.toUpperCase().includes("(B)") || _eventName_.toUpperCase().includes("BOY")){
        if(_eventName_.toUpperCase().includes("60 METER HURDLES") || _eventName_.toUpperCase().includes("60 M. H.") || _eventName_.toUpperCase().includes("60 M.H.")){
            score = Math.floor( 20.5173 * Math.pow(15.50 - parseFloat(timeInSeconds), 1.92) );
        }else if(_eventName_.toUpperCase().includes("60 METER DASH") || _eventName_.toUpperCase().includes("60 M. D.") || _eventName_.toUpperCase().includes("60 M.D.")){
            score = Math.floor( 58.0150 * Math.pow(11.50 - parseFloat(timeInSeconds), 1.81) );
        }else if(_eventName_.toUpperCase().includes("1000")){
            score = Math.floor( 0.08713 * Math.pow(305.50 - parseFloat(timeInSeconds), 1.85) );
        }else if(_eventName_.toUpperCase().includes("100 METER DASH") || _eventName_.toUpperCase().includes("100 M. D.") || _eventName_.toUpperCase().includes("100 M.D.") || _eventName_.toUpperCase().includes("100 METER D.") || _eventName_.toUpperCase().includes("100 M. DASH")){
            score = Math.floor( 25.4347 * Math.pow(18.00 - parseFloat(timeInSeconds), 1.81) );
        }else if(_eventName_.toUpperCase().includes("400 METER DASH") || _eventName_.toUpperCase().includes("400 M. D.") || _eventName_.toUpperCase().includes("400 M.D.") || _eventName_.toUpperCase().includes("400 METER D.") || _eventName_.toUpperCase().includes("400 M. DASH")){
            score = Math.floor( 1.53775 * Math.pow(82.00 - parseFloat(timeInSeconds), 1.81) );
        }else if(_eventName_.toUpperCase().includes("110 METER HURDLES") || _eventName_.toUpperCase().includes("110 M. H.") || _eventName_.toUpperCase().includes("110 M.H.") || _eventName_.toUpperCase().includes("110 METER H.") || _eventName_.toUpperCase().includes("110 M. HURDLES")){
            score = Math.floor( 5.74352 * Math.pow(28.50 - parseFloat(timeInSeconds), 1.92) );
        }else if(_eventName_.toUpperCase().includes("1500")){
            score = Math.floor( 0.03768 * Math.pow(480.00 - parseFloat(timeInSeconds), 1.85) );
        }
    }else if(_eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("WOMAN") || _eventName_.toUpperCase().includes("(W)") || _eventName_.toUpperCase().includes("(G)") || _eventName_.toUpperCase().includes("GIRL")){
        if(_eventName_.toUpperCase().includes("60 METER HURDLES") || _eventName_.toUpperCase().includes("60 M. H.") || _eventName_.toUpperCase().includes("60 M.H.")){
            score = Math.floor( 20.0479 * Math.pow(17.00 - parseFloat(timeInSeconds), 1.835) );
        }else if(_eventName_.toUpperCase().includes("800")){
            score = Math.floor( 0.11193 * Math.pow(254.00 - parseFloat(timeInSeconds), 1.88) );
        }else if(_eventName_.toUpperCase().includes("100 METER HURDLES") || _eventName_.toUpperCase().includes("100 M. H.") || _eventName_.toUpperCase().includes("100 M.H.") || _eventName_.toUpperCase().includes("100 M. H")){
            score = Math.floor( 9.23076 * Math.pow(26.70 - parseFloat(timeInSeconds), 1.835) );
        }else if(_eventName_.toUpperCase().includes("200 M") || _eventName_.toUpperCase().includes("200 M. D.") || _eventName_.toUpperCase().includes("200 M.D.")){
            score = Math.floor( 4.99087 * Math.pow(42.50 - parseFloat(timeInSeconds), 1.81) );
        }
    }
    return score;
}

function getSeconds(_mark_){
    let updatedTime = _mark_;
    if(updatedTime.includes(" ")){
        updatedTime = updatedTime.substring(0, updatedTime.indexOf(" "));
    }
    if(updatedTime.includes(":")){
        let minutes = updatedTime.substring(0, updatedTime.indexOf(":"));
        let seconds = updatedTime.substring(updatedTime.indexOf(":")+1);
        updatedTime = (parseInt(minutes)*60 + parseFloat(seconds)).toFixed(2);
    }
    return updatedTime;
}

function getBestMark(_heightProgression_, _attempts_){
    let bestMark = "0.00";
    if(_attempts_==="DNS"){
        return "DNS";
    }else if(_attempts_===undefined || _attempts_==="" || _attempts_==="NULL"){
        return bestMark;
    }
    if(_heightProgression_==="NULL" || _heightProgression_===""){
        let attempts = _attempts_.split(',');
        for(let i=0; i<attempts.length; i+=2){
            if(parseFloat(attempts[i])>parseFloat(bestMark)){
                bestMark = attempts[i];
            }
        }
        if(bestMark==="0.00"){
            bestMark = "X";
        }
    }else{
        let attempts = _attempts_.split(',');
        let progression = _heightProgression_.split(',');
        progression.pop();
        progression.shift();
        for(let i=0; i<attempts.length; i++){
            if(attempts[i].includes("O") && progression.length>i){
                bestMark = progression[i];
            }
        }
        if(bestMark==="0.00"){
            bestMark = "NH";
        }
    }
    return bestMark;
}

function roundTime(_time_){

    if(_time_.substring(_time_.indexOf('.')+1).length<=2){
        return _time_;
    }
    if(_time_ === "99:99:99.999") {
        return "DNS";
    }else if(_time_ === "99:99:99.998") {
        return "DNF";
    }else if(_time_ === "99:99:99.997") {
        return "DQ";
    }else if(_time_ === "99:99:99.996") {
        return "";
    }else {
        let adjustedTime = _time_.substring(0, _time_.indexOf('.')+1);
        let decimals = _time_.substring(_time_.indexOf('.')+1);
        if(decimals>990){
            if(adjustedTime.includes(':')){
                let mins = adjustedTime.substring(0, adjustedTime.indexOf(':')+1);
                let seconds = adjustedTime.substring(adjustedTime.indexOf(':')+1);
                if(!seconds.includes(':')){
                    decimals = "000";
                    if(parseInt(seconds)<10){
                        adjustedTime = (mins + '0' + (parseInt(seconds)+1) + '.');
                    }else {
                        adjustedTime = (mins + (parseInt(seconds)+1) + '.');
                    }
                }

            }else{
                decimals = "000";
                adjustedTime = (parseInt(adjustedTime)+1) + '.';
            }
        }
        if(decimals[2]==='0'){
            decimals = decimals[0] + '.' + decimals[1] + decimals[2];
        }else if(decimals[2]!=='0'){
            decimals = decimals[0] + '.' + decimals[1] + '9';
        }
        decimals = parseFloat(decimals).toFixed(1);
        decimals = decimals[0] + decimals[2];
        return adjustedTime + decimals;
    }
}

function getCompiledResults(_fieldResults_, _trackResults_){
    let updatedResults = [];
    for(let i=0; i<_fieldResults_.length; i++){
        if(!checkMultiAthlete(_fieldResults_[i][1], updatedResults)){
            updatedResults.push([0, _fieldResults_[i][1], _fieldResults_[i][2], _fieldResults_[i][3],"-","-","-","-","-","-","-","-","-","-",0]);
        }
        for(let j=0; j<updatedResults.length; j++){
            if(parseInt(updatedResults[j][1])===parseInt(_fieldResults_[i][1])){
                updatedResults[j][parseInt(_fieldResults_[i][0])+3] = [_fieldResults_[i][4], _fieldResults_[i][5]];
                updatedResults[j][14] = parseInt(updatedResults[j][14]) + parseInt(_fieldResults_[i][5]);
                if(_fieldResults_[i][4]==="DNS"){
                    updatedResults[j][14] = 0;
                }
            }
        }
    }
    for(let i=0; i<_trackResults_.length; i++){
        if(!checkMultiAthlete(_trackResults_[i][1], updatedResults)){
            updatedResults.push([0, _trackResults_[i][1], _trackResults_[i][2], _trackResults_[i][3],"-","-","-","-","-","-","-","-","-","-",0]);
        }
        for(let j=0; j<updatedResults.length; j++){
            if(parseInt(updatedResults[j][1])===parseInt(_trackResults_[i][1])){
                updatedResults[j][parseInt(_trackResults_[i][0])+3] = [_trackResults_[i][4], _trackResults_[i][5]];
                updatedResults[j][14] = parseInt(updatedResults[j][14]) + parseInt(_trackResults_[i][5]);
                if(_trackResults_[i][4]==="DNS"){
                    updatedResults[j][14] = 0;
                }
            }
        }
    }
    for(let i=0; i<updatedResults.length-1; i++){
        for(let j=i+1; j<updatedResults.length; j++){
            if(parseInt(updatedResults[j][14])>parseInt(updatedResults[i][14])){
                let tempArray = updatedResults[i];
                updatedResults[i] = updatedResults[j];
                updatedResults[j] = tempArray;
            }
        }
    }
    for(let i=0; i<updatedResults.length; i++){
        if(parseInt(updatedResults[i][14])>0){
            updatedResults[i][0] = i+1;
        }else{
            updatedResults[i][0] = "-";
        }
    }
    return updatedResults;
}

function checkMultiAthlete(_bib_, _results_){
    for(let i=0; i<_results_.length; i++){
        if(parseInt(_results_[i][1])===parseInt(_bib_)){
            return true;
        }
    }
    return false;
}

function getTeamData(_teamInfo_) {
    return <table><tbody><tr>
        <td className="teamScoresPlacePhone">{_teamInfo_[2]}</td>
        <td className="teamScoresLogoDivPhone"><img alt="img" src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + _teamInfo_[0] + ".png"} className="teamScoresLogoPhone"></img></td>
        <td className="teamScoresNamePhone">{_teamInfo_[0]}</td>
        <td className="teamScoresPointsPhone">{_teamInfo_[1].toFixed(2)}</td>
    </tr></tbody></table>
}

export default TeamScoresPhone;
