import React from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom';
import './HomeScreen.css';

class HomeScreen extends React.Component {

    state = {
        searchValue: "",
        activeMeets: [],
        pastMeets: []
    }

    componentDidMount() {

        axios.get("https://liveresults.trxctiming.com/api/activeMeets").then((response) => {
            this.setState({activeMeets: response.data});
        });

        axios.get("https://liveresults.trxctiming.com/api/pastMeets").then((response) => {
            this.setState({pastMeets: response.data});
        });
    }

    componentWillUnmount() {

    }

    render() {
        if(window.innerWidth<1280) { return <div className="homeScreenPhone">
            <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/TRXC.png"} className="homePicturePhone" alt=""></img>
            <input className="phoneSearchField" type="text" defaultValue="Search Meet..." id="searchID" onClick={() => emptyFilter(this)}></input>
            <div className="searchButtons">
                <button className="heatButtonDesktop" onClick={() => updateFilter(this)}>SEARCH</button>
                <button className="heatButtonDesktop" onClick={() => resetFilter(this)}>RESET</button>
            </div>

            <table className="phoneMeetsTable">
                <thead className="phoneHeader">
                    <th>Meet</th>
                    <th></th>
                </thead>
                <tbody>{checkMeets(this.state.activeMeets, this.state.searchValue).map( meet =>
                    <tr className="phoneActiveMeet">
                        <td className="phoneHostLogoArea"><img className="phoneHostLogo" src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" + meet[0] + ".png"} alt=""></img></td>
                        <td className="phoneAthleteXC"><table>
                            <tr><td className="phoneMeetName"><Link style={{textDecoration: 'none'}} to={'/meets/' + meet[0]}>{meet[0].replaceAll('_', ' ')}</Link></td></tr>
                            <tr><td className="phoneMeetDate">{meet[3].substring(meet[3].indexOf(",")+2, meet[3].indexOf("00:00:00")-1)}</td></tr>
                            <tr><td className="phoneMeetLocation">{meet[2]}</td></tr>
                        </table></td>
                    </tr>)}
                    {checkMeets(this.state.pastMeets, this.state.searchValue).map(meet =>
                    <tr>
                        <td className="phoneHostLogoArea"><img className="phoneHostLogo" src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" + meet[0] + ".png"} alt=""></img></td>
                        <td className="phoneAthleteXC"><table>
                            <tr><td className="phoneMeetName"><Link style={{textDecoration: 'none'}} to={'/meets/' + meet[0]}>{meet[0].replaceAll('_', ' ')}</Link></td></tr>
                            <tr><td className="phoneMeetDate">{meet[3].substring(meet[3].indexOf(",")+2, meet[3].indexOf("00:00:00")-1)}</td></tr>
                            <tr><td className="phoneMeetLocation">{meet[2]}</td></tr>
                        </table></td>
                </tr>)}</tbody>
            </table>

        </div>}

        else{ return <div className="homeScreen">
            <div className="liveEvents">
                {this.state.activeMeets.map(meet => <Link style={{textDecoration: 'none'}} to={'/meets/' + meet[0]}><div className="liveEvent" key={meet[0]}>
                    <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" + meet[0] + ".png"} alt="" className="eventImage"></img>
                    <div className="eventTitle">{meet[0].replaceAll('_', ' ')}</div>
                    <div className="eventHost">{meet[1]}</div>
                    <div className="eventDate">{meet[3].substring(0, meet[3].indexOf("00:00:00"))}</div>
                    <div className="eventLocation">{meet[2]}</div>
                </div></Link>)}
            </div>

            <div className="pastEvents">
                <div className="searchArea">
                    <input className="searchField" type="text" defaultValue="Search..." id="searchID" onClick={() => emptyFilter(this)}></input>
                    <button className="heatButtonDesktop" onClick={() => updateFilter(this)}>SEARCH</button>
                    <button className="heatButtonDesktop" onClick={() => resetFilter(this)}>RESET</button>
                </div>

                <table className="pastEventsTable">
                    <thead>
                        <td className="pastMeetLogoArea"></td>
                        <td className="pastMeetInfo">Meet</td>
                        <td className="pastMeetType">Type</td>
                    </thead>
                    {checkMeets(this.state.pastMeets, this.state.searchValue).map(meet => <tbody><tr className="resultTableTrackRowDesktop">
                        <td className="pastMeetLogoArea"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" + meet[0] + ".png"} alt="" className="pastMeetLogo"></img></td>
                        <td className="pastMeetInfo"><table>
                            <tr><td className="pastMeetName"><Link style={{textDecoration: 'none'}} to={'/meets/' + meet[0]}>{meet[0].replaceAll('_', ' ')}</Link></td></tr>
                            <tr><td className="pastMeetHost">{"Hosted by " + meet[1]}</td></tr>
                            <tr><td className="pastMeetDate">{meet[2] + ", " + meet[3].substring(meet[3].indexOf(",")+2, meet[3].indexOf("00:00:00")-1)}</td></tr>
                        </table></td>
                        <td className="pastMeetType" key={parseInt(Math.random()*100000)}>{getMeetType(meet[5])}</td>
                  </tr></tbody>)}
                </table>
            </div>

            <div className="homeFooter"><a href="https://trxctiming.com">By TRXC Timing</a></div>

        </div>}
    }

}

function checkMeets(_meets_, _searchValue_) {
    let meets = [];
    for(let i=0; i<_meets_.length; i++){
        if(document.getElementById('searchID').value === "Search..." || document.getElementById('searchID').value === ""){
            meets.push(_meets_[i]);
        }
        else if(_meets_[i][0].replaceAll('_', ' ').toUpperCase().includes(_searchValue_.toUpperCase())){
            meets.push(_meets_[i]);
        }
        else if(_meets_[i][1].toUpperCase().includes(_searchValue_.toUpperCase())){
            meets.push(_meets_[i]);
        }
        else if(_meets_[i][2].toUpperCase().includes(_searchValue_.toUpperCase())){
            meets.push(_meets_[i]);
        }
        else if(_meets_[i][5].toUpperCase().includes(_searchValue_.toUpperCase())){
            meets.push(_meets_[i]);
        }
    }
    return meets;
}

function updateFilter(_this_) {
    _this_.setState({searchValue: document.getElementById('searchID').value});
    return;
}

function emptyFilter(_this_) {
    document.getElementById('searchID').value = "";
    return;
}

function resetFilter(_this_) {
    document.getElementById('searchID').value = "Search...";
    _this_.setState({searchValue: ""});
    return;
}

function getMeetType(_type_){
    if(_type_==="Cross Country"){
        return "XC";
    }else{
        return _type_;
    }
}

export default HomeScreen;
