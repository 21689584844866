import React from 'react';
import axios from 'axios';
import './LiveResultsVertical.css';
import './StartListField.css';

class LiveResultsVertical extends React.Component {

    state = {
        meetName: this.props.meetName,
        session: this.props.session,
        eventNumber: this.props.eventNumber,
        roundNumber: this.props.roundNumber,
        eventName: this.props.eventName,
        eventGender: this.props.eventGender,
        divisions: this.props.divisions,
        eventRecord: this.props.eventRecord,
        teams: [],
        status: 0,
        eventProgression: [],
        system: undefined,
        entries: [],
        results: [],
        roster: []
    }

    componentDidMount() {
        this.setState({eventName: filterName(this.state.eventName)});

        axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.state.meetName, event: this.state.eventNumber, round: this.state.roundNumber, session: this.state.session} }).then((response) => {
            this.setState({entries: response.data});
        });

        axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Field"} }).then((response) => {
            if(response.data.length>0){
                this.setState({system: response.data[0][5]});
                let eventProgression = response.data[0][6].split(',');
                eventProgression.pop();
                eventProgression.shift();
                if(eventProgression!==undefined){
                    this.setState({results: filterData(response.data, eventProgression), eventProgression: eventProgression});
                }
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});
        });

        axios.get("https://liveresults.trxctiming.com/api/teams", { params: {id: this.state.meetName} }).then(response => {
            this.setState({teams: response.data});
        });

        //set interval for live results updates
        this.interval = setInterval(() => {

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0 && response.data.length>=this.state.results.length){
                    this.setState({system: response.data[0][5]});
                    let eventProgression = response.data[0][6].split(',');
                    eventProgression.pop();
                    eventProgression.shift();
                    if(eventProgression!==undefined){
                        this.setState({results: filterData(response.data, eventProgression), eventProgression: eventProgression});
                    }
                }
            });

        }, 15000); //15s

    }

    componentDidUpdate(prevProps) {
        if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber){

            this.setState({session: this.props.session, eventNumber: this.props.eventNumber, roundNumber: this.props.roundNumber, eventName: filterName(this.props.eventName), eventProgression: [], results: [], status: 0, eventGender: this.props.eventGender, eventRecord: this.props.eventRecord});

            axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.props.meetName, event: this.props.eventNumber, round: this.props.roundNumber, session: this.props.session} }).then((response) => {
                this.setState({entries: response.data});
            });

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.props.meetName, session: this.props.session, event: this.props.eventNumber, round: this.props.roundNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0){
                    this.setState({system: response.data[0][5]});
                    let eventProgression = response.data[0][6].split(',');
                    eventProgression.pop();
                    eventProgression.shift();
                    if(eventProgression!==undefined){
                        this.setState({results: filterData(response.data, eventProgression), eventProgression: eventProgression});
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return <div className="eventResultsVertical">
            <p className="eventNameVertical">{this.state.eventName}</p>
            <div className="eventRecordXC" style={{whiteSpace: 'pre-line'}}>{filterRecord(this.state.eventRecord)}</div>
            {getHeaderButtons(this)}
            {this.state.status===-1 ? getStartListTable(this.state.entries, this.state.roster) : getResultsTable(this.state.status, this.state.eventProgression, this.state.system, this.state.results, this.state.roster, this.state.divisions, this.state.teams, this.state.eventGender)}
        </div>
    }

}

function filterRecord(_record_){
    let updatedRecord = "";
    if(_record_===undefined || _record_==="" || _record_==="NULL"){
        return updatedRecord;
    }
    if(_record_[_record_.length-1]===';'){
        updatedRecord = _record_.substring(0, _record_.length-1);
    }
    return updatedRecord.replaceAll(';', '\n');
}

function getResultsTable(_status_, _progression_, _system_, _results_, _roster_, _divisions_, _teams_, _gender_){
    if(_status_===0){
      return <table className="_resultTableVertical_">
          <thead>
              <th className="placeVertical">Place</th>
              <th className="orderVertical">Order</th>
              <th className="logoColumnVertical"></th>
              <th className="athleteVertical">Athlete</th>
              {_progression_.map( height => <th className="heightVerticalHeader"><table>
                  <tr><td>{height}</td></tr>
                  <tr><td>{getMarkConversion('1', height, _system_)}</td></tr>
              </table></th>)}
              <th className="markVertical">Mark</th>
          </thead>
          <tbody>{_results_.map( athlete => <tr className="_resultTableVerticalRow_">
              <td className="placeVertical">{athlete[0]}</td>
              <td className="orderVertical">{athlete[1]}</td>
              <td className="logoColumnVertical"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[2] + ".png"} className="logoVertical" alt=""></img></td>
              <td className="athleteVertical"><table>
                  <tr><td className="athleteVerticalRowName">{athlete[3] + getGrade(athlete[6], _roster_)}</td></tr>
                  <tr><td className="athleteVerticalRowTeam">{athlete[2]}</td></tr>
              </table></td>
              {athlete[4].map( attempt => <td className="heightVertical" style={{'background-color':'lightyellow'}}>{attempt}</td>)}
              <td><table>
                  <tr><td className="markVertical">{getMark(athlete[0], athlete[5])}</td></tr>
                  <tr><td className="markVerticalConversion">{getMarkConversion(athlete[0], athlete[5], _system_)}</td></tr>
              </table></td>
          </tr>)}</tbody>
      </table>;
    }else{
        let _byDivisionResults_ = [];
        for(let i=1; i<=_divisions_.split(',').length; i++){
            _byDivisionResults_[i-1] = filterByDivision(_results_, i, _teams_, _gender_);
        }
        return _byDivisionResults_.map( (divisionResults, index) => <div><button className="flightButtonVertical" onClick={() => displayHeat(index)}>{_divisions_.split(',')[index]}</button>
            <div className="heatResults" id={index} style={{display: 'block'}}>
                <table className="_resultTableVertical_">
                    <thead>
                        <th className="placeVertical">Place</th>
                        <th className="orderVertical">Order</th>
                        <th className="logoColumnVertical"></th>
                        <th className="athleteVertical">Athlete</th>
                        {_progression_.map( height => <th className="heightVerticalHeader"><table>
                            <tr><td>{height}</td></tr>
                            <tr><td>{getMarkConversion('1', height, _system_)}</td></tr>
                        </table></th>)}
                        <th className="markVertical">Mark</th>
                    </thead>
                    <tbody>{divisionResults.map( athlete => <tr className="_resultTableVerticalRow_">
                        <td className="placeVertical">{getDivisionPlace(divisionResults, athlete[0])}</td>
                        <td className="orderVertical">{athlete[1]}</td>
                        <td className="logoColumnVertical"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[2] + ".png"} className="logoVertical" alt=""></img></td>
                        <td className="athleteVertical"><table>
                            <tr><td className="athleteVerticalRowName">{athlete[3] + getGrade(athlete[6], _roster_)}</td></tr>
                            <tr><td className="athleteVerticalRowTeam">{athlete[2]}</td></tr>
                        </table></td>
                        {athlete[4].map( attempt => <td className="heightVertical" style={{'background-color':'lightyellow'}}>{attempt}</td>)}
                        <td><table>
                            <tr><td className="markVertical">{getMark(athlete[0], athlete[5])}</td></tr>
                            <tr><td className="markVerticalConversion">{getMarkConversion(athlete[0], athlete[5], _system_)}</td></tr>
                        </table></td>
                    </tr>)}</tbody>
                </table>
            </div>
        </div>);
    }
}

function getStartListTable(_entries_, _roster_){
    return <table className="startListTableField">
        <thead>
            <th className="flightField">Flight</th>
            <th className="logoColumnField"></th>
            <th className="athleteField">Athlete</th>
            <th className="fieldTeam">Team</th>
            {getEntryHeader(_entries_)}
        </thead>
        <tbody>{_entries_.map(entry => <tr>
            <td className="flightField">{entry[3] + '-' + entry[6]}</td>
            <td className="logoColumnField"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + entry[8] + ".png"} alt={entry[8]} className="logoField"></img></td>
            <td className="athleteField">{entry[7] + getGrade(entry[5], _roster_)}</td>
            <td className="fieldTeam">{entry[8]}</td>
            {getEntry(entry[9])}
        </tr>)}</tbody>
    </table>;
}

function getHeaderButtons(_this_){
    return <table className="heatButtonsDesktop"><tr>
        <td className="heatButtonDesktop" onClick={() => _this_.setState({status: -1})}>{'Start List'}</td>
        <td className="heatButtonDesktop" onClick={() => _this_.setState({status: 0})}>{'Compiled'}</td>
        {_this_.state.divisions.split(',').length>1 && _this_.state.divisions.toUpperCase()!=="JV,Varsity" ? <td className="heatButtonDesktop" onClick={() => _this_.setState({status: 1})}>{'By Division'}</td> : null}
    </tr></table>;
}

function displayHeat(_heat_){
    if(document.getElementById(_heat_).style.display==="block"){
        document.getElementById(_heat_).style.display = "none";
    }else if(document.getElementById(_heat_).style.display==="none"){
        document.getElementById(_heat_).style.display = "block";
    }
    return;
}

function getDivisionPlace(_flightResults_, _place_){
    if(_place_==="-" || _place_==="DNS"){
        return _place_;
    }
    let place = 1;
    for(let i=0; i<_flightResults_.length; i++){
        if(_flightResults_[i][0]===_place_){
            return place;
        }else{
            place++;
        }
    }
    return _place_;
}

function filterByDivision(_results_, _division_, _teams_, _gender_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        if(checkTeamDivision(_results_[i][2], _teams_, _division_, _gender_)){
          updatedResults.push(_results_[i]);
        }
    }
    return updatedResults;
}

function checkTeamDivision(_team_, _teams_, _division_, _gender_){
    let gender = '';
    if(_gender_==="Women"){
        gender = 'F';
    }else if(_gender_==="Men"){
        gender = 'M';
    }
    for(let i=0; i<_teams_.length; i++){
        if(_teams_[i][0]===_team_ && _teams_[i][1]===gender && _teams_[i][2]===_division_){
            return true;
        }
    }
    return false;
}

function getEntryHeader(_entries_){
    if(_entries_.length<=0){
        return;
    }
    if(_entries_[0][9]!==undefined && _entries_[0][9]!=="" && _entries_[0][9]!=="NULL" && _entries_[0][9]!==null){
        return <th className="fieldEntry">Entry Mark</th>;
    }else {
        return;
    }
}

function getEntry(_entry_){
      if(_entry_!==undefined && _entry_!=="" && _entry_!=="NULL" && _entry_!==null){
          return <td className="fieldEntry">{_entry_}</td>;
      }
}

function getMarkConversion(_place_, _bestMark_, _system_){
    if(_place_==="" || _place_==="-" || _place_===null || _place_===undefined || _place_==="DNS" || _bestMark_.toUpperCase()==="JUMPOFF"){
        return;
    }
    if(_system_==="Metric"){
        let englishMark = Math.floor((parseFloat(_bestMark_) + 0.002) * 39.3700787402 * 100) / 100;

        let englishMarkString = "";

        let markFeet = parseInt(englishMark/12);
        let markInches = parseFloat(englishMark-markFeet*12).toFixed(2);

        if(markInches-parseInt(markInches)>0.75){
            markInches = markInches - (markInches - parseInt(markInches) - 0.75);
        }else if(markInches-parseInt(markInches)<0.75 && markInches-parseInt(markInches)>0.50){
            markInches = markInches - (markInches - parseInt(markInches) - 0.50);
        }else if(markInches-parseInt(markInches)<0.50 && markInches-parseInt(markInches)>0.25){
            markInches = markInches - (markInches - parseInt(markInches) - 0.25);
        }else if(markInches-parseInt(markInches)<0.25 && markInches-parseInt(markInches)>0.00){
            markInches = markInches - (markInches - parseInt(markInches));
        }

        englishMarkString = markFeet.toString() + "-" + parseFloat(markInches).toFixed(2);

        return englishMarkString;
    }
    return "";
}

function getMark(_place_, _bestMark_){
    if(_place_==="" || _place_==="-" || _place_===null || _place_===undefined || _place_==="DNS"){
        return "NH";
    }
    return _bestMark_;
}

function filterName(_name_){

    let updatedName = _name_;
    if(updatedName.includes("(M)")){
        updatedName = updatedName.replace("(M)", "Men's");
    }else if(updatedName.includes("(W)")){
        updatedName = updatedName.replace("(W)", "Women's");
    }else if(updatedName.includes("(B)")){
        updatedName = updatedName.replace("(B)", "Boys'");
    }else if(updatedName.includes("(G)")){
        updatedName = updatedName.replace("(G)", "Girls'");
    }
    if(updatedName.includes("PV")){
        updatedName = updatedName.replace("PV", "Pole Vault");
    }else if(updatedName.includes("HJ")){
        updatedName = updatedName.replace("HJ", "High Jump");
    }else if(updatedName.includes("P.V.")){
        updatedName = updatedName.replace("P.V.", "Pole Vault");
    }else if(updatedName.includes("H.J.")){
        updatedName = updatedName.replace("H.J.", "High Jump");
    }
    return updatedName + " - Results";
}

function filterData(_results_, _eventProgression_) {

    let filteredData = [];
    _results_.map( athlete => filteredData.push([getVerticalPlace(athlete[7]), athlete[10], athlete[12], athlete[11], getAttempts(athlete[7], athlete[13], _eventProgression_), getBestMark(athlete[7], athlete[13], _eventProgression_), athlete[9]]) );
    return filteredData;
}

function getVerticalPlace(_place_) {

    if(_place_===999999) {
        return "DNS";
    }else if(_place_===999998) {
        return "-";
    }else{
        return _place_;
    }
}

function getAttempts(_place_, _attempts_, _eventProgression_) {

    let updatedAttempts = [];
    let attempts = _attempts_.split(',');
    for(let i=0; i<_eventProgression_.length; i++){
        if(attempts[i]==="DNS" || _place_===999999){
            updatedAttempts.push("");
        }else{
            if(i<attempts.length){
                updatedAttempts.push(attempts[i])
            }else{
                updatedAttempts.push("");
            }
        }
    }
    return updatedAttempts;
}

function getBestMark(_place_, _attempts_, _eventProgression_) {

    let bestHeight = "";
    let attempts = _attempts_.split(',');
    if(_place_===999998 || _place_===999999){
        return bestHeight;
    }
    let _continue_ = true;
    for(let i=0; i<attempts.length; i++){
        if(attempts[i].includes('O') && _continue_){
            bestHeight = _eventProgression_[i];
        }
        if(attempts[i]==="XXX"){
            _continue_ = false;
        }
    }
    return bestHeight;
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return "  (" + grade + ")";
        }
    }
    return "";
}

export default LiveResultsVertical;
