import React from 'react';
import axios from 'axios';
import './LiveResultsMultis.css';
import MultisTable from './MultisTable.js';

class LiveResultsMultis extends React.Component {

    state = {
        meetName: this.props.meetName,
        session: this.props.session,
        eventNumber: this.props.eventNumber,
        roundNumber: this.props.roundNumber,
        eventName: this.props.eventName,
        eventGender: this.props.eventGender,
        eventRecord: this.props.eventRecord,
        system: undefined,
        headerAttempts : ["Attempt 1", "Attempt 2", "Attempt 3"],
        results: [],
        roster: []
    }

    componentDidMount() {
        this.setState({eventName: filterName(this.state.eventName)});

        axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Field"} }).then((response) => {
            if(response.data.length>0){
                this.setState({system: response.data[0][5]});
                let maxAttempts = getMaxAttempts(response.data);
                if(maxAttempts>3){
                    let updatedAttempts = [];
                    for(let i=0; i<maxAttempts; i++){
                        updatedAttempts[i] = "Attempt " + (i+1);
                    }
                    this.setState({headerAttempts: updatedAttempts})
                }
                this.setState({results: filterData(response.data, maxAttempts)});
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});
        });

        //set interval for live results updates
        this.interval = setInterval(() => {

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0 && response.data.length>=this.state.results.length){
                    this.setState({system: response.data[0][5]});
                    let maxAttempts = getMaxAttempts(response.data);
                    if(maxAttempts>3){
                        let updatedAttempts = [];
                        for(let i=0; i<maxAttempts; i++){
                            updatedAttempts[i] = "Attempt " + (i+1);
                        }
                        this.setState({headerAttempts: updatedAttempts})
                    }
                    this.setState({results: filterData(response.data, maxAttempts)});
                }
            });

        }, 15000); //15s

    }

    componentDidUpdate(prevProps) {
        if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber){

            this.setState({session: this.props.session, eventNumber: this.props.eventNumber, roundNumber: this.props.roundNumber, eventName: filterName(this.props.eventName), headerAttempts : ["Attempt 1", "Attempt 2", "Attempt 3"], results: [], eventRecord: this.state.eventRecord});

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.props.meetName, session: this.props.session, event: this.props.eventNumber, round: this.props.roundNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0){
                    this.setState({system: response.data[0][5]});
                    let maxAttempts = getMaxAttempts(response.data);
                    if(maxAttempts>3){
                        let updatedAttempts = [];
                        for(let i=0; i<maxAttempts; i++){
                            updatedAttempts[i] = "Attempt " + (i+1);
                        }
                        this.setState({headerAttempts: updatedAttempts})
                    }
                    this.setState({results: filterData(response.data, maxAttempts)});
                }
            });

        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if(this.state.results.length<=0){
            return <div className="eventResultField">
                <p className="eventNameField">{this.state.eventName}</p>
                <div className="eventRecordXC" style={{whiteSpace: 'pre-line'}}>{filterRecord(this.state.eventRecord)}</div>
            </div>
        }
        return <div className="eventResultField">
            <p className="eventNameField">{this.state.eventName}</p>
            <div className="eventRecordXC" style={{whiteSpace: 'pre-line'}}>{filterRecord(this.state.eventRecord)}</div>
            <table className="resultTableField">
                <thead>
                    <th className="placeField">Place</th>
                    <th className="flightField">Flight</th>
                    <th className="orderField">Order</th>
                    <th className="logoColumnField"></th>
                    <th className="athleteField">Athlete</th>
                    {this.state.headerAttempts.map( attempt => <th className="attemptField">{attempt}</th> )}
                    <th className="markField">Mark</th>
                    <th className="markField">Score</th>
                </thead>
                <tbody>{this.state.results.map( athlete => <tr className="resultTableFieldRow">
                    <td className="placeField">{athlete[0]}</td>
                    <td className="flightField">{athlete[1]}</td>
                    <td className="orderField">{athlete[2]}</td>
                    <td className="logoColumnField"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} className="logoField" alt=""></img></td>
                    <td className="athleteFieldDesktop"><table>
                        <tr><td className="athleteRowName">{athlete[4] + getGrade(athlete[7], this.state.roster)}</td></tr>
                        <tr><td className="athleteRowTeam">{athlete[3]}</td></tr>
                    </table></td>
                    {athlete[5].map( attempt => <td className="attemptFieldDesktop"><table>
                        <tr><td className="markRowDesktop">{getAttemptMark(attempt)}</td></tr>
                        <tr><td className="attemptConversion">{getMarkConversion(athlete[0], attempt, this.state.system, this.state.eventName)}</td></tr>
                        <tr><td className="windRowDesktop">{getAttemptWind(attempt)}</td></tr>
                    </table></td> )}
                    <td><table>
                        <tr><td className="markField">{getMark(athlete[0], athlete[6])}</td></tr>
                        <tr><td className="markFieldConversion">{getMarkConversion(athlete[0], athlete[6], this.state.system, this.state.eventName)}</td></tr>
                    </table></td>
                    <td className="markField">{getScore(athlete[0], athlete[6], this.state.eventGender, this.state.eventName)}</td>
                </tr>)}</tbody>
            </table>

            <MultisTable meetName={this.props.meetName} eventNumber={this.props.eventNumber} eventName={this.props.eventName}/>

        </div>
    }

}

function filterRecord(_record_){
    let updatedRecord = "";
    if(_record_===undefined || _record_==="" || _record_==="NULL"){
        return updatedRecord;
    }
    if(_record_[_record_.length-1]===';'){
        updatedRecord = _record_.substring(0, _record_.length-1);
    }
    return updatedRecord.replaceAll(';', '\n');
}

function getMarkConversion(_place_, _bestMark_, _system_, _eventName_){
    if(_place_==="" || _place_==="-" || _place_===null || _place_===undefined || _place_==="DNS" || _bestMark_==="X" || _bestMark_==="F" || _bestMark_==="-" || _bestMark_==="P"){
        return;
    }
    if(_system_==="Metric"){
        let englishMark = Math.floor((parseFloat(_bestMark_) + 0.004999) * 39.3700787402 * 100) / 100;
        let englishMarkString = "";

        let markFeet = parseInt(englishMark/12);
        let markInches = parseFloat(englishMark-markFeet*12).toFixed(2);

        if(markInches-parseInt(markInches)>0.75){
            markInches = markInches - (markInches - parseInt(markInches) - 0.75);
        }else if(markInches-parseInt(markInches)<0.75 && markInches-parseInt(markInches)>0.50){
            markInches = markInches - (markInches - parseInt(markInches) - 0.50);
        }else if(markInches-parseInt(markInches)<0.50 && markInches-parseInt(markInches)>0.25){
            markInches = markInches - (markInches - parseInt(markInches) - 0.25);
        }else if(markInches-parseInt(markInches)<0.25 && markInches-parseInt(markInches)>0.00){
            markInches = markInches - (markInches - parseInt(markInches));
        }

        if(!isNaN(markFeet) && !isNaN(markInches)){
            if(_eventName_.toUpperCase().includes("DISC") || _eventName_.toUpperCase().includes("JAV") || _eventName_.toUpperCase().includes("HAMMER")){
                englishMarkString = markFeet.toString() + "-" + parseFloat(markInches).toFixed(0);
                return englishMarkString;
            }else{
                englishMarkString = markFeet.toString() + "-" + parseFloat(markInches).toFixed(2);
                return englishMarkString;
            }
        }
    }
    return "";
}

function getMark(_place_, _bestMark_){
    if(_place_==="" || _place_==="-" || _place_===null || _place_===undefined || _place_==="DNS"){
        return "NM";
    }
    return _bestMark_;
}

function filterName(_name_){

    let updatedName = _name_;
    if(updatedName.includes("(M)")){
        updatedName = updatedName.replace("(M)", "Men's");
    }else if(updatedName.includes("(W)")){
        updatedName = updatedName.replace("(W)", "Women's");
    }else if(updatedName.includes("(B)")){
        updatedName = updatedName.replace("(B)", "Boys'");
    }else if(updatedName.includes("(G)")){
        updatedName = updatedName.replace("(G)", "Girls'");
    }
    return updatedName + " - Results";
}

function filterData(_results_, _maxAttempts_) {

    let filteredData = [];
    _results_.map( athlete => filteredData.push([getFieldPlace(athlete[7]), athlete[3], athlete[10], athlete[12], athlete[11], getAttempts(athlete[7], athlete[13], _maxAttempts_), getBestMark(athlete[7], athlete[13], athlete[5]), athlete[9]]) );
    return filteredData;
}

function getFieldPlace(_place_) {

    if(_place_===999999) {
        return "DNS";
    }else if(_place_===999998) {
        return "-";
    }else{
        return _place_;
    }
}

function getAttempts(_place_, _attempts_, _maxAttempts_) {

    let updatedAttempts = [];
    if(_place_===999999){
        for(let i=0; i<_maxAttempts_; i++){
            updatedAttempts[i] = "";
        }
        return updatedAttempts;
    }else{
        let attempts = _attempts_.replaceAll('F', 'X').split(',');
        for(let i=0; i<attempts.length; i+=2){
            if(attempts[i+1]==="-0.0" || attempts[i]==="F" || attempts[i]==="-" || attempts[i]==="X" || attempts[i]==="P"){
                if(attempts[i]!==undefined){
                    updatedAttempts[i/2] = attempts[i];
                }
            }else{
                if(attempts[i]!==undefined && attempts[i+1]!==undefined){
                    updatedAttempts[i/2] = attempts[i] + " (" + attempts[i+1] + ")";
                }
            }
        }
        if(updatedAttempts.length<_maxAttempts_){
            for(let i=updatedAttempts.length; i<_maxAttempts_; i++){
                updatedAttempts[i] = "";
            }
        }
    }
    return updatedAttempts;
}

function getBestMark(_place_, _attempts_, _system_) {
    if(_system_!=="English"){
        let bestMark = "0";
        let attempts = _attempts_.split(',');
        if(_place_===999999){
            return "";
        }
        for(let i=0; i<attempts.length; i+=2){
            let cAttempt = parseFloat(attempts[i]);
            let best =  parseFloat(bestMark);
            if(cAttempt > best){
                bestMark = attempts[i];
            }
        }
        if(bestMark==="0"){
            return "";
        }
        return bestMark;
    }else{
        let bestMark = "0";
        let attempts = _attempts_.split(',');
        if(_place_===999999){
            return "";
        }
        for(let i=0; i<attempts.length; i+=2){
            if(attempts[i]!=="F" && attempts[i]!=="-" && attempts[i]!=="P" && attempts[i]!=="X" && attempts[i]!==undefined && attempts[i]!==""){
                let cFeet = parseInt(attempts[i].substring(0, attempts[i].indexOf("-")));
                let cInches =  parseFloat(attempts[i].substring(attempts[i].indexOf("-")+1));
                let bFeet = parseInt(bestMark.substring(0, bestMark.indexOf("-")));
                let bInches =  parseFloat(bestMark.substring(bestMark.indexOf("-")+1));
                if(cFeet>bFeet || bestMark==="0"){
                    bestMark = attempts[i];
                }else if(cFeet===bFeet && cInches>bInches){
                    bestMark = attempts[i];
                }
            }
        }
        if(bestMark==="0"){
            return "";
        }
        return bestMark;
    }
}

function getMaxAttempts(_results_){
    let maxAttempts = 3;
    for(let i=0; i<_results_.length; i++){
        let attempts = _results_[i][13].split(',');
        if(attempts.length/2>maxAttempts && attempts.length/2>3 && attempts.length/2<7){
            maxAttempts = attempts.length/2;
        }
    }
    return maxAttempts;
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return "  (" + grade + ")";
        }
    }
    return "";
}

function getScore(_place_, _bestMark_, _gender_, _eventName_) {
    let score = 0;
    if(_place_==="DNS" || _place_==="SCR" || _place_==="DQ" || _bestMark_==="" || _bestMark_==="NM" || _bestMark_==="X" || _bestMark_==="F"){
        return score;
    }
    if( _gender_==="Men" && (_eventName_.toUpperCase().includes("SHOT") || _eventName_.toUpperCase().includes("SP") || _eventName_.toUpperCase().includes("S. P.") || _eventName_.toUpperCase().includes("S.P.")) ){
        score = Math.floor( 51.39 * Math.pow(parseFloat(_bestMark_) - 1.50, 1.05) );
    }else if( _gender_==="Women" && (_eventName_.toUpperCase().includes("SHOT") || _eventName_.toUpperCase().includes("SP") || _eventName_.toUpperCase().includes("S. P.") || _eventName_.toUpperCase().includes("S.P.")) ){
        score = Math.floor( 56.0211 * Math.pow(parseFloat(_bestMark_) - 1.50, 1.05) );
    }else if( _gender_==="Men" && (_eventName_.toUpperCase().includes("LONG") || _eventName_.toUpperCase().includes("LJ") || _eventName_.toUpperCase().includes("L. J.") || _eventName_.toUpperCase().includes("L.J.")) ){
        score = Math.floor( 0.14354 * Math.pow(parseFloat(_bestMark_)*100 - 220.00, 1.40) );
    }else if( _gender_==="Women" && (_eventName_.toUpperCase().includes("LONG") || _eventName_.toUpperCase().includes("LJ") || _eventName_.toUpperCase().includes("L. J.") || _eventName_.toUpperCase().includes("L.J.")) ){
        score = Math.floor( 0.188807 * Math.pow(parseFloat(_bestMark_)*100 - 210.00, 1.41) );
    }else if( _gender_==="Women" && _eventName_.toUpperCase().includes("JAV")){
        score = Math.floor( 15.9803 * Math.pow(parseFloat(_bestMark_) - 3.80, 1.04) );
    }else if( _gender_==="Men" && _eventName_.toUpperCase().includes("DISC")){
        score = Math.floor( 12.91 * Math.pow(parseFloat(_bestMark_) - 4.00, 1.10) );
    }else if( _gender_==="Men" && _eventName_.toUpperCase().includes("JAV")){
          score = Math.floor( 10.14 * Math.pow(parseFloat(_bestMark_) - 7.00, 1.08) );
    }
    return score;
}

function getAttemptMark(_attempt_){
    if(_attempt_.includes(' ')){
        return _attempt_.substring(0, _attempt_.indexOf(' '));
    }
    return _attempt_;
}

function getAttemptWind(_attempt_){
    if(_attempt_.includes(' ')){
        return _attempt_.substring(_attempt_.indexOf(' ')+1);
    }
    return '';
}

export default LiveResultsMultis;
