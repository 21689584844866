import React from 'react';
import axios from 'axios';
//import './ResultsPhone.css';
import PhoneResultsVertical from './PhoneResultsVertical.js';
//import LiveResultsVerticalMulti from './LiveResultsVerticalMulti.js';
//import LiveResultsMultis from './LiveResultsMultis.js';
import PhoneResultsField from './PhoneResultsField.js';
//import LiveResultsXC from './LiveResultsXC.js';
import PhoneResultsTrack from './PhoneResultsTrack.js';
import PhoneResultsXC from './PhoneResultsXC.js';

class ResultsPhone extends React.Component {

  state = {
      eventName: undefined,
      eventQs: undefined,
      eventqs: undefined,
      eventType: undefined,
      splitNames: undefined,
      scoring: undefined,
      divisions: undefined,
      meetType: undefined,
      timeZone: undefined,
      eventGender: undefined
  }

  componentDidMount() {

      axios.get("https://liveresults.trxctiming.com/api/meetInfo", { params: {id: this.props.match.params.id} }).then((response) => {
          if(response.data.length>0){
              this.setState({divisions: response.data[0][11], meetType: response.data[0][5], timeZone: response.data[0][12]});
          }
      });

      axios.get("https://liveresults.trxctiming.com/api/schedule", { params: {id: this.props.match.params.id, session: this.props.match.params.session} }).then((response) => {
          for(let i=0; i<response.data.length; i++){
              if(response.data[i][2]===parseInt(this.props.match.params.event) && response.data[i][3]===parseInt(this.props.match.params.round)){
                  this.setState({eventGender: getGender(response.data[i][5]), eventName: response.data[i][5], eventQs: response.data[i][8], eventqs: response.data[i][9], splitNames: response.data[i][13], scoring: response.data[i][12]});
                  if(response.data[i][5].includes("CC") || response.data[i][5].includes("XC") || response.data[i][5].includes("Cross Country") || this.state.meetType==="Cross Country"){
                      this.setState({eventType: "XC"});
                  }else if(response.data[i][10]==="Track"){
                      this.setState({eventType: "Track"});
                  }else{
                      this.setState({eventType: getEventType(response.data[i][5])});
                  }
              }
          }
      });
  }

  componentDidUpdate() {

  }

  componentWillUnmount() {

  }

  render() {
      return <div>
          {getResults(this)}
      </div>;
  }

}

function getResults(_this_){
    if(_this_.state.eventType==="Track"){
        return <PhoneResultsTrack meetName={_this_.props.match.params.id} session={1} eventNumber={_this_.props.match.params.event} roundNumber={_this_.props.match.params.round} eventName={_this_.state.eventName} eventQs={_this_.state.eventQs} eventqs={_this_.state.eventqs} timeZone={_this_.state.timeZone} divisions={_this_.state.divisions} eventGender={_this_.state.eventGender}/>;
    } else if(_this_.state.eventType==="Vertical"){
        return <PhoneResultsVertical meetName={_this_.props.match.params.id} session={1} eventNumber={_this_.props.match.params.event} roundNumber={_this_.props.match.params.round} eventName={_this_.state.eventName} divisions={_this_.state.divisions} eventGender={_this_.state.eventGender}/>;
    } else if(_this_.state.eventType==="Field"){
        return <PhoneResultsField meetName={_this_.props.match.params.id} session={1} eventNumber={_this_.props.match.params.event} roundNumber={_this_.props.match.params.round} eventName={_this_.state.eventName} divisions={_this_.state.divisions} eventGender={_this_.state.eventGender}/>;
    } else if(_this_.state.eventType==="XC"){
        return <PhoneResultsXC meetName={_this_.props.match.params.id} session={1} eventNumber={_this_.props.match.params.event} roundNumber={_this_.props.match.params.round} eventName={_this_.state.eventName} minTeamRunners={_this_.state.eventQs} maxTeamRunners={_this_.state.eventqs} splitNames={_this_.state.splitNames} scoring={_this_.state.scoring} divisions={_this_.state.divisions} timeZone={_this_.state.timeZone}/>;
    }
    return <div></div>;
}

function getGender(_eventName_){
    if(_eventName_.toUpperCase().includes("(G)") || _eventName_.toUpperCase().includes("GIRLS") || _eventName_.toUpperCase().includes("(W)") || _eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("GIRL") || _eventName_.toUpperCase().includes("WOMAN")){
        return "Women";
    }else{
        return "Men";
    }
}

function getEventType(_eventName_){
    if(_eventName_.toUpperCase().includes("PV") || _eventName_.toUpperCase().includes("P.V.") || _eventName_.toUpperCase().includes("POLE VAULT")){
        /*if(_eventName_.toUpperCase().includes("HEPT")){
            return "Multi-Vertical";
        }if(_eventName_.toUpperCase().includes("DEC")){
            return "Multi-Vertical";
        }if(_eventName_.toUpperCase().includes("PENT")){
            return "Multi-Vertical";
        }else{*/
            return "Vertical";
        //}
    }else if(_eventName_.toUpperCase().includes("HJ") || _eventName_.toUpperCase().includes("H.J.") || _eventName_.toUpperCase().includes("HIGH JUMP")){
        /*if(_eventName_.toUpperCase().includes("HEPT")){
            return "Multi-Vertical";
        }if(_eventName_.toUpperCase().includes("DEC")){
            return "Multi-Vertical";
        }if(_eventName_.toUpperCase().includes("PENT")){
            return "Multi-Vertical";
        }else{*/
            return "Vertical";
        //}
    }else{
        if(_eventName_.toUpperCase().includes("HEPT RESULTS")){
            return "Multis";
        }if(_eventName_.toUpperCase().includes("DEC RESULTS")){
            return "Multis";
        }if(_eventName_.toUpperCase().includes("PENT RESULTS")){
            return "Multis";
        }else{
            return "Field";
        }
    }
}

export default ResultsPhone;
