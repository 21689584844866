import React from 'react';
import axios from 'axios';
import './LiveResultsTrack.css';
import MultisTable from './MultisTable.js';
import LiveClock from './LiveClock.js';

class LiveResultsTrackMulti extends React.Component {

    state = {
        meetName: this.props.meetName,
        session: this.props.session,
        eventNumber: this.props.eventNumber,
        roundNumber: this.props.roundNumber,
        eventRecord: this.props.eventRecord,
        heatNumber: 0,
        totalHeats: [],
        wind: undefined,
        eventName: this.props.eventName,
        eventQs: this.props.eventQs,
        eventqs: this.props.eventqs,
        results: [],
        roster: [],
        windReadings: [],
        eventStatus: this.props.eventStatus,
        timeZone: this.props.timeZone
    }

    componentDidMount() {

        this.setState({eventName: filterName(this.state.eventName)});

        axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Track"} }).then((response) => {
            if(response.data.length>0){
                let filteredResults = filterData(response.data, this.state.eventQs, this.state.eventqs, this.state.heatNumber);
                let totalHeats = getHeats(filteredResults);
                let windReadings = getWindReadings(response.data);
                this.setState({windReadings: windReadings});
                this.setState({results: filteredResults, totalHeats: totalHeats});
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});
        });


        //set interval for live results updates
        this.interval = setInterval(() => {

          axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Track"} }).then((response) => {
              if(response.data.length>0 && response.data.length>=this.state.results.length){
                  let filteredResults = filterData(response.data, this.state.eventQs, this.state.eventqs, this.state.heatNumber);
                  let totalHeats = getHeats(filteredResults);
                  if(totalHeats.length > this.state.totalHeats.length){
                      this.setState({totalHeats: totalHeats});
                  }
                  let windReadings = getWindReadings(response.data);
                  this.setState({windReadings: windReadings});
                  this.setState({results: filteredResults});
              }
          });

        }, 5000);

    }

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber || prevState.heatNumber!==this.state.heatNumber){

            if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber){
                //heatNumber and totalHeats set separately to avoid wrong buttons displayed on next event clicked
                this.setState({heatNumber: 0, totalHeats: []});
            }

            this.setState({session: this.props.session, eventNumber: this.props.eventNumber, roundNumber: this.props.roundNumber, eventName: filterName(this.props.eventName), eventQs: this.props.eventQs, eventqs: this.props.eventqs, eventRecord: this.props.eventRecord, results: [], wind: undefined});

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.props.meetName, session: this.props.session, event: this.props.eventNumber, round: this.props.roundNumber, type: "Track"} }).then((response) => {
                if(response.data.length>0){
                    let filteredResults = filterData(response.data, this.state.eventQs, this.state.eventqs, this.state.heatNumber);
                    let totalHeats = getHeats(filteredResults);
                    if(totalHeats.length > this.state.totalHeats.length){
                        this.setState({totalHeats: totalHeats});
                    }
                    let windReadings = getWindReadings(response.data);
                    this.setState({windReadings: windReadings});
                    this.setState({results: filteredResults});
                }
            });
        }

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if(this.state.results.length<=0){
            return <div className="eventResultsTrack">
                <p className="eventNameTrack">{this.state.eventName}</p>
                <div className="eventRecordXC" style={{whiteSpace: 'pre-line'}}>{filterRecord(this.state.eventRecord)}</div>
            </div>
        }
        return <div className="eventResultsTrack">
            <div className="eventNameTrack">{this.state.eventName}</div>
            <div className="eventRecordXC" style={{whiteSpace: 'pre-line'}}>{filterRecord(this.state.eventRecord)}</div>
            {getRunningClock(this.state.eventName, this.state.eventStatus, this.state.meetName, this.state.eventNumber, this.state.roundNumber, this.state.timeZone)}
            <table className="heatButtonsDesktop"><tr>{this.state.totalHeats.map( (heat, index) => <td className="heatButtonDesktop" onClick={() => this.setState({heatNumber: index})}>{heat}</td>)}</tr></table>
            <table className="resultTableTrackDesktop">
                <thead>
                    <td className="placeTrackDesktop">Place</td>
                    <td className="heatTrackDesktop">Heat</td>
                    <td className="logoColumnTrackDesktop"></td>
                    <td className="athleteTrackDesktop">Athlete</td>
                    <td className="athleteTimeDesktop"><a>Time</a></td>
                </thead>
                {this.state.results.map( athlete => <tbody><tr className="resultTableTrackRowDesktop">
                    <td colspan="5"><table><tr><td className="placeTrackDesktop">{getPlace(athlete[0], athlete[6])}</td>
                    <td className="heatTrackDesktop">{athlete[1] + '-' + athlete[2]}</td>
                    <td className="logoColumnTrackDesktop"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[4] + ".png"} className="logoTrack" alt=""></img></td>
                    <td className="athleteTrackDesktop"><table>
                        <tr><td className="athleteTrackRowName">{athlete[5] + getGrade(athlete[8], this.state.roster)}</td></tr>
                        <tr><td className="athleteTrackRowTeam">{athlete[4]}</td></tr>
                    </table></td>
                    <td className="athleteTimeDesktop"><table>
                        <tr><td className="athleteMarkRowTime"><a>{athlete[6] + getScore(athlete[0], athlete[6], this.props.eventGender, this.state.eventName)}</a></td></tr>
                        <tr><td className="athleteMarkRowWind"><a>{getWind(this.state.eventName, this.state.windReadings, athlete[1], athlete[6])}</a></td></tr>
                    </table></td></tr></table></td></tr>
                    {getSplits(this.state.eventName, athlete[7], athlete[6])}
                </tbody>)}
            </table>
            <MultisTable meetName={this.props.meetName} eventNumber={this.props.eventNumber} eventName={this.props.eventName}/>

        </div>
    }

}

function filterRecord(_record_){
    let updatedRecord = "";
    if(_record_===undefined || _record_==="" || _record_==="NULL"){
        return updatedRecord;
    }
    if(_record_[_record_.length-1]===';'){
        updatedRecord = _record_.substring(0, _record_.length-1);
    }
    return updatedRecord.replaceAll(';', '\n');
}

function getRunningClock(_eventName_, _status_, _meetName_, _eventNumber_, _roundNumber_, _timeZone_){
    if(_eventName_.toUpperCase().includes("60 M") || _eventName_.toUpperCase().includes("100 M") || (_eventName_.toUpperCase().includes("200 M") && !_eventName_.toUpperCase().includes("3200 M")) || _eventName_.toUpperCase().includes("55 M")){
        return;
    }
    return <LiveClock meetName={_meetName_} eventNumber={_eventNumber_} roundNumber={_roundNumber_} eventStatus={_status_} timeZone={_timeZone_}/>;
}

function filterData(_results_, _Qs_, _qs_, _currentHeat_) {

    let filteredData = [];
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][3]===_currentHeat_ || _currentHeat_===0){
            filteredData.push( [getTrackPlace(_results_[i][8], _results_[i][13], _results_), _results_[i][3], _results_[i][10], _results_[i][9], _results_[i][12], _results_[i][11], getTime(_results_[i][8], _results_[i][13], _results_, _Qs_, _qs_), _results_[i][15], _results_[i][9]]);
        }
    }
    return filteredData;
}

function getTrackPlace(_place_, _time_, _results_) {

    if(_place_===999999 || _place_==="DNS") {
        return "DNS";
    }else if(_place_===999998 || _place_==="DNF") {
        return "DNF";
    }else if(_place_===999997 || _place_==="DQ") {
        return "DQ";
    }else if(_place_===999996 || _place_==="FS") {
        return "FS";
    }else if(_place_===999995 || _place_==="SCR") {
        return "SCR";
    }else if(_place_===999994 || _place_==="ADV") {
        return "ADV";
    }else if(_place_===999993){
        return "-";
    }else {
        for(let i=0; i<_results_.length; i++){
            if(_results_[i][13]===_time_){
                return i+1;
            }
        }
        return "-";
    }
}

function getTime(_place_, _time_, _results_, _Qs_, _qs_) {

    if(_place_>=999993){
        return "";
    }else if(_time_ === "99:99:99.999") {
        return "";
    }else if(_time_ === "99:99:99.998") {
        return "";
    }else if(_time_ === "99:99:99.997") {
        return "";
    }else if(_time_ === "99:99:99.996") {
        return "";
    }else if(_time_.substring(0, 8) === "99:99:99") {
        return "";
    }else {
        let timeCount = 0;
        for(let i=0; i<_results_.length; i++){
            if(roundTime(_results_[i][13])===roundTime(_time_)){
                timeCount++;
            }
        }
        if(timeCount>1){
            if(_place_<=_Qs_){
                return roundTime(_time_) + " (" + _time_ + ")Q";
            }else if(getTimeQualifer(_time_, _results_, _Qs_, _qs_)){
                return roundTime(_time_) + " (" + _time_ + ")q";
            }else{
                return roundTime(_time_) + " (" + _time_ + ")";
            }
        }else{
            if(_place_<=_Qs_){
                return roundTime(_time_) + "Q";
            }else if(getTimeQualifer(_time_, _results_, _Qs_, _qs_)){
                return roundTime(_time_) + "q";
            }else{
                return roundTime(_time_);
            }
        }
    }
}

function roundTime(_time_){

    if(_time_.substring(_time_.indexOf('.')+1).length<=2){
        return _time_;
    }
    if(_time_ === "99:99:99.999") {
        return "";
    }else if(_time_ === "99:99:99.998") {
        return "";
    }else if(_time_ === "99:99:99.997") {
        return "";
    }else if(_time_ === "99:99:99.996") {
        return "";
    }else {
        let adjustedTime = _time_.substring(0, _time_.indexOf('.')+1);
        let decimals = _time_.substring(_time_.indexOf('.')+1);
        if(decimals>990){
            if(adjustedTime.includes(':')){
                let mins = adjustedTime.substring(0, adjustedTime.indexOf(':')+1);
                let seconds = adjustedTime.substring(adjustedTime.indexOf(':')+1);
                if(!seconds.includes(':')){
                    decimals = "000";
                    if(parseInt(seconds)<10){
                        adjustedTime = (mins + '0' + (parseInt(seconds)+1) + '.');
                    }else {
                        adjustedTime = (mins + (parseInt(seconds)+1) + '.');
                    }
                }

            }else{
                decimals = "000";
                adjustedTime = (parseInt(adjustedTime)+1) + '.';
            }
        }
        if(decimals[2]==='0'){
            decimals = decimals[0] + '.' + decimals[1] + decimals[2];
        }else if(decimals[2]!=='0'){
            decimals = decimals[0] + '.' + decimals[1] + '9';
        }
        decimals = parseFloat(decimals).toFixed(1);
        decimals = decimals[0] + decimals[2];
        return adjustedTime + decimals;
    }
}

function getSplits(_eventName_, _splits_, _finalTime_){
    if(_splits_==="NULL" || _splits_===null || _splits_===undefined){
        return;
    }
    if(_eventName_.toUpperCase().includes("60 M") || _eventName_.toUpperCase().includes("100 M") || (_eventName_.toUpperCase().includes("200 M") && !_eventName_.toUpperCase().includes("3200 M") )){
        return;
    }
    let splits = _splits_.split(',');
    if(splits.length<1 || !_splits_.includes('.') || (_finalTime_.length>0 && splits.length<=1) ){
        return;
    }else{
        for(let i=0; i<splits.length; i++){
            let sumTime = splits[i].substring(0, splits[i].indexOf(' '));
            let splitTime = splits[i].substring(splits[i].indexOf(' ')+2, splits[i].length-1);
            splits[i] = roundTime(sumTime) + ' (' + roundTime(splitTime) + ')';
        }
    }
    return <tr><td colSpan="5"><div className="splitsDivDesktop"><table align="center"  className="splitsTableDesktop"><tr>{splits.map( (split, index) => <td className="splitBoxDesktop">{split}</td>)}</tr></table></div></td></tr>;
}

function filterName(_name_){
    let updatedName = _name_;
    if(updatedName.includes("(M)")){
        updatedName = updatedName.replace("(M)", "Men's");
    }else if(updatedName.includes("(W)")){
        updatedName = updatedName.replace("(W)", "Women's");
    }else if(updatedName.includes("(B)")){
        updatedName = updatedName.replace("(B)", "Boys'");
    }else if(updatedName.includes("(G)")){
        updatedName = updatedName.replace("(G)", "Girls'");
    }
    return updatedName + " - Results";
}

function getTimeQualifer(_time_, _results_, _Qs_, _qs_){
    let timeQualifiers = 0;
    let lastTimeIn = null;
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][8]>_Qs_){
            if(_results_[i][13]===_time_){
                if(timeQualifiers<_qs_ || lastTimeIn===_time_){
                    return true;
                }else{
                    return false;
                }
            }else{
                timeQualifiers++;
                lastTimeIn = _results_[i][13];
            }
        }
    }
    return false;
}

function getHeats(_results_){
    let totalHeats = 0;
    let heatArray = []
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][1]>totalHeats){
            totalHeats = _results_[i][1];
        }
    }if(totalHeats>1){
        heatArray.push("Compiled");
        for (let i=1; i<=totalHeats; i++){
            heatArray.push("Heat " + i);
        }
    }
    return heatArray;
}

function getWindReadings(_results_){
    let windReadings = [];
    if(_results_===undefined || _results_.length<=0){
      return windReadings;
    }
    for(let i=0; i<_results_.length; i++){
        windReadings[_results_[i][3]] = _results_[i][5];
    }
    return windReadings;
}

function getWind(_eventName_, _windReadings_, _cHeat_, _time_){
    if(_time_===undefined || _time_===null || _time_.length<=0){
      return;
    }
    if(_eventName_.includes("300") || _eventName_.includes("400")){
        return;
    }else if(_eventName_.includes("500") || _eventName_.includes("600") || _eventName_.includes("800")){
        return;
    }else if(_eventName_.includes("1000") || _eventName_.includes("1500")){
        return;
    }else if(_eventName_.includes("1600") || _eventName_.includes("1609") || _eventName_.includes("Mile")){
        return;
    }else if(_eventName_.includes("3000") || _eventName_.includes("3200")){
        return;
    }else if(_eventName_.includes("5000") || _eventName_.includes("10000")){
        return;
    }else if(_eventName_.includes("4x100") || _eventName_.includes("4x200")){
        return;
    }
    return _windReadings_[_cHeat_];
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return "  (" + grade + ")";
        }
    }
    return "";
}

function getScore(_place_, _mark_, _gender_, _eventName_) {
    let score = 0;
    if(_place_==="DNS" || _place_==="DNF" || _place_==="DQ" || _place_==="FS" || _place_==="SCR" || _mark_===""){
        return "";
    }
    let timeInSeconds = getSeconds(_mark_);
    if( _gender_==="Women" && (_eventName_.toUpperCase().includes("60 METER HURDLES") || _eventName_.toUpperCase().includes("60 M. H.") || _eventName_.toUpperCase().includes("60 M.H.")) ){
        score = Math.floor( 20.0479 * Math.pow(17.00 - parseFloat(timeInSeconds), 1.835) );
    }else if( _gender_==="Men" && (_eventName_.toUpperCase().includes("60 METER HURDLES") || _eventName_.toUpperCase().includes("60 M. H.") || _eventName_.toUpperCase().includes("60 M.H.")) ){
        score = Math.floor( 20.5173 * Math.pow(15.50 - parseFloat(timeInSeconds), 1.92) );
    }else if( _gender_==="Men" && (_eventName_.toUpperCase().includes("60 METER DASH") || _eventName_.toUpperCase().includes("60 M. D.") || _eventName_.toUpperCase().includes("60 M.D.")) ){
        score = Math.floor( 58.0150 * Math.pow(11.50 - parseFloat(timeInSeconds), 1.81) );
    }else if( _gender_==="Women" && (_eventName_.toUpperCase().includes("800")) ){
        score = Math.floor( 0.11193 * Math.pow(254.00 - parseFloat(timeInSeconds), 1.88) );
    }else if( _gender_==="Men" && (_eventName_.toUpperCase().includes("1000")) ){
        score = Math.floor( 0.08713 * Math.pow(305.50 - parseFloat(timeInSeconds), 1.85) );
    }else if( _gender_==="Women" && (_eventName_.toUpperCase().includes("100 METER H.") || _eventName_.toUpperCase().includes("100 METER HURDLES") || _eventName_.toUpperCase().includes("100 M. H.") || _eventName_.toUpperCase().includes("100 M.H.")) ){
        score = Math.floor( 9.23076 * Math.pow(26.70 - parseFloat(timeInSeconds), 1.835) );
    }else if( _gender_==="Women" && (_eventName_.toUpperCase().includes("200 METER DASH") || _eventName_.toUpperCase().includes("200 M. D.") || _eventName_.toUpperCase().includes("200 M.D.")) ){
        score = Math.floor( 4.99087 * Math.pow(42.50 - parseFloat(timeInSeconds), 1.81) );
    }else if( _gender_==="Men" && (_eventName_.toUpperCase().includes("100 METER DASH") || _eventName_.toUpperCase().includes("100 M. D.") || _eventName_.toUpperCase().includes("100 M.D.") || _eventName_.toUpperCase().includes("100 METER D.")) ){
        score = Math.floor( 25.4347 * Math.pow(18.00 - parseFloat(timeInSeconds), 1.81) );
    }else if( _gender_==="Men" && (_eventName_.toUpperCase().includes("400 METER DASH") || _eventName_.toUpperCase().includes("400 M. D.") || _eventName_.toUpperCase().includes("400 M.D.") || _eventName_.toUpperCase().includes("400 METER D.") || _eventName_.toUpperCase().includes("400 M. DASH")) ){
        score = Math.floor( 1.53775 * Math.pow(82.00 - parseFloat(timeInSeconds), 1.81) );
    }else if( _gender_==="Men" && (_eventName_.toUpperCase().includes("110 METER HURDLES") || _eventName_.toUpperCase().includes("110 M. H.") || _eventName_.toUpperCase().includes("110 M.H.") || _eventName_.toUpperCase().includes("110 METER H.")) ){
        score = Math.floor( 5.74352 * Math.pow(28.50 - parseFloat(timeInSeconds), 1.92) );
    }else if( _gender_==="Men" && _eventName_.toUpperCase().includes("1500")){
        score = Math.floor( 0.03768 * Math.pow(480.00 - parseFloat(timeInSeconds), 1.85) );
    }
    return ' (' + score + ')';
}

function getSeconds(_mark_){
    let updatedTime = _mark_;
    if(updatedTime.includes(" ")){
        updatedTime = updatedTime.substring(0, updatedTime.indexOf(" "));
    }
    if(updatedTime.includes(":")){
        let minutes = updatedTime.substring(0, updatedTime.indexOf(":"));
        let seconds = updatedTime.substring(updatedTime.indexOf(":")+1);
        updatedTime = (parseInt(minutes)*60 + parseFloat(seconds)).toFixed(2);
    }
    return updatedTime;
}

function getPlace(_place_, _time_){
    if(_time_!==null && _time_!==undefined && _time_.length>0){
        return _place_;
    }else if(_place_==="DNS" || _place_==="DNF" || _place_==="ADV" || _place_==="DQ" || _place_==="FS"){
        return _place_;
    }else{
        return '-';
    }
}

export default LiveResultsTrackMulti;
