import React from 'react';
import axios from 'axios';
import './StartListTrack.css';
class StartListTrack extends React.Component {

    state = {
        meetName: this.props.meetName,
        session: this.props.session,
        eventNumber: this.props.eventNumber,
        roundNumber: this.props.roundNumber,
        eventName: this.props.eventName,
        entries: [],
        roster: [],
        maxHeats: []
    }

    componentDidMount() {

        this.setState({eventName: filterName(this.state.eventName)});

        axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.state.meetName, event: this.state.eventNumber, round: this.state.roundNumber, session: this.state.session} }).then((response) => {
            this.setState({entries: response.data, maxHeats: getMaxHeats(response.data)});
        });

        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});
        });
    }

    componentDidUpdate(prevProps) {

        if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber){

            this.setState({session: this.props.session, eventNumber: this.props.eventNumber, roundNumber: this.props.roundNumber, eventName: filterName(this.props.eventName), entries: []});

            axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.props.meetName, event: this.props.eventNumber, round: this.props.roundNumber, session: this.props.session} }).then((response) => {
                this.setState({entries: response.data, maxHeats: getMaxHeats(response.data)});
            });

            axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
                this.setState({roster: response.data});
            });
        }
    }

    componentWillUnmount() {

    }

    render() {
        return <div className="startListScreenTrack">
            <div className="eventNameTrack">{this.state.eventName}</div>


            {this.state.maxHeats.map( (heatResults, index) => <div><button className="heatButton" onClick={() => displayHeat(index)}>{'Heat ' + (index+1)}</button>
                <div className="heatResults" id={index} style={{display: 'block'}}>
                    <table className="startListTableTrack">
                        <thead>
                            <th className="heatTrack">Heat</th>
                            <th className="logoColumnTrack"></th>
                            <th className="athleteTrack">Athlete</th>
                            <th className="trackTeam">Team</th>
                            {getEntryHeader(this.state.entries)}
                        </thead>
                        {getHeatList(this.state.entries, index+1).map( entry => <tbody><tr>
                              <td className="heatTrack">{entry[3] + '-' + entry[6]}</td>
                              <td className="logoColumnTrack"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + entry[8] + ".png"} alt={entry[8]} className="logoTrack"></img></td>
                              <td className="athleteTrackSL">{entry[7] + getGrade(entry[5], this.state.roster)}</td>
                              <td className="trackTeam">{entry[8]}</td>
                              {getEntry(entry[9])}
                        </tr></tbody>)}
            </table></div></div>)}

        </div>
    }

}

function getHeatList(_startList_, _heat_){
    let entries = [];
    for(let i=0; i<_startList_.length; i++){
        if(_startList_[i][3]===_heat_){
            entries.push(_startList_[i])
        }
    }
    return entries;
}

function displayHeat(_heat_){
    if(document.getElementById(_heat_).style.display==="block"){
        document.getElementById(_heat_).style.display = "none";
    }else if(document.getElementById(_heat_).style.display==="none"){
        document.getElementById(_heat_).style.display = "block";
    }
    return;
}

function getMaxHeats(_startList_){
    let maxHeat = 0;
    for(let i=0; i<_startList_.length; i++){
        if(_startList_[i][3]>maxHeat){
            maxHeat = _startList_[i][3];
        }
    }
    let heats = [];
    for(let i=0; i<maxHeat; i++){
        heats.push('');
    }
    return heats;
}

function filterName(_name_){
    let updatedName = _name_;
    if(updatedName.includes("(M)")){
        updatedName = updatedName.replace("(M)", "Men's");
    }else if(updatedName.includes("(W)")){
        updatedName = updatedName.replace("(W)", "Women's");
    }else if(updatedName.includes("(B)")){
        updatedName = updatedName.replace("(B)", "Boys'");
    }else if(updatedName.includes("(G)")){
        updatedName = updatedName.replace("(G)", "Girls'");
    }
    return updatedName + " - Start List";
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return "  (" + grade + ")";
        }
    }
    return "";
}

function getEntryHeader(_entries_){
    if(_entries_.length<=0){
        return;
    }
    if(_entries_[0][9]!==undefined && _entries_[0][9]!=="" && _entries_[0][9]!=="NULL" && _entries_[0][9]!==null){
        return <th className="trackEntry">Entry Mark</th>;
    }else {
        return;
    }
}

function getEntry(_entry_){
      if(_entry_!==undefined && _entry_!=="" && _entry_!=="NULL" && _entry_!==null){
          return <td className="trackEntry">{_entry_}</td>;
      }
}

export default StartListTrack;
