import React from 'react';
import axios from 'axios';
import './PhoneResultsXC.css';
import PhoneLiveClock from './PhoneLiveClock.js';

class PhoneResultsXC extends React.Component {

    state = {
        meetName: this.props.meetName,
        session: this.props.session,
        eventNumber: this.props.eventNumber,
        roundNumber: this.props.roundNumber,
        eventName: this.props.eventName,
        eventGender: null,
        results: [[]],
        d1Results: [[]],
        d2Results: [[]],
        mResults: [[]],
        d1MResults: [[]],
        d2MResults: [[]],
        fResults: [[]],
        d1FResults: [[]],
        d2FResults: [[]],
        minTeamRunners: 5,
        maxTeamRunners: 7,
        roster: [],
        teams: [],
        status: 0,
        gender: 0,
        division: 0,
        cDivision: 0,
        split: 0,
        splitNames: [],
        scoring: this.props.scoring,
        divisions: this.props.divisions,
        filteredData: [],
        timeZone: this.props.timeZone
    }

    componentDidMount() {

        //set race gender
        this.setState({eventGender: getGender(this.props.eventName)});

        //get split names
        if(this.props.splitNames!==undefined && this.props.splitNames!==null && this.props.splitNames!=="NULL"){
            let splits = this.props.splitNames;
            this.setState({splitNames: splits.split(',')});
        }

        //set team scorers and pushers
        if(parseInt(this.props.minTeamRunners)>0 && parseInt(this.props.maxTeamRunners)>0){
            this.setState({minTeamRunners: parseInt(this.props.minTeamRunners), maxTeamRunners: parseInt(this.props.maxTeamRunners)});
        }

        //get meet roster
        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});

            //get meet teams
            axios.get("https://liveresults.trxctiming.com/api/teams", { params: {id: this.state.meetName} }).then(response => {
                this.setState({teams: response.data});
                this.setState({division: checkTeamDivisions(response.data, this.state.eventGender)} );
            });

            //get results
            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Track"} }).then((response) => {
                if(response.data.length>0){
                    this.setState({filteredData: filterData(response.data)});
                    this.setState({results: getResults(filterData(response.data), this.state.roster, "0")});
                    //if mixed race get male and female results
                    if(this.state.eventGender==="0"){
                        this.setState({fResults: getResults(filterData(response.data), this.state.roster, "F")});
                        this.setState({mResults: getResults(filterData(response.data), this.state.roster, "M")});
                    }
                }
            });
        });

        //set interval for live results updates
        this.interval = setInterval(() => {

          //get results
          axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Track"} }).then((response) => {

              if(response.data.length>0){
                  this.setState({filteredData: filterData(response.data)});
                  this.setState({results: getResults(filterData(response.data), this.state.roster, "0")});
                  //if mixed race get male and female results
                  if(this.state.eventGender==="0"){
                      this.setState({fResults: getResults(filterData(response.data), this.state.roster, "F")});
                      this.setState({mResults: getResults(filterData(response.data), this.state.roster, "M")});
                  }
              }
          });

        }, 5000);

    }

    componentDidUpdate(prevProps) {

        if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber){

            this.setState({scoring: this.props.scoring, session: this.props.session, eventNumber: this.props.eventNumber, eventGender: getGender(this.props.eventName), roundNumber: this.props.roundNumber, eventName: filterName(this.props.eventName), results: [[]], split: 0, status: 0, minTeamRunners:5, maxTeamRunners: 7, splitNames: [], cDivision: 0});

            if(this.props.splitNames!==undefined && this.props.splitNames!==null && this.props.splitNames!=="NULL"){
                let splits = this.props.splitNames;
                this.setState({splitNames: splits.split(',')});
            }

            if(parseInt(this.props.minTeamRunners)>0 && parseInt(this.props.maxTeamRunners)>0){
                this.setState({minTeamRunners: parseInt(this.props.minTeamRunners), maxTeamRunners: parseInt(this.props.maxTeamRunners)});
            }

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.props.meetName, session: this.props.session, event: this.props.eventNumber, round: this.props.roundNumber, type: "Track"} }).then((response) => {
                if(response.data.length>0){
                    this.setState({filteredData: filterData(response.data)});
                    this.setState({results: getResults(filterData(response.data), this.state.roster, "0")});
                    if(this.state.eventGender==="0"){
                        this.setState({fResults: getResults(filterData(response.data), this.state.roster, "F")});
                        this.setState({mResults: getResults(filterData(response.data), this.state.roster, "M")});
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return <div className="phoneResultsXC">
            <div className="meetHeaderPhone">
                <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.props.meetName + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                <div className="meetInfoPhoneXC">
                    <p className="meetTitlePhoneXC">{this.state.meetName.replaceAll('_', ' ')}</p>
                    <p className="meetHostPhoneXC">{this.state.eventName}</p>
                    <p className="meetHostPhoneXC">{"Scoring: Top " + this.state.minTeamRunners + " Athletes"}</p>
                    {getTeamScoresButton(this)}
                </div>
            </div>
            <div className="meetHeaderPhoneEmpty"></div>
            {getRunningClock(this.state.meetName, this.state.eventNumber, this.state.roundNumber, this.state.timeZone)}
            {getGenderButtons(this.state.eventGender, this.state.status, this, this.state.gender)}
            {getDivisionButtons(this.state.division, this)}
            {getSplitButtons(this.state.results[0], this.state.splitNames, this)}
            {getIndividualScores(this.state.results, this.state.roster, this.state.status, this.state.split, this.state.gender, this.state.splitNames, this.state.cDivision, this.props.meetName, this)}
            {getIndividualDivisionScores(this.state.filteredData, this.state.roster, this.state.status, this.state.split, this.state.gender, this.state.splitNames, this.state.cDivision, this.state.teams, this.state.eventGender, this.state.divisions, this.props.meetName, this)}
            {getIndividualFemaleScores(this.state.fResults, this.state.roster, this.state.status, this.state.split, this.state.gender, this.state.splitNames, this.state.cDivision, this.props.meetName, this)}
            {getIndividualDivisionFemaleScores(this.state.filteredData, this.state.roster, this.state.status, this.state.split, this.state.gender, this.state.splitNames, this.state.cDivision, this.state.teams, this.state.eventGender, this.state.divisions, this.props.meetName, this)}
            {getIndividualMaleScores(this.state.mResults, this.state.roster, this.state.status, this.state.split, this.state.gender, this.state.splitNames, this.state.cDivision, this.props.meetName, this)}
            {getIndividualDivisionMaleScores(this.state.filteredData, this.state.roster, this.state.status, this.state.split, this.state.gender, this.state.splitNames, this.state.cDivision, this.state.teams, this.state.eventGender, this.state.divisions, this.props.meetName, this)}
            {getTeamScores(this.state.results, this.state.status, this.state.split, this.state.eventGender, this.state.minTeamRunners, this.state.maxTeamRunners, this.state.teams, this.state.scoring, this.state.cDivision, this.props.meetName, this)}
            {getDivisionTeamScores(this.state.filteredData, this.state.status, this.state.split, this.state.eventGender, this.state.minTeamRunners, this.state.maxTeamRunners, this.state.teams, this.state.scoring, this.state.cDivision, this.state.gender, this.state.divisions, this.props.meetName, this)}
            {getFemaleScores(this.state.fResults, this.state.status, this.state.split, this.state.gender, this.state.minTeamRunners, this.state.maxTeamRunners, this.state.teams, this.state.roster, this.state.scoring, this.state.cDivision, this.props.meetName, this)}
            {getFemaleDivisionTeamScores(this.state.filteredData, this.state.roster, this.state.status, this.state.split, this.state.gender, this.state.splitNames, this.state.cDivision, this.state.teams, this.state.eventGender, this.state.divisions, this.state.minTeamRunners, this.state.maxTeamRunners, this.state.scoring, this.props.meetName, this)}
            {getMaleScores(this.state.mResults, this.state.status, this.state.split, this.state.gender, this.state.minTeamRunners, this.state.maxTeamRunners, this.state.teams, this.state.roster, this.state.scoring, this.state.cDivision, this.props.meetName, this)}
            {getMaleDivisionTeamScores(this.state.filteredData, this.state.roster, this.state.status, this.state.split, this.state.gender, this.state.splitNames, this.state.cDivision, this.state.teams, this.state.eventGender, this.state.divisions, this.state.minTeamRunners, this.state.maxTeamRunners, this.state.scoring, this.props.meetName, this)}
            {getMixedTeamScores(this.state.results, this.state.status, this.state.split, this.state.gender, this.state.minTeamRunners, this.state.maxTeamRunners, this.state.teams, this.state.scoring, this.state.cDivision, this.props.meetName, this.state.eventGender, this)}
        </div>
    }

}

function getRunningClock(_meetName_, _eventNumber_, _roundNumber_, _timeZone_){
    return <PhoneLiveClock meetName={_meetName_} eventNumber={_eventNumber_} roundNumber={_roundNumber_} timeZone={_timeZone_}/>;
}

function getTeamScoresButton(_this_){
    if(_this_.state.status===0){
        return <p onClick={() => _this_.setState({status: 1})} className="phoneTeamScoresButton">View Team Scores</p>
    }else if(_this_.state.status===1){
        return <p onClick={() => _this_.setState({status: 0})} className="phoneTeamScoresButton">View Individual Results</p>
    }
}

function getDivisionButtons(_division_, _this_){
    if(_division_<=1 || _this_.state.scoring===0){
        return;
    }
    //let divNames = _this_.state.divisions.split(',');
    if(_this_.state.divisions===undefined || !_this_.state.divisions.includes(',') || _this_.state.divisions==="NULL"){
        let divNames = [];
        for(let i=0; i<_division_; i++){
            divNames.push("Division " + (i+1));
        }
        return <div className="phoneButtonsXC">
              {getCompiledDivisionButton(_this_)}
              {divNames.map( (div, index) => getDivisionButton(_this_, index))}
        </div>;
    }else{
        let divNames = _this_.state.divisions.split(',');
        return <div className="phoneButtonsXC">
            {getCompiledDivisionButton(_this_)}
            {divNames.map( (div, index) => getDivisionButtonWithName(_this_, index))}
        </div>;
    }
}

function getCompiledDivisionButton(_this_){
    if(_this_.state.cDivision===0){
        return <button className="activePhoneButtonXC" onClick={() => _this_.setState({cDivision: 0})}>Compiled</button>
    }else {
        return <button className="phoneButtonXC" onClick={() => _this_.setState({cDivision: 0})}>Compiled</button>
    }
}

function getDivisionButton(_this_, _index_){
    if(_this_.state.cDivision===_index_+1){
        return <button className="activePhoneButtonXC" onClick={() => _this_.setState({cDivision: _index_+1})}>{"Division " + (_index_+1)}</button>;
    }else {
        return <button className="phoneButtonXC" onClick={() => _this_.setState({cDivision: _index_+1})}>{"Division " + (_index_+1)}</button>;
    }
}

function getDivisionButtonWithName(_this_, _index_){
    if(_this_.state.cDivision===_index_+1){
        return <button className="activePhoneButtonXC" onClick={() => _this_.setState({cDivision: _index_+1})}>{getDivisionName(_this_.state.divisions, _index_+1)}</button>
    }else {
        return <button className="phoneButtonXC" onClick={() => _this_.setState({cDivision: _index_+1})}>{getDivisionName(_this_.state.divisions, _index_+1)}</button>
    }
}

function getGenderButtons(_genderStatus_, _status_, _this_, _gender_){
    if(_genderStatus_!=="0"){
        return;
    }
    return <div className="phoneButtonsXC">
            {getGenderButton(0, _gender_, _this_)}
            {getGenderButton(1, _gender_, _this_)}
            {getGenderButton(2,_gender_, _this_)}
    </div>;
}

function getGenderButton(_code_, _gender_, _this_){
    if(_code_===0){
        if(_code_===_gender_){
            return <button className="activePhoneButtonXC" onClick={() => _this_.setState({gender: 0})}>Mixed</button>;
        }else{
            return <button className="phoneButtonXC" onClick={() => _this_.setState({gender: 0})}>Mixed</button>;
        }
    }else if(_code_===1){
        if(_code_===_gender_){
            return <button className="activePhoneButtonXC" onClick={() => _this_.setState({gender: 1})}>Women</button>;
        }else{
            return <button className="phoneButtonXC" onClick={() => _this_.setState({gender: 1})}>Women</button>;
        }
    }else if(_code_===2){
        if(_code_===_gender_){
            return <button className="activePhoneButtonXC" onClick={() => _this_.setState({gender: 2})}>Men</button>;
        }else{
            return <button className="phoneButtonXC" onClick={() => _this_.setState({gender: 2})}>Men</button>;
        }
    }
}

function getSplitButtons(_results_, _splitNames_, _this_){
    if(_results_.length<=0){
        return;
    }
    let maxSplits = getMaxSplits(_results_);
    if(maxSplits.length>0/* && _this_.state.status===0*/){
        return <div className="phoneButtonsXC">
            {maxSplits.map( (split, index) => getSplitButton(index, _splitNames_, _this_))}
            {getFinalSplitButton(_this_)}
        </div>;
    }/*else if(maxSplits.length>0 && _this_.state.status===1){
        return <div className="phoneButtonsXC">
            {maxSplits.map( (split, index) => <button className="phoneButtonXC" onClick={() => _this_.setState({split: index+1})}>{getSplitNameButton(_splitNames_[index], index)}</button>)}
            <button className="phoneButtonXC" onClick={() => _this_.setState({split: 0, status: 1})}>Final</button>
        </div>;
    }*/else{
        return;
    }
}

function getSplitButton(_index_, _splitNames_, _this_){
    if(_index_+1===_this_.state.split){
        return <button className="activePhoneButtonXC" onClick={() => _this_.setState({split: _index_+1})}>{getSplitNameButton(_splitNames_[_index_], _index_)}</button>;
    }else{
        return <button className="phoneButtonXC" onClick={() => _this_.setState({split: _index_+1})}>{getSplitNameButton(_splitNames_[_index_], _index_)}</button>;
    }
}

function getFinalSplitButton(_this_){
    if(_this_.state.split===0){
        return <button className="activePhoneButtonXC" onClick={() => _this_.setState({split: 0/*, status: 0*/})}>Final</button>
    }else{
        return <button className="phoneButtonXC" onClick={() => _this_.setState({split: 0/*, status: 0*/})}>Final</button>;
    }
}

function getIndividualScores(_results_, _roster_, _status_, _split_, _gender_, _splitNames_, _cDivision_, _meetName_, _this_){
    if(_results_.length<=0 || _status_!==0 || _gender_!==0 || _cDivision_>0){
        return;
    }else {
        return <div><table className="phoneTableXC">
            <thead>
                <th className="phonePlaceXC">Pl.</th>
                <th className="phonePlaceChangeXC"></th>
                <th className="phoneLogoColumnXC"></th>
                <th className="phoneAthleteXC">Athlete</th>
                <th className="phoneMarkXC">Time</th>
            </thead>
            {_results_[_split_].map( athlete => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + athlete[2] + "/" + _this_.props.eventNumber, "_blank")}>
                <td className="phonePlaceXC">{athlete[0]}</td>
                <td className="phonePlaceChangeXC">{getPlaceChange(_results_, athlete[1], athlete[0], _split_)}</td>
                <td className="phoneLogoColumnXC"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[2] + ".png"} className="phoneLogoXC" alt=""></img></td>
                <td className="phoneAthleteXC"><table>
                    <tr><td className="phoneXCRowName">{athlete[3] + getGrade(athlete[1], _roster_)}</td></tr>
                    <tr><td className="phoneXCRowTeam">{athlete[2]}</td></tr>
                </table></td>
                <td className="phoneMarkXC">{athlete[4]}</td></tr>
                {getSplits(athlete[5], _split_, athlete[4], _splitNames_)}
            </tbody>)}
        </table></div>;
    }
}

function getIndividualDivisionScores(_results_, _roster_, _status_, _split_, _gender_, _splitNames_, _cDivision_, _teams_, _eventGender_, _divisions_, _meetName_, _this_){
    if(_results_.length<=0 || _status_!==0 || _gender_!==0 || _cDivision_===0){
        return;
    }else {
        let divResults = getDivisionResults(_results_, _teams_, _cDivision_, _eventGender_);
        return <div><table className="phoneTableXC">
            <thead>
                <th className="phonePlaceXC">Pl.</th>
                <th className="phonePlaceChangeXC"></th>
                <th className="phoneLogoColumnXC"></th>
                <th className="phoneAthleteXC">Athlete</th>
                <th className="phoneMarkXC">Time</th>
            </thead>
            {divResults[_split_].map( (athlete,index) => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + athlete[2] + "/" + _this_.props.eventNumber , "_blank")}>
                <td className="phonePlaceXC">{getPlace(athlete[0], index+1)}</td>
                <td className="phonePlaceChangeXC">{getPlaceChange(_results_, athlete[1], getPlace(athlete[0], index+1), _split_)}</td>
                <td className="phoneLogoColumnXC"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[2] + ".png"} className="phoneLogoXC" alt=""></img></td>
                <td className="phoneAthleteXC"><table>
                    <tr><td className="phoneXCRowName">{athlete[3] + getGrade(athlete[1], _roster_)}</td></tr>
                    <tr><td className="phoneXCRowTeam">{athlete[2]}</td></tr>
                </table></td>
                <td className="phoneMarkXC">{athlete[4]}</td></tr>
                {getSplits(athlete[5], _split_, athlete[4], _splitNames_)}
            </tbody>)}
        </table></div>;
    }
}

function getIndividualFemaleScores(_results_, _roster_, _status_, _split_, _gender_, _splitNames_, _cDivision_, _meetName_, _this_){
    if(_results_.length<=0 || _status_!==0 || _gender_!==1 || _cDivision_>0){
        return;
    }else {
        return <div><table className="phoneTableXC">
            <thead>
                <th className="phonePlaceXC">Pl.</th>
                <th className="phonePlaceChangeXC"></th>
                <th className="phoneLogoColumnXC"></th>
                <th className="phoneAthleteXC">Athlete</th>
                <th className="phoneMarkXC">Time</th>
            </thead>
            {_results_[_split_].map( (athlete, index) => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + athlete[2] + "/" + _this_.props.eventNumber , "_blank")}>
                <td className="phonePlaceXC">{getPlace(athlete[0], index+1)}</td>
                <td className="phonePlaceChangeXC">{getPlaceChange(_results_, athlete[1], getPlace(athlete[0], index+1), _split_)}</td>
                <td className="phoneLogoColumnXC"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[2] + ".png"} className="phoneLogoXC" alt=""></img></td>
                <td className="phoneAthleteXC"><table>
                    <tr><td className="phoneXCRowName">{athlete[3] + getGrade(athlete[1], _roster_)}</td></tr>
                    <tr><td className="phoneXCRowTeam">{athlete[2]}</td></tr>
                </table></td>
                <td className="phoneMarkXC">{athlete[4]}</td></tr>
                {getSplits(athlete[5], _split_, athlete[4], _splitNames_)}
            </tbody>)}
        </table></div>;
    }
}

function getIndividualDivisionFemaleScores(_results_, _roster_, _status_, _split_, _gender_, _splitNames_, _cDivision_, _teams_, _eventGender_, _divisions_, _meetName_, _this_){
    if(_results_.length<=0 || _status_!==0 || _gender_!==1 || _cDivision_===0){
        return;
    }else {
        let divResults = getGenderDivisionResults(_results_, _teams_, _cDivision_, "F", _roster_);
        return <div><table className="phoneTableXC">
            <thead>
                <th className="phonePlaceXC">Pl.</th>
                <th className="phonePlaceChangeXC"></th>
                <th className="phoneLogoColumnXC"></th>
                <th className="phoneAthleteXC">Athlete</th>
                <th className="phoneMarkXC">Time</th>
            </thead>
            {divResults[_split_].map( (athlete, index) => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + athlete[2] + "/" + _this_.props.eventNumber , "_blank")}>
                <td className="phonePlaceXC">{getPlace(athlete[0], index+1)}</td>
                <td className="phonePlaceChangeXC">{getPlaceChange(_results_, athlete[1], getPlace(athlete[0], index+1), _split_)}</td>
                <td className="phoneLogoColumnXC"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[2] + ".png"} className="phoneLogoXC" alt=""></img></td>
                <td className="phoneAthleteXC"><table>
                    <tr><td className="phoneXCRowName">{athlete[3] + getGrade(athlete[1], _roster_)}</td></tr>
                    <tr><td className="phoneXCRowTeam">{athlete[2]}</td></tr>
                </table></td>
                <td className="phoneMarkXC">{athlete[4]}</td></tr>
                {getSplits(athlete[5], _split_, athlete[4], _splitNames_)}
            </tbody>)}
        </table></div>;
    }
}

function getIndividualMaleScores(_results_, _roster_, _status_, _split_, _gender_, _splitNames_, _cDivision_, _meetName_, _this_){
    if(_results_.length<=0 || _status_!==0 || _gender_!==2 || _cDivision_>0){
        return;
    }else {
        return <div><table className="phoneTableXC">
            <thead>
                <th className="phonePlaceXC">Pl.</th>
                <th className="phonePlaceChangeXC"></th>
                <th className="phoneLogoColumnXC"></th>
                <th className="phoneAthleteXC">Athlete</th>
                <th className="phoneMarkXC">Time</th>
            </thead>
            {_results_[_split_].map( (athlete, index) => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + athlete[2] + "/" + _this_.props.eventNumber , "_blank")}>
                <td className="phonePlaceXC">{getPlace(athlete[0], index+1)}</td>
                <td className="phonePlaceChangeXC">{getPlaceChange(_results_, athlete[1], getPlace(athlete[0], index+1), _split_)}</td>
                <td className="phoneLogoColumnXC"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[2] + ".png"} className="phoneLogoXC" alt=""></img></td>
                <td className="phoneAthleteXC"><table>
                    <tr><td className="phoneXCRowName">{athlete[3] + getGrade(athlete[1], _roster_)}</td></tr>
                    <tr><td className="phoneXCRowTeam">{athlete[2]}</td></tr>
                </table></td>
                <td className="phoneMarkXC">{athlete[4]}</td></tr>
                {getSplits(athlete[5], _split_, athlete[4], _splitNames_)}
            </tbody>)}
        </table></div>;
    }
}

function getIndividualDivisionMaleScores(_results_, _roster_, _status_, _split_, _gender_, _splitNames_, _cDivision_, _teams_, _eventGender_, _divisions_, _meetName_, _this_){
    if(_results_.length<=0 || _status_!==0 || _gender_!==2 || _cDivision_===0){
        return;
    }else {
        let divResults = getGenderDivisionResults(_results_, _teams_, _cDivision_, "M", _roster_);
        return <div><table className="phoneTableXC">
            <thead>
                <th className="phonePlaceXC">Pl.</th>
                <th className="phonePlaceChangeXC"></th>
                <th className="phoneLogoColumnXC"></th>
                <th className="phoneAthleteXC">Athlete</th>
                <th className="phoneMarkXC">Time</th>
            </thead>
            {divResults[_split_].map( (athlete, index) => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + athlete[2] + "/" + _this_.props.eventNumber , "_blank")}>
                <td className="phonePlaceXC">{getPlace(athlete[0], index+1)}</td>
                <td className="phonePlaceChangeXC">{getPlaceChange(_results_, athlete[1], getPlace(athlete[0], index+1), _split_)}</td>
                <td className="phoneLogoColumnXC"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[2] + ".png"} className="phoneLogoXC" alt=""></img></td>
                <td className="phoneAthleteXC"><table>
                    <tr><td className="phoneXCRowName">{athlete[3] + getGrade(athlete[1], _roster_)}</td></tr>
                    <tr><td className="phoneXCRowTeam">{athlete[2]}</td></tr>
                </table></td>
                <td className="phoneMarkXC">{athlete[4]}</td></tr>
                {getSplits(athlete[5], _split_, athlete[4], _splitNames_)}
            </tbody>)}
        </table></div>;
    }
}

function getTeamScores(_results_, _status_, _split_, _gender_, _minRunners_, _maxRunners_, _teams_, _scoring_, _cDivision_, _meetName_, _this_){
    if(_results_.length<=0 || _status_!==1 || _gender_==="0" || _cDivision_>0){
        return;
    }else {
        return <div><table className="phoneTableXC">
            <thead>
                <th className="phoneTeamPlaceXC">Pl.</th>
                <th className="phoneTeamPlaceChangeXC"></th>
                <th className="phoneTeamXC">Team</th>
                <th className="phoneTeamAthletesXC"></th>
                <th className="scoreXCPhone">Score</th>
            </thead>
            {setTeamScores(_results_[_split_], _minRunners_, _maxRunners_, _teams_, _gender_, _split_, _scoring_).map( (team, index) => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + team[0] + "/" + _this_.props.eventNumber , "_blank")}>
                <td className="phoneTeamPlaceXC">{getTeamPlace(index+1, team[1])}</td>
                <td className="phoneTeamPlaceChangeXC">{getTeamPlaceChange(_results_, team[0], getTeamPlace(index+1, team[1]), _split_, _minRunners_, _maxRunners_, _teams_, _gender_, _scoring_)}</td>
                <td className="phoneTeamXC"><table className="phoneTeamXCTable"><tr><td><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + team[0] + ".png"} className="phoneLogoXC" alt=""></img></td></tr><tr><td>{team[0]}</td></tr></table></td>
                <td className="phoneTeamAthletesXC"><div className="phoneTeamAthletesXCDiv"><table className="phoneTeamAthletesXCTable"><tr>
                    <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][0], 1, _results_[_split_], team[0])}</td>
                    <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][1], 2, _results_[_split_], team[0])}</td>
                    <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][2], 3, _results_[_split_], team[0])}</td>
                    <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][3], 4, _results_[_split_], team[0])}</td>
                    <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][4], 5, _results_[_split_], team[0])}</td>
                    <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][5], 6, _results_[_split_], team[0])}</td>
                    <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][6], 7, _results_[_split_], team[0])}</td>
                </tr></table></div></td>
                <td className="scoreXCPhone">{getTeamPoints(team[1])}</td>
            </tr></tbody>)}
        </table></div>;
    }
}

function getDivisionTeamScores(_results_, _status_, _split_, _eventGender_, _minRunners_, _maxRunners_, _teams_, _scoring_, _cDivision_, _gender_, _divisions_, _meetName_, _this_){
    if(_results_.length<=0 || _status_!==1 || (_eventGender_==="0" && _gender_>0) || _cDivision_===0){
        return;
    }else {
        let divResults = getDivisionResults(_results_, _teams_, _cDivision_, _eventGender_)
        return <div><table className="phoneTableXC">
        <thead>
            <th className="phoneTeamPlaceXC">Pl.</th>
            <th className="phoneTeamPlaceChangeXC"></th>
            <th className="phoneTeamXC">Team</th>
            <th className="phoneTeamAthletesXC"></th>
            <th className="scoreXCPhone">Score</th>
        </thead>
        {setTeamScores(divResults[_split_], _minRunners_, _maxRunners_, _teams_, _gender_, _split_, _scoring_).map( (team, index) => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + team[0] + "/" + _this_.props.eventNumber , "_blank")}>
            <td className="phoneTeamPlaceXC">{getTeamPlace(index+1, team[1])}</td>
            <td className="phoneTeamPlaceChangeXC">{getTeamPlaceChange(_results_, team[0], getTeamPlace(index+1, team[1]), _split_, _minRunners_, _maxRunners_, _teams_, _gender_, _scoring_)}</td>
            <td className="phoneTeamXC"><table className="phoneTeamXCTable"><tr><td><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + team[0] + ".png"} className="phoneLogoXC" alt=""></img></td></tr><tr><td>{team[0]}</td></tr></table></td>
            <td className="phoneTeamAthletesXC"><div className="phoneTeamAthletesXCDiv"><table className="phoneTeamAthletesXCTable"><tbody><tr>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][0], 1, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][1], 2, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][2], 3, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][3], 4, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][4], 5, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][5], 6, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][6], 7, divResults[_split_], team[0])}</td>
            </tr></tbody></table></div></td>
            <td className="scoreXCPhone">{getTeamPoints(team[1])}</td>
        </tr></tbody>)}
        </table></div>;
    }
}

function getFemaleScores(_results_, _status_, _split_, _gender_, _minRunners_, _maxRunners_, _teams_, _roster_, _scoring_, _cDivision_, _meetName_, _this_){
    if(_results_.length<=0 || _status_!==1 || _gender_!==1 || _cDivision_>0){
        return;
    }else {
        return <div><table className="phoneTableXC">
        <thead>
            <th className="phoneTeamPlaceXC">Pl.</th>
            <th className="phoneTeamPlaceChangeXC"></th>
            <th className="phoneTeamXC">Team</th>
            <th className="phoneTeamAthletesXC"></th>
            <th className="scoreXCPhone">Score</th>
        </thead>
        {setTeamScores(_results_[_split_], _minRunners_, _maxRunners_, _teams_, "F", _split_, _scoring_).map( (team, index) => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + team[0] + "/" + _this_.props.eventNumber , "_blank")}>
            <td className="phoneTeamPlaceXC">{getTeamPlace(index+1, team[1])}</td>
            <td className="phoneTeamPlaceChangeXC">{getTeamPlaceChange(_results_, team[0], getTeamPlace(index+1, team[1]), _split_, _minRunners_, _maxRunners_, _teams_, _gender_, _scoring_)}</td>
            <td className="phoneTeamXC"><table className="phoneTeamXCTable"><tr><td><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + team[0] + ".png"} className="phoneLogoXC" alt=""></img></td></tr><tr><td>{team[0]}</td></tr></table></td>
            <td className="phoneTeamAthletesXC"><div className="phoneTeamAthletesXCDiv"><table className="phoneTeamAthletesXCTable"><tr>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][0], 1, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][1], 2, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][2], 3, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][3], 4, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][4], 5, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][5], 6, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][6], 7, _results_[_split_], team[0])}</td>
            </tr></table></div></td>
            <td className="scoreXCPhone">{getTeamPoints(team[1])}</td>
        </tr></tbody>)}
        </table></div>;
    }
}

function getFemaleDivisionTeamScores(_results_, _roster_, _status_, _split_, _gender_, _splitNames_, _cDivision_, _teams_, _eventGender_, _divisions_, _minRunners_, _maxRunners_, _scoring_, _meetName_, _this_){
    if(_results_.length<=0 || _status_!==1 || _eventGender_!=="0" || _cDivision_===0 || _gender_!==1){
        return;
    }else {
        let divResults = getGenderDivisionResults(_results_, _teams_, _cDivision_, "F", _roster_);
        return <div><table className="phoneTableXC">
        <thead>
            <th className="phoneTeamPlaceXC">Pl.</th>
            <th className="phoneTeamPlaceChangeXC"></th>
            <th className="phoneTeamXC">Team</th>
            <th className="phoneTeamAthletesXC"></th>
            <th className="scoreXCPhone">Score</th>
        </thead>
        {setTeamScores(divResults[_split_], _minRunners_, _maxRunners_, _teams_, "F", _split_, _scoring_).map( (team, index) => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + team[0] + "/" + _this_.props.eventNumber , "_blank")}>
            <td className="phoneTeamPlaceXC">{getTeamPlace(index+1, team[1])}</td>
            <td className="phoneTeamPlaceChangeXC">{getTeamPlaceChange(_results_, team[0], getTeamPlace(index+1, team[1]), _split_, _minRunners_, _maxRunners_, _teams_, _gender_, _scoring_)}</td>
            <td className="phoneTeamXC"><table className="phoneTeamXCTable"><tr><td><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + team[0] + ".png"} className="phoneLogoXC" alt=""></img></td></tr><tr><td>{team[0]}</td></tr></table></td>
            <td className="phoneTeamAthletesXC"><div className="phoneTeamAthletesXCDiv"><table className="phoneTeamAthletesXCTable"><tr>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][0], 1, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][1], 2, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][2], 3, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][3], 4, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][4], 5, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][5], 6, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][6], 7, divResults[_split_], team[0])}</td>
            </tr></table></div></td>
            <td className="scoreXCPhone">{getTeamPoints(team[1])}</td>
        </tr></tbody>)}
        </table></div>;
    }
}

function getMaleScores(_results_, _status_, _split_, _gender_, _minRunners_, _maxRunners_, _teams_, _roster_, _scoring_, _cDivision_, _meetName_, _this_){
    if(_results_.length<=0 || _status_!==1 || _gender_!==2 || _cDivision_>0){
        return;
    }else {
        return <div><table className="phoneTableXC">
        <thead>
            <th className="phoneTeamPlaceXC">Pl.</th>
            <th className="phoneTeamPlaceChangeXC"></th>
            <th className="phoneTeamXC">Team</th>
            <th className="phoneTeamAthletesXC"></th>
            <th className="scoreXCPhone">Score</th>
        </thead>
        {setTeamScores(_results_[_split_], _minRunners_, _maxRunners_, _teams_, "M", _split_, _scoring_).map( (team, index) => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + team[0] + "/" + _this_.props.eventNumber , "_blank")}>
            <td className="phoneTeamPlaceXC">{getTeamPlace(index+1, team[1])}</td>
            <td className="phoneTeamPlaceChangeXC">{getTeamPlaceChange(_results_, team[0], getTeamPlace(index+1, team[1]), _split_, _minRunners_, _maxRunners_, _teams_, _gender_, _scoring_)}</td>
            <td className="phoneTeamXC"><table className="phoneTeamXCTable"><tr><td><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + team[0] + ".png"} className="phoneLogoXC" alt=""></img></td></tr><tr><td>{team[0]}</td></tr></table></td>
            <td className="phoneTeamAthletesXC"><div className="phoneTeamAthletesXCDiv"><table className="phoneTeamAthletesXCTable"><tr>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][0], 1, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][1], 2, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][2], 3, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][3], 4, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][4], 5, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][5], 6, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][6], 7, _results_[_split_], team[0])}</td>
            </tr></table></div></td>
            <td className="scoreXCPhone">{getTeamPoints(team[1])}</td>
        </tr></tbody>)}
        </table></div>;
    }
}

function getMaleDivisionTeamScores(_results_, _roster_, _status_, _split_, _gender_, _splitNames_, _cDivision_, _teams_, _eventGender_, _divisions_, _minRunners_, _maxRunners_, _scoring_, _meetName_, _this_){
    if(_results_.length<=0 || _status_!==1 || _eventGender_!=="0" || _cDivision_===0 || _gender_!==2){
        return;
    }else {
        let divResults = getGenderDivisionResults(_results_, _teams_, _cDivision_, "M", _roster_);
        return <div><table className="phoneTableXC">
        <thead>
            <th className="phoneTeamPlaceXC">Pl.</th>
            <th className="phoneTeamPlaceChangeXC"></th>
            <th className="phoneTeamXC">Team</th>
            <th className="phoneTeamAthletesXC"></th>
            <th className="scoreXCPhone">Score</th>
        </thead>
        {setTeamScores(divResults[_split_], _minRunners_, _maxRunners_, _teams_, "M", _split_, _scoring_).map( (team, index) => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + team[0] + "/" + _this_.props.eventNumber , "_blank")}>
            <td className="phoneTeamPlaceXC">{getTeamPlace(index+1, team[1])}</td>
            <td className="phoneTeamPlaceChangeXC">{getTeamPlaceChange(_results_, team[0], getTeamPlace(index+1, team[1]), _split_, _minRunners_, _maxRunners_, _teams_, _gender_, _scoring_)}</td>
            <td className="phoneTeamXC"><table className="phoneTeamXCTable"><tr><td><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + team[0] + ".png"} className="phoneLogoXC" alt=""></img></td></tr><tr><td>{team[0]}</td></tr></table></td>
            <td className="phoneTeamAthletesXC"><div className="phoneTeamAthletesXCDiv"><table className="phoneTeamAthletesXCTable"><tr>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][0], 1, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][1], 2, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][2], 3, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][3], 4, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][4], 5, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][5], 6, divResults[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][6], 7, divResults[_split_], team[0])}</td>
            </tr></table></div></td>
            <td className="scoreXCPhone">{getTeamPoints(team[1])}</td>
        </tr></tbody>)}
        </table></div>;
    }
}

function getMixedTeamScores(_results_, _status_, _split_, _gender_, _minRunners_, _maxRunners_, _teams_, _scoring_, _cDivision_, _meetName_, _eventGender_, _this_){
    if(_results_.length<=0 || _status_!==1 || _gender_!==0 || _cDivision_>0 || _eventGender_!=="0"){
        return;
    }else {
        return <div><table className="phoneTableXC">
        <thead>
            <th className="phoneTeamPlaceXC">Pl.</th>
            <th className="phoneTeamPlaceChangeXC"></th>
            <th className="phoneTeamXC">Team</th>
            <th className="phoneTeamAthletesXC"></th>
            <th className="scoreXCPhone">Score</th>
        </thead>
        {setTeamScores(_results_[_split_], _minRunners_, _maxRunners_, _teams_, _gender_, _split_, _scoring_).map( (team, index) => <tbody><tr onClick={() => window.open("https://liveresults.trxctiming.com/TeamResults/" + _meetName_ + "/" + team[0] + "/" + _this_.props.eventNumber , "_blank")}>
            <td className="phoneTeamPlaceXC">{getTeamPlace(index+1, team[1])}</td>
            <td className="phoneTeamPlaceChangeXC">{getTeamPlaceChange(_results_, team[0], getTeamPlace(index+1, team[1]), _split_, _minRunners_, _maxRunners_, _teams_, _gender_, _scoring_)}</td>
            <td className="phoneTeamXC"><table className="phoneTeamXCTable"><tr><td><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + team[0] + ".png"} className="phoneLogoXC" alt=""></img></td></tr><tr><td>{team[0]}</td></tr></table></td>
            <td className="phoneTeamAthletesXC"><div className="phoneTeamAthletesXCDiv"><table className="phoneTeamAthletesXCTable"><tr>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][0], 1, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][1], 2, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][2], 3, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][3], 4, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][4], 5, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][5], 6, _results_[_split_], team[0])}</td>
                <td className="phoneTeamAthleteXC">{getTeamAthlete(team[3][6], 7, _results_[_split_], team[0])}</td>
            </tr></table></div></td>
            <td className="scoreXCPhone">{getTeamPoints(team[1])}</td>
        </tr></tbody>)}
        </table></div>;
    }
}

function getTeamAthlete(_score_, _teamPlace_, _results_, _team_){
    if(parseInt(_score_)<=0 || parseInt(_score_)===NaN){
        return '-';
    }
    let teamCount = 0;
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][2]===_team_){
            teamCount++;
            if(teamCount===_teamPlace_){
                if(_results_[i][4].length<=0){
                    return <table className="teamTableXCIndividual"><tr><td className="teamTableXCIndividualPlace">{"-"}</td></tr></table>;
                }
                else if(_results_[i][3].indexOf(',')>0){
                    return <table className="teamTableXCIndividual"><tr><td className="teamTableXCIndividualPlace">{_score_}</td></tr><tr><td>{_results_[i][3].substring(0, _results_[i][3].indexOf(',')+1)}</td></tr><tr><td>{_results_[i][3].substring(_results_[i][3].indexOf(',')+2)}</td></tr><tr><td>{_results_[i][4]}</td></tr></table>;
                }else{
                    return <table className="teamTableXCIndividual"><tr><td className="teamTableXCIndividualPlace">{_score_}</td></tr><tr><td>{_results_[i][3]}</td></tr><tr><td>{_results_[i][4]}</td></tr></table>;
                }
            }
        }
    }
    return '-';
}

function filterData(_results_) {

    let filteredData = [];
    _results_.map( athlete => filteredData.push([getTrackPlace(athlete[8]), athlete[9], athlete[12], athlete[11], getTime(athlete[8], athlete[13]), athlete[15]]) );
    return filteredData;
}

function getTrackPlace(_place_) {

    if(_place_===999999 || _place_==="DNS") {
        return "DNS";
    }else if(_place_===999998 || _place_==="DNF") {
        return "DNF";
    }else if(_place_===999997 || _place_==="DQ") {
        return "DQ";
    }else if(_place_===999996 || _place_==="FS") {
        return "FS";
    }else if(_place_===999995 || _place_==="SCR") {
        return "SCR";
    }else if(_place_===999994 || _place_==="ADV") {
        return "ADV";
    }else if(_place_===999993){
        return "-";
    }else {
        return _place_;
    }
}

function getTime(_place_, _time_) {

    if(_place_>=999993){
        return "";
    }else if(_time_ === "99:99:99.999") {
        return "";
    }else if(_time_ === "99:99:99.998") {
        return "";
    }else if(_time_ === "99:99:99.997") {
        return "";
    }else if(_time_ === "99:99:99.996") {
        return "";
    }else if(_time_.substring(0, 8) === "99:99:99") {
        return "";
    }else {
        return roundTime(_time_);
    }
}

function roundTime(_time_){

    if(_time_.substring(_time_.indexOf('.')+1).length<=2){
        return _time_;
    }
    if(_time_ === "99:99:99.999") {
        return "";
    }else if(_time_ === "99:99:99.998") {
        return "";
    }else if(_time_ === "99:99:99.997") {
        return "";
    }else if(_time_ === "99:99:99.996") {
        return "";
    }else {
        let adjustedTime = _time_.substring(0, _time_.indexOf('.')+1);
        let decimals = _time_.substring(_time_.indexOf('.')+1);
        if(decimals>990){
            if(adjustedTime.includes(':')){
                let mins = adjustedTime.substring(0, adjustedTime.indexOf(':')+1);
                let seconds = adjustedTime.substring(adjustedTime.indexOf(':')+1);
                if(!seconds.includes(':')){
                    decimals = "000";
                    if(parseInt(seconds)+1===60){
                        adjustedTime = (mins + '00.');
                    }
                    else if(parseInt(seconds)<10){
                        adjustedTime = (mins + '0' + (parseInt(seconds)+1) + '.');
                    }else {
                        adjustedTime = (mins + (parseInt(seconds)+1) + '.');
                    }
                }

            }else{
                decimals = "000";
                adjustedTime = (parseInt(adjustedTime)+1) + '.';
            }
        }
        if(decimals[2]==='0'){
            decimals = decimals[0] + '.' + decimals[1] + decimals[2];
        }else if(decimals[2]!=='0'){
            decimals = decimals[0] + '.' + decimals[1] + '9';
        }
        decimals = parseFloat(decimals).toFixed(1);
        decimals = decimals[0] + decimals[2];
        return adjustedTime + decimals;
    }
}

function getSplits(_splits_, _split_, _finalTime_, _splitNames_){
    if(_splits_==="NULL" || _splits_===null || _splits_===undefined || _split_>0){
        return;
    }
    let splits = _splits_.split(',');
    if(splits.length<1 || !_splits_.includes('.') || (_finalTime_.length>0 && splits.length<=1) ){
        return;
    }else{
        for(let i=0; i<splits.length; i++){
            let sumTime = splits[i].substring(0, splits[i].indexOf(' '));
            let splitTime = splits[i].substring(splits[i].indexOf(' ')+2, splits[i].length-1);
            splits[i] = roundTime(sumTime) + ' (' + roundTime(splitTime) + ')';
        }
    }
    return <tr><td colSpan="5"><div className="splitsDivPhoneXC"><table align="center"  className="splitsTablePhoneXC"><tbody><tr>{splits.map( (split, index) => <td className="splitBoxPhoneXC">{getSplitName(_splitNames_[index]) + split}</td>)}</tr></tbody></table></div></td></tr>;
}

function setTeamScores(_results_, _minRunners_, _maxRunners_, _validTeams_, _gender_, _split_, _scoring_){
    if(_gender_==="0"){
        return [];
    }
    let teamScores = [];
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][0]!=="DNF" && _results_[i][0]!=="DNS" && _results_[i][0]!=="DQ" && _results_[i][0]!=="SCR" && ((_results_[i][4]!=="" && _split_===0) || (_results_[i][4]!=="" && _split_!==0)) ){
            if(checkValidTeam(_results_[i][2], _validTeams_, _gender_)){
                teamScores = addRunner(_results_[i][2], teamScores);
            }
        }
    }
    teamScores = teamScores.filter(team => team[2]>=_minRunners_);
    // Setting runners scoring on each team to 0 to start counting points
    for(let i=0; i<teamScores.length; i++){
        teamScores[i][2] = 0;
    }
    let pointsCount = 1;
    //loop through results to score teams
    for(let i=0; i<_results_.length; i++){
        if(checkTeam(_results_[i][2], teamScores) && _results_[i][0]!=="DNS" && _results_[i][0]!=="DNF" && _results_[i][0]!=="DQ" && _results_[i][0]!=="SCR"){
            if(checkScoringRunner(_results_[i][2], teamScores, _minRunners_)){
                teamScores = addPoints(pointsCount, _results_[i][2], teamScores, _minRunners_, _maxRunners_);
                pointsCount++;
            }else if(checkDisplacementRunner(_results_[i][2], teamScores, _maxRunners_)){
                teamScores = addPoints(pointsCount, _results_[i][2], teamScores, _minRunners_, _maxRunners_);
                pointsCount++;
            }
        }
    }
    //sort teamScores
    for(let i=0; i<teamScores.length-1; i++){
        for(let j=i+1; j<teamScores.length; j++){
            if(teamScores[i][1]>teamScores[j][1]){
                let tempScore = teamScores[i];
                teamScores[i] = teamScores[j];
                teamScores[j] = tempScore;
            }else if(teamScores[i][1]===teamScores[j][1] && teamScores[i][1]>0){
                if(checkTeamTie(teamScores[i], teamScores[j], _scoring_, _minRunners_)){
                    let tempScore = teamScores[i];
                    teamScores[i] = teamScores[j];
                    teamScores[j] = tempScore;
                }
            }
        }
    }
    return addZeroScoreTeams(teamScores, _validTeams_, _gender_, _results_);
}

function addRunner(_team_, _teamScores_){
    let updatedTeamScores = _teamScores_;
    for(let i=0; i<updatedTeamScores.length; i++){
        if(updatedTeamScores[i][0]===_team_){
            updatedTeamScores[i][2]++;
            return updatedTeamScores;
        }
    }
    updatedTeamScores.push([_team_, 0, 1, ["-", "-", "-", "-", "-", "-", "-"]]);
    return updatedTeamScores;
}

function checkTeam(_team_, _teamScores_){
    for(let i=0; i<_teamScores_.length; i++){
        if(_teamScores_[i][0]===_team_){
            return true;
        }
    }
    return false;
}

function checkValidTeam(_team_, _validTeams_, _gender_){
    for(let i=0; i<_validTeams_.length; i++){
        if(_validTeams_[i][0]===_team_ && (_validTeams_[i][1]===_gender_ || _gender_===0)){
            return true;
        }
    }
    return false;
}

function checkScoringRunner(_team_, _teamScores_, _minRunners_){
    for(let i=0; i<_teamScores_.length; i++){
        if(_teamScores_[i][0]===_team_){
            if(_teamScores_[i][2]<_minRunners_){
                return true;
            }else{
                return false;
            }
        }
    }
    return false;
}

function checkDisplacementRunner(_team_, _teamScores_, _maxRunners_){
    for(let i=0; i<_teamScores_.length; i++){
        if(_teamScores_[i][0]===_team_){
            if(_teamScores_[i][2]<_maxRunners_){
                return true;
            }else{
                return false;
            }
        }
    }
    return false;
}

function addPoints(_points_, _team_, _teamScores_, _minRunners_, _maxRunners_){
    let updatedTeamScores = _teamScores_;
    for(let i=0; i<updatedTeamScores.length; i++){
        if(updatedTeamScores[i][0]===_team_){
            if(updatedTeamScores[i][2]<_minRunners_){
                updatedTeamScores[i][1]+=_points_;
            }
            if(_points_>0 && updatedTeamScores[i][2]<_maxRunners_){
                updatedTeamScores[i][3][updatedTeamScores[i][2]] = _points_;
            }
            updatedTeamScores[i][2]++;
            return updatedTeamScores;
        }
    }
    return updatedTeamScores;
}

function checkTeamTie(_teami_, _teamj_, _scoring_, _minRunners_){
    if(_scoring_===1){
        if(_teami_[3][_minRunners_]!=="-" && _teamj_[3][_minRunners_]!=="-"){
            if(_teamj_[3][_minRunners_]<_teami_[3][_minRunners_]){
                return true;
            }else{
                return false;
            }
        }else if(_teami_[3][_minRunners_]==="-" && _teamj_[3][_minRunners_]==="-"){
            if(_teamj_[3][_minRunners_-1]<_teami_[3][_minRunners_-1]){
                return true;
            }else{
                return false;
            }
        }else if(_teamj_[3][_minRunners_]!=="-"){
            return true;
        }
    }else if(_scoring_===2){
        let jWins = 0;
        for(let i=0; i<5; i++){
            if(_teamj_[3][i]<_teami_[3][i]){
                jWins++;
            }
        }
        if(jWins>=3){
            return true;
        }
    }
    return false;
}

function addZeroScoreTeams(_teamScores_, _validTeams_, _gender_, _results_){
    let updatedTeamScores = _teamScores_;
    for(let i=0; i<_results_.length; i++){
        if(checkValidTeam(_results_[i][2], _validTeams_, _gender_)){
            let add = true;
            for(let j=0; j<updatedTeamScores.length; j++){
                if(_results_[i][2]===updatedTeamScores[j][0]){
                    add = false;
                }
            }
            if(add){
                updatedTeamScores.push([_results_[i][2], 0, 1, ["", "", "", "", "", "", ""]]);
            }
        }
    }
    return updatedTeamScores;
}

function filterName(_name_, _split_){
    if(_name_===undefined){
        return;
    }
    let updatedName = _name_;
    if(updatedName.includes("(M)")){
        updatedName = updatedName.replace("(M)", "Men's");
    }else if(updatedName.includes("(W)")){
        updatedName = updatedName.replace("(W)", "Women's");
    }else if(updatedName.includes("(B)")){
        updatedName = updatedName.replace("(B)", "Boys'");
    }else if(updatedName.includes("(G)")){
        updatedName = updatedName.replace("(G)", "Girls'");
    }
    if(updatedName.includes("CC")){
        updatedName = updatedName.replace("CC", "Cross Country");
    }
    if(_split_===0){
        return updatedName + " - Results";
    }else if(_split_>0){
        return updatedName + " - Split " + _split_ + " Results";
    }
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return " (" + grade + ")";
        }
    }
    return "";
}

function getGender(_eventName_){
    if(_eventName_.includes("Girls") || _eventName_.includes("Women's") || _eventName_.includes("(W)") || _eventName_.includes("(G)") || _eventName_.includes("Women")){
        return "F";
    }else if(_eventName_.includes("Boys") || _eventName_.includes("Men's") || _eventName_.includes("(M)") || _eventName_.includes("(B)") || _eventName_.includes("Men")){
        return "M";
    }else if(_eventName_.includes("Mixed") || _eventName_.includes("mixed")){
        return "0";
    }
    return;
}

function convertTime(_time_){
    let time = _time_;
    let h="00", m="00", s="00", d="000";
    if(time.length===0){
        return "99:99:59.99";
    }else{
        if(time.includes(':', time.indexOf(':')+1)){
            h = time.substring(0, time.indexOf(':'));
            while(h.length<2){
                h = '0' + h;
            }
            time = time.substring(time.indexOf(':')+1);
        }
        if(time.includes(':')){
            m = time.substring(0, time.indexOf(':'));
            while(m.length<2){
                m = '0' + m;
            }
            time = time.substring(time.indexOf(':')+1);
        }
        if(time.includes('.')){
            s = time.substring(0, time.indexOf('.'));
            while(s.length<2){
                s = '0' + s;
            }
            d = time.substring(time.indexOf('.')+1);
            while(d.length<3){
                d = d + '0';
            }
        }
    }
    return h + ':' + m + ':' + s + '.' + d;
}

function getTeamPlace(_place_, _points_){
    if(_place_===0 || _points_===0){
        return "-";
    }else{
        return _place_;
    }
}

function getMaxSplits(_results_){
    let maxSplits = [];
    for(let i=0; i<_results_.length; i++){
        if((_results_[i][5].split(',').length > maxSplits.length) && (_results_[i][0]!=="DNF" && _results_[i][0]!=="DQ")){
            let tempMaxSplits = _results_[i][5].split(',');
            if(_results_[i][4].length>0){
                tempMaxSplits.pop();
            }
            if(tempMaxSplits.length > maxSplits.length){
                maxSplits = tempMaxSplits;
            }
        }
    }
    if(maxSplits[0]==="NULL"){
        return [];
    }
    return maxSplits;
}

function checkGender(_athlete_, _roster_, _gender_){
    for(let i=0; i<_roster_.length; i++){
        if(_roster_[i][0]===_athlete_[1]){
            if(_roster_[i][4]===_gender_){
                return true;
            }else{
                return false;
            }
        }
    }
    return false;
}

function getResults(_results_, _roster_, _gender_){
    let maxSplits = getMaxSplits(_results_);
    maxSplits = maxSplits.length;
    let results = [maxSplits+1];
    results[0] = [];
    //Get final times
    for(let i=0; i<_results_.length; i++){
        if(_gender_==="0"){
            results[0].push(_results_[i]);
        }else if(checkGender(_results_[i], _roster_, _gender_)){
            results[0].push(_results_[i]);
        }
    }
    //Get splits for each split
    for(let i=1; i<=maxSplits; i++){
        results[i] = [];
        //Get split times
        for(let j=0; j<_results_.length; j++){
            let splits = _results_[j][5].split(',');
            let split = "";
            if(splits[i-1]){
                split = splits[i-1];
                split = split.substring(0, split.indexOf(' '));
            }
            if(_gender_==="0"){
                results[i].push([_results_[j][0], _results_[j][1], _results_[j][2], _results_[j][3], split, _results_[j][5]]);
            }else if(checkGender(_results_[j], _roster_, _gender_)){
                results[i].push([_results_[j][0], _results_[j][1], _results_[j][2], _results_[j][3], split, _results_[j][5]]);
            }
        }
        //Sort splits
        for(let j=0; j<results[i].length-1; j++){
            for(let k=j+1; k<results[i].length; k++){
                if(convertTime(results[i][j][4]) > convertTime(results[i][k][4])){
                    let tempSplit = results[i][j];
                    results[i][j] = results[i][k];
                    results[i][k] = tempSplit;
                }
            }
            if(results[i][j][4].length>0){
                results[i][j][0] = j+1;
            }else{
                results[i][j][0] = "";
            }
        }
        if(results[i][results[i].length-1]!==undefined){
            if(results[i][results[i].length-1][4].length>0){
                results[i][results[i].length-1][0] = results[i].length;
            }else{
                results[i][results[i].length-1][0] = "";
            }
        }
    }
    return results;
}

function getPlaceChange(_results_, _bib_, _place_, _split_){
    let place = parseInt(_place_);
    if(_split_===1 || isNaN(place) || place===0 || place==="" || place===undefined || place===null || place==="NULL" || place==="null"){
        return "";
    }
    if(_split_>1){
        let prevPlace = 0;
        for(let i=0; i<_results_[_split_-1].length; i++){
            if(_results_[_split_-1][i][1]===_bib_){
                if(_results_[_split_-1][i][0]!==""){
                    prevPlace = _results_[_split_-1][i][0];
                    if(parseInt(place) - parseInt(prevPlace) < 0){
                        return <div>{Math.abs(parseInt(place) - parseInt(prevPlace))}<img src={"https://trxctiming.com/liveresults.trxctiming.com/images/green_arrow.png"} className="phoneArrowXC" alt=""></img></div>;
                    }else if(parseInt(place) - parseInt(prevPlace) > 0){
                        return <div>{Math.abs(parseInt(place) - parseInt(prevPlace))}<img src={"https://trxctiming.com/liveresults.trxctiming.com/images/red_arrow.png"} className="phoneArrowXC" alt=""></img></div>;
                    }else{
                        return <div><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/black_arrow.png"} className="phoneDoubleArrowXC" alt=""></img></div>;
                    }
                }else{
                    return "";
                }
            }
        }
    }else if(_split_===0 && _results_.length>1){
        let prevPlace = 0;
        for(let i=0; i<_results_[_results_.length-1].length; i++){
            if(_results_[_results_.length-1][i][1]===_bib_){
                if(_results_[_results_.length-1][i][0]!==""){
                    prevPlace = _results_[_results_.length-1][i][0];
                    if(parseInt(place) - parseInt(prevPlace) < 0){
                        return <div>{Math.abs(parseInt(place) - parseInt(prevPlace))}<img src={"https://trxctiming.com/liveresults.trxctiming.com/images/green_arrow.png"} className="phoneArrowXC" alt=""></img></div>;
                    }else if(parseInt(place) - parseInt(prevPlace) > 0){
                        return <div>{Math.abs(parseInt(place) - parseInt(prevPlace))}<img src={"https://trxctiming.com/liveresults.trxctiming.com/images/red_arrow.png"} className="phoneArrowXC" alt=""></img></div>;
                    }else{
                        return <div><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/black_arrow.png"} className="phoneDoubleArrowXC" alt=""></img></div>;
                    }
                }else{
                    return "";
                }
            }
        }
    }
    return "";
}

function getTeamPlaceChange(_results_, _teamName_, _place_, _split_, _minRunners_, _maxRunners_, _teams_, _gender_, _scoring_){
    let place = parseInt(_place_);
    if(_split_===1 || isNaN(place) || place===0 || place==="" || place===undefined || place===null || place==="NULL" || place==="null"){
        return "";
    }
    if(_split_>1){
        let prevPlace = 0;
        let prevTeamScores = setTeamScores(_results_[_split_-1], _minRunners_, _maxRunners_, _teams_, _gender_, _split_-1, _scoring_);
        for(let i=0; i<prevTeamScores.length; i++){
            if(prevTeamScores[i][0]===_teamName_){
                if(prevTeamScores[i][1]!=="0"){
                    prevPlace = i+1;
                    if(parseInt(place) - parseInt(prevPlace) < 0){
                        return <div>{Math.abs(parseInt(place) - parseInt(prevPlace))}<img src={"https://trxctiming.com/liveresults.trxctiming.com/images/green_arrow.png"} className="arrowXC" alt=""></img></div>;
                    }else if(parseInt(place) - parseInt(prevPlace) > 0){
                        return <div>{Math.abs(parseInt(place) - parseInt(prevPlace))}<img src={"https://trxctiming.com/liveresults.trxctiming.com/images/red_arrow.png"} className="arrowXC" alt=""></img></div>;
                    }else{
                        return <div><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/black_arrow.png"} className="doubleArrowXC" alt=""></img></div>;
                    }
                }else{
                    return "";
                }
            }
        }
    }else if(_split_===0 && _results_.length>1){
        let prevPlace = 0;
        let prevTeamScores = setTeamScores(_results_[_results_.length-1], _minRunners_, _maxRunners_, _teams_, _gender_, _results_.length-1, _scoring_);
        for(let i=0; i<prevTeamScores.length; i++){
            if(prevTeamScores[i][0]===_teamName_){
                if(prevTeamScores[i][1]!=="0"){
                    prevPlace = i+1;
                    if(parseInt(place) - parseInt(prevPlace) < 0){
                        return <div>{Math.abs(parseInt(place) - parseInt(prevPlace))}<img src={"https://trxctiming.com/liveresults.trxctiming.com/images/green_arrow.png"} className="arrowXC" alt=""></img></div>;
                    }else if(parseInt(place) - parseInt(prevPlace) > 0){
                        return <div>{Math.abs(parseInt(place) - parseInt(prevPlace))}<img src={"https://trxctiming.com/liveresults.trxctiming.com/images/red_arrow.png"} className="arrowXC" alt=""></img></div>;
                    }else{
                        return <div><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/black_arrow.png"} className="doubleArrowXC" alt=""></img></div>;
                    }
                }else{
                    return "";
                }
            }
        }
    }
    return "";
}

function getPlace(_place_, _index_){
    if(_place_===undefined || _place_==="" || _place_==="-"){
        return;
    }
    return _index_;
}

function getSplitName(_splitName_){
    if(_splitName_===undefined || _splitName_===null || _splitName_==="" || _splitName_==="NULL"){
        return "";
    }else{
        return _splitName_ + " ";
    }
}

function getSplitNameButton(_splitName_, _index_){
    if(_splitName_===undefined || _splitName_===null || _splitName_==="" || _splitName_==="NULL"){
        return "Split " + (_index_+1);
    }else{
        return _splitName_ + " ";
    }
}

function checkTeamDivisions(_teams_, _eventGender_){
    let divisions = 0;
    for(let i=0; i<_teams_.length; i++){
        if((_teams_[i][1]===_eventGender_ && _teams_[i][2]>divisions) || (_teams_[i][2]>divisions && _eventGender_==="0")){
            divisions = _teams_[i][2];
        }
    }
    return divisions;
}

function getDivisionResults(_results_, _teams_, _division_, _gender_){
    let maxSplits = getMaxSplits(_results_);
    maxSplits = maxSplits.length;
    let results = [maxSplits+1];
    results[0] = [];
    //Get final times
    for(let i=0; i<_results_.length; i++){
        if(checkDivision(_results_[i][2], _teams_, _division_, _gender_)){
            results[0].push(_results_[i]);
        }
    }
    //Get splits for each split
    for(let i=1; i<=maxSplits; i++){
        results[i] = [];
        //Get split times
        for(let j=0; j<_results_.length; j++){
            let splits = _results_[j][5].split(',');
            let split = "";
            if(splits[i-1]){
                split = splits[i-1];
                split = split.substring(0, split.indexOf(' '));
            }
            if(checkDivision(_results_[j][2], _teams_, _division_, _gender_)){
                results[i].push([_results_[j][0], _results_[j][1], _results_[j][2], _results_[j][3], split, _results_[j][5]]);
            }
        }
        //Sort splits
        for(let j=0; j<results[i].length-1; j++){
            for(let k=j+1; k<results[i].length; k++){
                if(convertTime(results[i][j][4]) > convertTime(results[i][k][4])){
                    let tempSplit = results[i][j];
                    results[i][j] = results[i][k];
                    results[i][k] = tempSplit;
                }
            }
            if(results[i][j][4].length>0){
                results[i][j][0] = j+1;
            }else{
                results[i][j][0] = "";
            }
        }
        if(results[i][results[i].length-1]!==undefined){
            if(results[i][results[i].length-1][4].length>0){
                results[i][results[i].length-1][0] = results[i].length;
            }else{
                results[i][results[i].length-1][0] = "";
            }
        }
    }
    return results;
}

function getGenderDivisionResults(_results_, _teams_, _division_, _gender_, _roster_){
    let maxSplits = getMaxSplits(_results_);
    maxSplits = maxSplits.length;
    let results = [maxSplits+1];
    results[0] = [];
    let counter=0;
    //Get final times
    for(let i=0; i<_results_.length; i++){
        if(checkDivision(_results_[i][2], _teams_, _division_, _gender_)){
            if(checkGender(_results_[i], _roster_, _gender_)){
                //results[0].push(_results_[i]);
                results[0][counter] = _results_[i];
                counter++;
            }
        }
    }
    //Get splits for each split
    for(let i=1; i<=maxSplits; i++){
        results[i] = [];
        counter = 0;
        //Get split times
        for(let j=0; j<_results_.length; j++){
            let splits = _results_[j][5].split(',');
            let split = "";
            if(splits[i-1]){
                split = splits[i-1];
                split = split.substring(0, split.indexOf(' '));
            }
            if(checkDivision(_results_[j][2], _teams_, _division_, _gender_)){
                if(checkGender(_results_[j], _roster_, _gender_)){
                    //results[i].push([_results_[j][0], _results_[j][1], _results_[j][2], _results_[j][3], split, _results_[j][5]]);
                    results[i][counter] = [_results_[j][0], _results_[j][1], _results_[j][2], _results_[j][3], split, _results_[j][5]];
                    counter++;
                }
            }
        }
        //Sort splits
        for(let j=0; j<results[i].length-1; j++){
            for(let k=j+1; k<results[i].length; k++){
                if(convertTime(results[i][j][4]) > convertTime(results[i][k][4])){
                    let tempSplit = results[i][j];
                    results[i][j] = results[i][k];
                    results[i][k] = tempSplit;
                }
            }
            if(results[i][j][4].length>0){
                results[i][j][0] = j+1;
            }else{
                results[i][j][0] = "";
            }
        }
        if(results[i][results[i].length-1]!==undefined){
            if(results[i][results[i].length-1][4].length>0){
                results[i][results[i].length-1][0] = results[i].length;
            }else{
                results[i][results[i].length-1][0] = "";
            }
        }
    }
    return results;
}

function checkDivision(_cTeam_, _teams_, _division_, _gender_){
    for(let i=0; i<_teams_.length; i++){
        if((_teams_[i][0]===_cTeam_ && _teams_[i][1]===_gender_) || (_teams_[i][0]===_cTeam_ && "0"===_gender_)){
            if(_teams_[i][2]===_division_){
                return true;
            }else{
                return false;
            }
        }
    }
    return false;
}

function getDivisionName(_divisionNames_, _division_){
    if(_divisionNames_==="NULL" || _divisionNames_===undefined || _divisionNames_===null || _divisionNames_.length<=0){
        return "Division " + _division_;
    }else{
        let divNames = _divisionNames_.split(',');
        if(divNames.length>=_division_){
            return divNames[_division_-1];
        }
    }
}

function getTeamPoints(_points_){
    if(_points_<=0){
        return "NTS";
    }
    return _points_
}

export default PhoneResultsXC;
