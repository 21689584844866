import React from 'react';
import axios from 'axios';
import './MeetScreen.css';
import StartListXC from './StartListXC.js';
import StartListTrack from './StartListTrack.js';
import StartListField from './StartListField.js';
import LiveResultsVertical from './LiveResultsVertical.js';
import LiveResultsVerticalMulti from './LiveResultsVerticalMulti.js';
import LiveResultsMultis from './LiveResultsMultis.js';
import LiveResultsField from './LiveResultsField.js';
import LiveResultsXC from './LiveResultsXC.js';
import LiveResultsTrack from './LiveResultsTrack.js';
import LiveResultsTrackMulti from './LiveResultsTrackMulti.js';
import TeamScores from './TeamScores.js';
import TeamScoresPhone from './TeamScoresPhone.js';
//import CompleteTeamScores from './CompleteTeamScores.js';
import {Link} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
  },
});

class MeetScreen extends React.Component {

  state = {
      session: 1,
      eventNumber: 0,
      roundNumber: 0,
      eventType: undefined,
      schedule: [],
      teamScores: [false, null, 0],
      status: 0,
      meetType: undefined,
      eventName: "",
      eventQs: undefined,
      eventqs: undefined,
      eventGender: undefined,
      splitNames: undefined,
      scoring: undefined,
      divisions: undefined,
      sessionNames: ["Schedule"],
      meetStatus: undefined,
      meetLocation: "",
      meetDate: "",
      meetHost: "",
      eventStatus: 0,
      timeZone: undefined,
      eventRecord: undefined
  }

  async componentDidMount() {

      let sessionNames, divisions, meetStatus, meetHost, meetDate, meetLocation, timeZone, teamScores, meetType, schedule=[];

      await axios.get("https://liveresults.trxctiming.com/api/meetInfo", { params: {id: this.props.match.params.id} }).then((response) => {
          if(response.data.length>0){
              meetHost = response.data[0][1];
              meetLocation = response.data[0][2];
              meetDate = response.data[0][3];
              meetType = response.data[0][5];
              meetStatus = response.data[0][6];
              if(response.data[0][7]>0){
                  teamScores = [true, response.data[0][8], response.data[0][9]];
              }else{
                  teamScores = [false, null, 0];
              }
              sessionNames = response.data[0][10];
              if(sessionNames==="NULL" || sessionNames===undefined || sessionNames===null || sessionNames.length===0){
                  sessionNames = ["Schedule"];
              }else{
                  sessionNames = sessionNames.split(',');
              }
              divisions = response.data[0][11];
              timeZone = response.data[0][12];
          }
      });

      axios.get("https://liveresults.trxctiming.com/api/schedule", { params: {id: this.props.match.params.id, session: this.state.session} }).then((response) => {
          if(response.data.length>0){
              schedule = response.data;
          }
      });

      this.setState({sessionNames: sessionNames, divisions: divisions, meetStatus: meetStatus, meetHost: meetHost, meetDate: meetDate, meetLocation: meetLocation, timeZone: timeZone, schedule: schedule, teamScores: teamScores, meetType: meetType});

      this.interval = setInterval(() => {
          axios.get("https://liveresults.trxctiming.com/api/schedule", { params: {id: this.props.match.params.id, session: this.state.session} }).then((response) => {
              if(response.data.length>0){
                  this.setState({schedule: response.data});
              }
          });
      }, 13000);

  }

  componentDidUpdate() {
      if(this.state.schedule.length===0){
          axios.get("https://liveresults.trxctiming.com/api/schedule", { params: {id: this.props.match.params.id, session: this.state.session} }).then((response) => {
              if(response.data.length>0){
                  this.setState({schedule: response.data});
              }
          });
      }
  }

  componentWillUnmount() {
      clearInterval(this.interval);
  }

  render() {
    if(this.state.schedule.length<=0 && window.innerWidth<1280){
        return <div className="phoneMeetScreen">
            <div className="meetHeaderPhone">
                <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.props.match.params.id + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                <div className="meetInfoPhone">
                    <p className="meetTitlePhone">{this.props.match.params.id.replaceAll('_', ' ')}</p>
                    <p className="meetLocationPhone">{this.state.meetLocation + " - " + filterDate(this.state.meetDate)}</p>
                    <p className="meetHostPhone">{this.state.meetHost}</p>
                </div>
            </div>
        </div>;
    }
    else if(window.innerWidth<1280) { return <div className="phoneMeetScreen">
        <div className="meetHeaderPhone">
            <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.props.match.params.id + ".png"} className="meetHeaderPicturePhone" alt=""></img>
            <div className="meetInfoPhone">
                <p className="meetTitlePhone">{this.props.match.params.id.replaceAll('_', ' ')}</p>
                <p className="meetLocationPhone">{this.state.meetLocation + " - " + filterDate(this.state.meetDate)}</p>
                <p className="meetHostPhone">{this.state.meetHost}</p>
            </div>
        </div>
        <div className="meetHeaderPhoneEmpty"></div>
        {this.state.sessionNames.map((session, index) => <div>
            <button className="scheduleButton" onClick={() => displaySession(session)}>{session}</button>
            <div className="scheduleSession" id={session} style={{display: this.state.sessionNames.length===1 ? 'block' : 'none'}}>
                <table className="scheduleTablePhone">
                    {getSessionSchedule(this.state.schedule, index+1).map(meet => <tbody><tr>
                        <td className="_scheduleEventPhone_"><table>
                            <tr><td className="scheduleEventNameRow">{meet[5] + " - " + getRoundName(meet[3], meet[7])}</td></tr>
                            <tr><td className="scheduleEventInfoRow">{getStartTime(meet[6])}</td></tr>
                        </table></td>
                        <td>{getPhoneLink(this.props.match.params.id, meet, this.state.meetType, this.state.meetStatus)}</td>
                    </tr></tbody>)}
                </table>
            </div>
        </div>)}
        {getPhoneTeamScores(this)}
      </div>}
    else {
        return <ThemeProvider theme={theme}><div className="meetScreen">
        <div className="meetMainArea">

            <div className="meetSchedule">
                {this.state.sessionNames.map((session, index) => <div>
                    <button className="scheduleButton" onClick={() => displaySession(session)}>{session}</button>
                    <div className="scheduleSession" id={session} style={{display: this.state.sessionNames.length===1 ? 'block' : 'none'}}>
                        <table className="scheduleTableDesktop">
                            {getSessionSchedule(this.state.schedule, index+1).map(meet => <tbody><tr>
                                <td className="scheduleEventDesktop"><table>
                                    <tr><td className="scheduleEventNameDesktop">{meet[5] + " - " + getRoundName(meet[3], meet[7])}</td></tr>
                                    <tr><td className="scheduleEventInfoDesktop">{getStartTime(meet[6])}</td></tr>
                                </table></td>
                                <td className="scheduleButtonDesktop">{getScheduleButton(this, this.state.meetStatus, meet)}</td>
                            </tr></tbody>)}
                        </table>
                    </div>
                </div>)}
                {getTeamScores(this)}
            </div>

            <div className="meetResults">
                <div className="meetHeader">
                    <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" + this.props.match.params.id + ".png"} className="meetHostImage" alt=""></img>
                    <div className="meetHeaderInfo">
                        <p className="meetHeaderTitle">{this.props.match.params.id.replaceAll('_', ' ')}</p>
                        <p className="meetHeaderHost">{this.state.meetHost}</p>
                        <p className="meetHeaderLocation">{this.state.meetLocation + " - " + filterDate(this.state.meetDate)}</p>
                    </div>
                </div>
                {getEventData(this)}
            </div>

        </div>

        <div className="meetFooter"><a href="https://trxctiming.com">By TRXC Timing</a></div>

    </div></ThemeProvider>}

  }

}

/* <div style={{visibility: this.state.status===3 && this.state.teamScores[0] ? 'visible' : 'hidden'}}><CompleteTeamScores meetName={this.props.match.params.id}/></div> */

//<div onClick={() => this.setState({status: 3})}>{getTeamScores(this)}</div>

/* <div className="meetSessions">
    {this.state.sessions.map( (session, index) => <button className="sessionButton" onClick={() => this.setState({session: index+1, schedule: []})}>{getSessionName(this.state.sessionNames, index)}</button>)}
    {getTeamScoresButton(this.state.teamScores, false, this.props.match.params.id)}
</div> */

function getSessionSchedule(_schedule_, _session_){
    let schedule = [];
    for(let i=0; i<_schedule_.length; i++){
        if(_schedule_[i][0]===_session_){
            schedule.push(_schedule_[i]);
        }
    }
    return schedule;
}

function displaySession(_session_){
    if(document.getElementById(_session_).style.display==="block"){
        document.getElementById(_session_).style.display = "none";
    }else if(document.getElementById(_session_).style.display==="none"){
        document.getElementById(_session_).style.display = "block";
    }
    return;
}

function getScheduleButton(_this_, _meetStatus_, _meet_){
    if(_meet_[10]==="Track"){
        return getScheduleButtonTrack(_this_, _meetStatus_, _meet_);
    }else if(_meet_[10]==="Field"){
        return getScheduleButtonField(_this_, _meetStatus_, _meet_);
    }
    return;
}

function getScheduleButtonField(_this_, _meetStatus_, _meet_){
  if(_meetStatus_===2){
      return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultDesktop_"><table onClick={() => _this_.setState({eventNumber: _meet_[2], roundNumber: _meet_[3], status: 2, eventType: getEventType(_meet_[5]), eventName: _meet_[5], eventGender: getGender(_meet_[5]), eventStatus: 4, eventRecord: _meet_[15]})}>
          <tr><td className="scheduleEventLinkDesktop">Results</td></tr>
          <tr><td className="scheduleEventStatusDesktop">Official</td></tr>
      </table></td>;
  }
  if(_meet_[11]===1){
      return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultDesktop_"><table onClick={() => _this_.setState({eventNumber: _meet_[2], roundNumber: _meet_[3], status: 1, eventType: "Field", eventName: _meet_[5], eventStatus: 1, eventRecord: _meet_[15]})}>
          <tr><td className="scheduleEventLinkDesktop">Start List</td></tr>
          <tr><td className="scheduleEventStatusDesktop">Seeded</td></tr>
      </table></td>;
  }else if(_meet_[11]===2){
      return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultDesktop_"><table onClick={() => _this_.setState({eventNumber: _meet_[2], roundNumber: _meet_[3], status: 2, eventType: getEventType(_meet_[5]), eventName: _meet_[5], eventGender: getGender(_meet_[5]), eventStatus: 2, eventRecord: _meet_[15]})}>
          <tr><td className="scheduleEventLinkDesktop">Results</td></tr>
          <tr><td className="scheduleEventStatusDesktop">LIVE</td></tr>
      </table></td>;
  }else if(_meet_[11]===3){
    return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultDesktop_"><table onClick={() => _this_.setState({eventNumber: _meet_[2], roundNumber: _meet_[3], status: 2, eventType: getEventType(_meet_[5]), eventName: _meet_[5], eventGender: getGender(_meet_[5]), eventStatus: 3, eventRecord: _meet_[15]})}>
        <tr><td className="scheduleEventLinkDesktop">Results</td></tr>
        <tr><td className="scheduleEventStatusDesktop">Finished</td></tr>
    </table></td>;
  }else if(_meet_[11]===4){
    return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultDesktop_"><table onClick={() => _this_.setState({eventNumber: _meet_[2], roundNumber: _meet_[3], status: 2, eventType: getEventType(_meet_[5]), eventName: _meet_[5], eventGender: getGender(_meet_[5]), eventStatus: 4, eventRecord: _meet_[15]})}>
        <tr><td className="scheduleEventLinkDesktop">Results</td></tr>
        <tr><td className="scheduleEventStatusDesktop">Official</td></tr>
    </table></td>;
  }
  return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultDesktop_"><table onClick={() => _this_.setState({eventStatus: 0})}>
      <tr><td className="scheduleEventLinkDesktop">N/A</td></tr>
      <tr><td className="scheduleEventStatusDesktop">Unseeded</td></tr>
  </table></td>;
}

function getScheduleButtonTrack(_this_, _meetStatus_, _meet_){
  if(_meetStatus_===2){
      return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultDesktop_"><table onClick={() => _this_.setState({eventNumber: _meet_[2], roundNumber: _meet_[3], status: 2, eventType: getTrackEventType(_meet_[5]), eventName: _meet_[5], eventQs: _meet_[8], eventqs: _meet_[9], eventGender: getGender(_meet_[5]), splitNames: _meet_[13], scoring: _meet_[12], eventStatus: 4, eventRecord: _meet_[15]})}>
          <tr><td className="scheduleEventLinkDesktop">Results</td></tr>
          <tr><td className="scheduleEventStatusDesktop">Official</td></tr>
      </table></td>;
  }
  if(_meet_[11]===1){
      return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultDesktop_"><table onClick={() => _this_.setState({eventNumber: _meet_[2], roundNumber: _meet_[3], status: 1, eventType: "Track", eventName: _meet_[5], eventStatus: 1, eventRecord: _meet_[15]})}>
          <tr><td className="scheduleEventLinkDesktop">Start List</td></tr>
          <tr><td className="scheduleEventStatusDesktop">Seeded</td></tr>
      </table></td>;
  }else if(_meet_[11]===2){
      return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultDesktop_"><table onClick={() => _this_.setState({eventNumber: _meet_[2], roundNumber: _meet_[3], status: 2, eventType: getTrackEventType(_meet_[5]), eventName: _meet_[5], eventQs: _meet_[8], eventqs: _meet_[9], eventGender: getGender(_meet_[5]), splitNames: _meet_[13], scoring: _meet_[12], eventStatus: 2, eventRecord: _meet_[15]})}>
          <tr><td className="scheduleEventLinkDesktop">Results</td></tr>
          <tr><td className="scheduleEventStatusDesktop">LIVE</td></tr>
      </table></td>;
  }else if(_meet_[11]===3){
    return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultDesktop_"><table onClick={() => _this_.setState({eventNumber: _meet_[2], roundNumber: _meet_[3], status: 2, eventType: getTrackEventType(_meet_[5]), eventName: _meet_[5], eventQs: _meet_[8], eventqs: _meet_[9], eventGender: getGender(_meet_[5]), splitNames: _meet_[13], scoring: _meet_[12], eventStatus: 3, eventRecord: _meet_[15]})}>
        <tr><td className="scheduleEventLinkDesktop">Results</td></tr>
        <tr><td className="scheduleEventStatusDesktop">Finished</td></tr>
    </table></td>;
  }else if(_meet_[11]===4){
    return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultDesktop_"><table onClick={() => _this_.setState({eventNumber: _meet_[2], roundNumber: _meet_[3], status: 2, eventType: getTrackEventType(_meet_[5]), eventName: _meet_[5], eventQs: _meet_[8], eventqs: _meet_[9], eventGender: getGender(_meet_[5]), splitNames: _meet_[13], scoring: _meet_[12], eventStatus: 4, eventRecord: _meet_[15]})}>
        <tr><td className="scheduleEventLinkDesktop">Results</td></tr>
        <tr><td className="scheduleEventStatusDesktop">Official</td></tr>
    </table></td>;
  }
  return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultDesktop_"><table onClick={() => _this_.setState({eventStatus: 0})}>
      <tr><td className="scheduleEventLinkDesktop">N/A</td></tr>
      <tr><td className="scheduleEventStatusDesktop">Unseeded</td></tr>
  </table></td>;
}


function getPhoneLink(_id_, _meet_, _meetType_, _meetStatus_){
    if(_meetStatus_===2){
        return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultPhone_"><table>
            <tr><td className="scheduleEventLinkRow">{getResult(_id_, _meet_[0], _meet_[11], _meet_[2], _meet_[3], _meet_[10])}</td></tr>
            <tr><td className="scheduleEventStatusRow">Official</td></tr>
        </table></td>;
    }
    if(_meet_[11]===1){
        return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultPhone_"><table>
            <tr><td className="scheduleEventLinkRow">{getStartList(_id_, _meet_[0], _meet_[11], _meet_[2], _meet_[3], _meetType_)}</td></tr>
            <tr><td className="scheduleEventStatusRow">Seeded</td></tr>
        </table></td>;
    }else if(_meet_[11]===2){
        return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultPhone_"><table>
            <tr><td className="scheduleEventLinkRow">{getResult(_id_, _meet_[0], _meet_[11], _meet_[2], _meet_[3], _meet_[10])}</td></tr>
            <tr><td className="scheduleEventStatusRow">LIVE</td></tr>
        </table></td>;
    }else if(_meet_[11]===3){
      return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultPhone_"><table>
          <tr><td className="scheduleEventLinkRow">{getResult(_id_, _meet_[0], _meet_[11], _meet_[2], _meet_[3], _meet_[10])}</td></tr>
          <tr><td className="scheduleEventStatusRow">Finished</td></tr>
      </table></td>;
    }else if(_meet_[11]===4){
      return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultPhone_"><table>
          <tr><td className="scheduleEventLinkRow">{getResult(_id_, _meet_[0], _meet_[11], _meet_[2], _meet_[3], _meet_[10])}</td></tr>
          <tr><td className="scheduleEventStatusRow">Official</td></tr>
      </table></td>;
    }
    return <td style={getStatusStyle(_meet_[11], _meetStatus_)} className="_scheduleResultPhone_"><table>
        <tr><td className="scheduleEventLinkRow"><p className="statusTextPhone">N/A</p></td></tr>
        <tr><td className="scheduleEventStatusRow">Unseeded</td></tr>
    </table></td>;
}

function filterDate(_date_){
    if(_date_===undefined || _date_===null || _date_.length<=0){
        return '';
    }else{
       return _date_.substring(_date_.indexOf(",")+2, _date_.indexOf("00:00:00")-1)
    }
}

function getEventType(_eventName_){
    if(_eventName_.toUpperCase().includes("PV") || _eventName_.toUpperCase().includes("P.V.") || _eventName_.toUpperCase().includes("POLE VAULT")){
        if(_eventName_.toUpperCase().includes("HEPT")){
            return "Multi-Vertical";
        }if(_eventName_.toUpperCase().includes("DEC")){
            return "Multi-Vertical";
        }if(_eventName_.toUpperCase().includes("PENT")){
            return "Multi-Vertical";
        }else{
            return "Vertical";
        }
    }else if(_eventName_.toUpperCase().includes("HJ") || _eventName_.toUpperCase().includes("H.J.") || _eventName_.toUpperCase().includes("HIGH JUMP")){
        if(_eventName_.toUpperCase().includes("HEPT")){
            return "Multi-Vertical";
        }if(_eventName_.toUpperCase().includes("DEC")){
            return "Multi-Vertical";
        }if(_eventName_.toUpperCase().includes("PENT")){
            return "Multi-Vertical";
        }else{
            return "Vertical";
        }
    }else{
        if(_eventName_.toUpperCase().includes("HEPT")){
            return "Multis";
        }if(_eventName_.toUpperCase().includes("DEC")){
            return "Multis";
        }if(_eventName_.toUpperCase().includes("PENT")){
            return "Multis";
        }else{
            return "Field";
        }
    }
}

function getTrackEventType(_eventName_){
    if(_eventName_.toUpperCase().includes("HEPT")){
        return "Multi-Track";
    }if(_eventName_.toUpperCase().includes("DEC")){
        return "Multi-Track";
    }if(_eventName_.toUpperCase().includes("PENT")){
        return "Multi-Track";
    }else{
        return "Track";
    }
}

function getGender(_eventName_){
    if(_eventName_.toUpperCase().includes("(G)") || _eventName_.toUpperCase().includes("GIRLS") || _eventName_.toUpperCase().includes("(W)") || _eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("GIRL") || _eventName_.toUpperCase().includes("WOMAN")){
        return "Women";
    }else{
        return "Men";
    }
}

function getRoundName(round, rounds) {
    if(round < rounds-1){
        return "Prelim";
    }else if (round < rounds){
        return "Semi";
    }else if (round >= rounds){
        return "Final";
    }
}

function getStartList(_meetName_, _session_, _status_, _eventNumber_, _roundNumber_, _meetType_) {
    if(_status_ > 0)
        return <Link target="_blank" style={{textDecoration: 'none'}} to={'/StartList/' + _meetName_ + '/' + _session_ + '/' + _eventNumber_ + '/' + _roundNumber_}><p className="statusTextPhone">START LIST</p></Link>;
    else
        return "N/A";
}

function getResult(_meetName_, _session_, _status_, _eventNumber_, _roundNumber_, _meetType_) {
    if(_status_ > 1)
        return <Link target="_blank" style={{textDecoration: 'none'}} to={'/Results/' + _meetType_ + '/' + _meetName_ + '/' + _session_ + '/' + _eventNumber_ + '/' + _roundNumber_}><p className="statusTextPhone">RESULT</p></Link>;
    else
        return "N/A";
}

/*function getSchedule(data, type) {
    let scheduleArray = [];
    for(let i=0; i<data.length; i++){
        let tempArray = data[i];
        if(tempArray!==undefined && (tempArray[10]===type || type==="ALL")){
            scheduleArray.push(tempArray);
        }
    }
    return scheduleArray;
}*/

function getStartTime(_startTime_){
    if(_startTime_===null || _startTime_==="NULL" || _startTime_===undefined){
        return ""
    }else{
        return _startTime_;
    }
}

function getTeamScores(_this_){
    if(_this_.state.teamScores[0]===true){
        return <TeamScores meetName={_this_.props.match.params.id} scoringInfo={_this_.state.teamScores} divisions={_this_.state.divisions}/>;
    }else{
        return;
    }
}

function getPhoneTeamScores(_this_){
    if(_this_.state.teamScores[0]===true){
        return <TeamScoresPhone meetName={_this_.props.match.params.id} scoringInfo={_this_.state.teamScores} divisions={_this_.state.divisions}/>;
    }else{
        return;
    }
}

/*function getTeamScoresButton(_teamScores_, _phone_, _meetName_){
    if(_teamScores_[0]!==undefined && _teamScores_[0]===true && _phone_){
        return <p className="phoneTeamScoresButton"><Link style={{textDecoration: 'none', color: 'inherit'}} to={'/TeamScores/' + _meetName_}>View Team Scores</Link></p>;
    }else if(_teamScores_[0]!==undefined && _teamScores_[0]===true && !_phone_){
        return;
    }else {
        return;
    }
}*/


function getEventData(_this_){
    if(_this_.state.session>0 && (_this_.state.eventNumber>0 && _this_.state.roundNumber>0 || _this_.state.status===3)){
        if(_this_.state.status===1){
            if(_this_.state.meetType==="Cross Country"){
                return <StartListXC meetName={_this_.props.match.params.id} session={_this_.state.session} eventNumber={_this_.state.eventNumber} roundNumber={_this_.state.roundNumber} eventName={_this_.state.eventName}/>;
            }else if(_this_.state.eventType==="Track"){
                return <StartListTrack meetName={_this_.props.match.params.id} session={_this_.state.session} eventNumber={_this_.state.eventNumber} roundNumber={_this_.state.roundNumber} eventName={_this_.state.eventName}/>;
            }else if(_this_.state.eventType==="Field" || _this_.state.eventType==="Vertical"){
                return <StartListField meetName={_this_.props.match.params.id} session={_this_.state.session} eventNumber={_this_.state.eventNumber} roundNumber={_this_.state.roundNumber} eventName={_this_.state.eventName}/>;
            }else{
                return;
            }
        }else if(_this_.state.status===2){
            if(_this_.state.meetType==="Cross Country"){
                return <LiveResultsXC meetName={_this_.props.match.params.id} session={_this_.state.session} eventNumber={_this_.state.eventNumber} roundNumber={_this_.state.roundNumber} eventName={_this_.state.eventName} minTeamRunners={_this_.state.eventQs} maxTeamRunners={_this_.state.eventqs} splitNames={_this_.state.splitNames} scoring={_this_.state.scoring} divisions={_this_.state.divisions} eventStatus={_this_.state.eventStatus} timeZone={_this_.state.timeZone} eventRecord={_this_.state.eventRecord}/>;
            } else if(_this_.state.eventType==="Track"){
                return <LiveResultsTrack meetName={_this_.props.match.params.id} session={_this_.state.session} eventNumber={_this_.state.eventNumber} roundNumber={_this_.state.roundNumber} eventName={_this_.state.eventName} eventQs={_this_.state.eventQs} eventqs={_this_.state.eventqs} eventStatus={_this_.state.eventStatus} timeZone={_this_.state.timeZone} divisions={_this_.state.divisions} eventGender={_this_.state.eventGender} eventRecord={_this_.state.eventRecord}/>;
            } else if(_this_.state.eventType==="Multi-Track"){
                return <LiveResultsTrackMulti meetName={_this_.props.match.params.id} session={_this_.state.session} eventNumber={_this_.state.eventNumber} roundNumber={_this_.state.roundNumber} eventName={_this_.state.eventName} eventQs={_this_.state.eventQs} eventqs={_this_.state.eventqs} eventGender={_this_.state.eventGender} eventStatus={_this_.state.eventStatus} timeZone={_this_.state.timeZone} eventRecord={_this_.state.eventRecord}/>;
            } else if(_this_.state.eventType==="Vertical"){
                return <LiveResultsVertical meetName={_this_.props.match.params.id} session={_this_.state.session} eventNumber={_this_.state.eventNumber} roundNumber={_this_.state.roundNumber} eventName={_this_.state.eventName} divisions={_this_.state.divisions} eventGender={_this_.state.eventGender} eventRecord={_this_.state.eventRecord}/>;
            } else if(_this_.state.eventType==="Field"){
                return <LiveResultsField meetName={_this_.props.match.params.id} session={_this_.state.session} eventNumber={_this_.state.eventNumber} roundNumber={_this_.state.roundNumber} eventName={_this_.state.eventName} divisions={_this_.state.divisions} eventGender={_this_.state.eventGender} eventRecord={_this_.state.eventRecord}/>;
            } else if(_this_.state.eventType==="Multis"){
                return <LiveResultsMultis meetName={_this_.props.match.params.id} session={_this_.state.session} eventNumber={_this_.state.eventNumber} roundNumber={_this_.state.roundNumber} eventName={_this_.state.eventName} eventGender={_this_.state.eventGender} eventRecord={_this_.state.eventRecord}/>;
            } else if(_this_.state.eventType==="Multi-Vertical"){
                return <LiveResultsVerticalMulti meetName={_this_.props.match.params.id} session={_this_.state.session} eventNumber={_this_.state.eventNumber} roundNumber={_this_.state.roundNumber} eventName={_this_.state.eventName} eventGender={_this_.state.eventGender} eventRecord={_this_.state.eventRecord}/>;
            }
        }else{
            return;
        }
    }else{
        return;
    }
}

function getSessionName(_sessionNames_, _index_){
    if(_sessionNames_===undefined || _sessionNames_==="NULL"){
        return "Session " + (_index_+1);
    }else{
        return _sessionNames_[_index_];
    }
}

/*function getStatus(_status_, _meetStatus_){
    if(_meetStatus_===2){
        return "Official";
    }
    if(_status_===1){
        return "Seeded";
    }else if(_status_===2){
        return "LIVE";
    }else if(_status_===3){
        return "Finished";
    }else if(_status_===4){
        return "Official";
    }
    return "Unseeded";
}*/

function getStatusStyle(_status_, _meetStatus_){
    if(_meetStatus_===2){
        return {backgroundColor: "#FFBC00"};
    }
    if(_status_===1){
        return {backgroundColor: "#D0D0D0"};
    }else if(_status_===2){
        return {backgroundColor: "#7CFC00"};
    }else if(_status_===3){
        return {backgroundColor: "#FFBC00"};
    }else if(_status_===4){
        return {backgroundColor: "#FFBC00"};
    }
    return {backgroundColor: "#707070", color: "#FFFFFF"};
}


export default MeetScreen;
