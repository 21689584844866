import React from 'react';
import axios from 'axios';
import './PhoneLiveClock.css';

class PhoneLiveClock extends React.Component {

  state = {
      meetName: this.props.meetName,
      eventNumber: this.props.eventNumber,
      roundNumber: this.props.roundNumber,
      timer: -1,
      timeZone: this.props.timeZone
  }

  componentDidMount() {

      axios.get("https://liveresults.trxctiming.com/api/eventInfo", { params: {id: this.state.meetName, event:this.state.eventNumber, round:this.state.roundNumber} }).then((response) => {
          if(response.data.length>0){
              if(response.data[0][14]!==undefined && response.data[0][14]!=="NULL" && response.data[0][14]!==null && response.data[0][11]===2){
                  let startTime = response.data[0][14];
                  if(startTime.includes('.')){
                      startTime = startTime.substring(0,startTime.indexOf('.'));
                  }

                  let cDate = new Date()
                  let cStamp = cDate.getTime() + cDate.getTimezoneOffset()*60*1000;

                  let timerDate = new Date(cStamp - 18000000);
                  const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                  let startDate = new Date(timerDate.getDate() + ' ' + monthNames[timerDate.getMonth()] + ' ' + timerDate.getFullYear() + ' ' + startTime + ' ' + this.state.timeZone);
                  let startStamp = startDate.getTime() + startDate.getTimezoneOffset()*60*1000;

                  if(startStamp>0 && cStamp>0){
                      this.setState({timer: cStamp - startStamp});
                  }
              }
          }
      });

      this.interval = setInterval(() => {
          if(this.state.timer>=0)
          this.setState({timer: this.state.timer+100});
      }, 100);

      this.interval2 = setInterval(() => {
          axios.get("https://liveresults.trxctiming.com/api/eventInfo", { params: {id: this.state.meetName, event:this.state.eventNumber, round:this.state.roundNumber} }).then((response) => {
              if(response.data.length>0){
                  if(response.data[0][14]!==undefined && response.data[0][14]!=="NULL" && response.data[0][14]!==null && response.data[0][11]===2){
                      let startTime = response.data[0][14];
                      if(startTime.includes('.')){
                          startTime = startTime.substring(0,startTime.indexOf('.'));
                      }

                      let cDate = new Date()
                      let cStamp = cDate.getTime() + cDate.getTimezoneOffset()*60*1000;

                      let timerDate = new Date(cStamp - 18000000);
                      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                      let startDate = new Date(timerDate.getDate() + ' ' + monthNames[timerDate.getMonth()] + ' ' + timerDate.getFullYear() + ' ' + startTime + ' ' + this.state.timeZone);
                      let startStamp = startDate.getTime() + startDate.getTimezoneOffset()*60*1000;

                      if(startStamp>0 && cStamp>0){
                          this.setState({timer: cStamp - startStamp});
                      }
                  }else if(response.data[0][11]!==2){
                      this.setState({timer: -1});
                  }
              }
          });
      }, 3000);

  }

  componentDidUpdate(prevProps, prevState) {
      if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber || prevProps.meetName!==this.props.meetName){
          this.setState({timer: -1, meetName: this.props.meetName, eventNumber: this.props.eventNumber, roundNumber: this.props.roundNumber});
          axios.get("https://liveresults.trxctiming.com/api/eventInfo", { params: {id: this.props.meetName, event:this.props.eventNumber, round:this.props.roundNumber} }).then((response) => {
              if(response.data.length>0){
                  if(response.data[0][14]!==undefined && response.data[0][14]!=="NULL" && response.data[0][14]!==null && response.data[0][11]===2){
                      let startTime = response.data[0][14];
                      if(startTime.includes('.')){
                          startTime = startTime.substring(0,startTime.indexOf('.'));
                      }

                      let cDate = new Date()
                      let cStamp = cDate.getTime() + cDate.getTimezoneOffset()*60*1000;

                      let timerDate = new Date(cStamp - 18000000);
                      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                      let startDate = new Date(timerDate.getDate() + ' ' + monthNames[timerDate.getMonth()] + ' ' + timerDate.getFullYear() + ' ' + startTime + ' ' + this.state.timeZone);
                      let startStamp = startDate.getTime() + startDate.getTimezoneOffset()*60*1000;

                      if(startStamp>0 && cStamp>0){
                          this.setState({timer: cStamp - startStamp});
                      }
                  }else if(response.data[0][11]!==2){
                      this.setState({timer: -1});
                  }
              }
          });
      }
  }

  componentWillUnmount() {
      clearInterval(this.interval);
      clearInterval(this.interval2);
  }

  render() {
      return <div className="phoneClock"><p>{getTime(this.state.timer)}</p></div>;
  }


}

function getTime(_timer_){
    if(_timer_<=0){
      return;
    }
    let timer = _timer_;
    let timerString = '';
    if(timer>=3600000){
        let h = parseInt(timer/3600000);
        timerString += (h + ':');
        timer = timer - 3600000*h;
    }
    if(timer>=60000){
        let m = parseInt(timer/60000);
        if(m<10){
            timerString += '0';
        }
        timerString += (m + ':');
        timer = timer - 60000*m;
    }else{
        timerString += '00:';
    }
    if(timer<10000){
        timerString += '0';
    }
    timerString += (timer/1000).toFixed(1);
    return timerString;
}

export default PhoneLiveClock;
