import React from 'react';
import axios from 'axios';
import './PhoneResultsVertical.css';
import './StartListPhone.css';
import MultisTablePhone from './MultisTablePhone.js';

class PhoneResultsVertical extends React.Component {

    state = {
        meetName: this.props.meetName,
        session: this.props.session,
        eventNumber: this.props.eventNumber,
        roundNumber: this.props.roundNumber,
        eventName: this.props.eventName,
        divisions: this.props.divisions,
        eventGender: this.props.eventGender,
        eventProgression: [],
        status: 0,
        system: undefined,
        teams: [],
        results: [],
        roster: [],
        entries: [],
        multiTable: false
    }

    componentDidMount() {
        this.setState({eventName: filterName(this.state.eventName)});

        axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.state.meetName, event: this.state.eventNumber, round: this.state.roundNumber, session: this.state.session} }).then((response) => {
            if(response.data.length>0){
                this.setState({entries: response.data});
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Field"} }).then((response) => {
            if(response.data.length>0){
                this.setState({system: response.data[0][5]});
                let eventProgression = response.data[0][6].split(',');
                eventProgression.pop();
                eventProgression.shift();
                if(eventProgression!==undefined){
                    this.setState({results: filterData(response.data, eventProgression), eventProgression: eventProgression});
                }
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});
        });

        axios.get("https://liveresults.trxctiming.com/api/teams", { params: {id: this.state.meetName} }).then(response => {
            this.setState({teams: response.data});
        });

        //set interval for live results updates
        this.interval = setInterval(() => {

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0 && response.data.length>=this.state.results.length){
                    this.setState({system: response.data[0][5]});
                    let eventProgression = response.data[0][6].split(',');
                    eventProgression.pop();
                    eventProgression.shift();
                    if(eventProgression!==undefined){
                        this.setState({results: filterData(response.data, eventProgression), eventProgression: eventProgression});
                    }
                }
            });

        }, 15000); //15s

    }

    componentDidUpdate(prevProps) {
        if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber){

            this.setState({session: this.props.session, eventNumber: this.props.eventNumber, roundNumber: this.props.roundNumber, eventName: filterName(this.props.eventName), eventProgression: [], results: [], status: 0, eventGender: this.props.eventGender});

            axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.props.meetName, event: this.props.eventNumber, round: this.props.roundNumber, session: this.props.session} }).then((response) => {
                if(response.data.length>0){
                    this.setState({entries: response.data});
                }
            });

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.props.meetName, session: this.props.session, event: this.props.eventNumber, round: this.props.roundNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0){
                    this.setState({system: response.data[0][5]});
                    let eventProgression = response.data[0][6].split(',');
                    eventProgression.pop();
                    eventProgression.shift();
                    if(eventProgression!==undefined){
                        this.setState({results: filterData(response.data, eventProgression), eventProgression: eventProgression});
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if(!this.state.multiTable){
            return <div className="eventResultVertical">
                <div className="meetHeaderPhone">
                    <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.props.meetName + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                    <div className="meetInfoPhone">
                        <p className="meetTitlePhone">{this.state.meetName.replaceAll('_', ' ')}</p>
                        <p className="meetHostPhone">{this.state.eventName}</p>
                    </div>
                </div>
                <div className="meetHeaderPhoneEmpty"></div>
                {getCompiledButton(this, this.state.eventName)}
                {getHeaderButtons(this)}
                {this.state.status===-1 ? getStartListTable(this.state.entries, this.state.roster) : getResultsTable(this.state.status, this.state.eventProgression, this.state.system, this.state.results, this.state.roster, this.state.divisions, this.state.teams, this.state.eventGender, this.state.eventName)}
            </div>}
        if(this.state.multiTable) { return <div className="eventResultVertical">
            <div className="meetHeaderPhone">
                <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.props.meetName + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                <div className="meetInfoPhone">
                    <p className="meetTitlePhone">{this.state.meetName.replaceAll('_', ' ')}</p>
                    <p className="meetHostPhone">{this.state.eventName}</p>
                </div>
            </div>
            <div className="meetHeaderPhoneEmpty"></div>
            {getCompiledButton(this, this.state.eventName)}
            {getMultisTable(this.state.eventName, this.state.eventNumber, this.state.meetName)}
        </div>}
    }
}

function getResultsTable(_status_, _progression_, _system_, _results_, _roster_, _divisions_, _teams_, _gender_, _eventName_){
    if(_status_===0){
        return <table className="phoneTableVertical">
            <thead>
                <th className="phonePlaceVertical">Pl.</th>
                <th className="phoneLogoColumnVertical"></th>
                <th className="phoneAthleteVertical">Athlete</th>
                <th className="phoneMarkVertical">Mark</th>
            </thead>
            {_results_.map( athlete => <tbody><tr>
                <td className="phonePlaceVertical">{athlete[0]}</td>
                <td className="phoneLogoColumnVertical"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[2] + ".png"} className="phoneLogoVertical" alt=""></img></td>
                <td className="phoneAthleteXC"><table>
                    <tr><td className="phoneXCRowName">{athlete[3] + getGrade(athlete[6], _roster_)}</td></tr>
                    <tr><td className="phoneXCRowTeam">{athlete[2]}</td></tr>
                </table></td>
                <td><table>
                    <tr><td className="phoneMarkVertical">{getMark(athlete[0], athlete[5]) + getScore(_eventName_, athlete[5])}</td></tr>
                    <tr><td className="phoneMarkVerticalConversion">{getMarkConversion(athlete[0], athlete[5], _system_)}</td></tr>
                </table></td></tr>
                {getAttemptsTable(athlete[0], _progression_, athlete[4], _system_)}
            </tbody>)}
        </table>;
    }else if(_status_===1){
      let _byDivisionResults_ = [];
      for(let i=1; i<=_divisions_.split(',').length; i++){
          _byDivisionResults_[i-1] = filterByDivision(_results_, i, _teams_, _gender_);
      }
      return _byDivisionResults_.map( (flightResults, index) => <div><button className="flightButtonPhone" onClick={() => displayHeat(index)}>{_divisions_.split(',')[index]}</button>
          <div className="heatResults" id={index} style={{display: 'block'}}>
              <table className="phoneTableField">
                  <thead>
                      <th className="phonePlaceVertical">Pl.</th>
                      <th className="phoneLogoColumnVertical"></th>
                      <th className="phoneAthleteVertical">Athlete</th>
                      <th className="phoneMarkVertical">Mark</th>
                  </thead>
                  {flightResults.map( athlete => <tbody><tr>
                      <td className="phonePlaceVertical">{getDivisionPlace(flightResults, athlete[0])}</td>
                      <td className="phoneLogoColumnVertical"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[2] + ".png"} className="phoneLogoVertical" alt=""></img></td>
                      <td className="phoneAthleteXC"><table>
                          <tr><td className="phoneXCRowName">{athlete[3] + getGrade(athlete[6], _roster_)}</td></tr>
                          <tr><td className="phoneXCRowTeam">{athlete[2]}</td></tr>
                      </table></td>
                      <td><table>
                          <tr><td className="phoneMarkVertical">{getMark(athlete[0], athlete[5]) + getScore(_eventName_, athlete[5])}</td></tr>
                          <tr><td className="phoneMarkVerticalConversion">{getMarkConversion(athlete[0], athlete[5], _system_)}</td></tr>
                      </table></td></tr>
                      {getAttemptsTable(athlete[0], _progression_, athlete[4], _system_)}
                  </tbody>)}
              </table>
          </div>
      </div>);
    }
}

function getStartListTable(_entries_, _roster_){
    return <table className="phoneTableXC">
        <thead>
            <th className="_flightPhone_">Flight</th>
            <th className="logoColumnPhoneXC"></th>
            <th className="athletePhoneXC">Athlete</th>
            {getEntryHeader(_entries_)}
        </thead>
        {_entries_.map(meet => <tbody><tr>
            <td className="_flightPhone_">{meet[3] + "-" + meet[6]}</td>
            <td className="logoColumnPhone"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + meet[8] + ".png"} alt="" className="logoPhone"></img></td>
            <td className="phoneAthleteXC"><table>
                <tr><td className="phoneXCRowName">{meet[7] + getGrade(meet[5], _roster_)}</td></tr>
                <tr><td className="phoneXCRowTeam">{meet[8]}</td></tr>
            </table></td>
            {getEntry(meet[9], meet[8])}
        </tr></tbody>)}
    </table>;
}

function getHeaderButtons(_this_){
    return <table className="heatButtonsPhone"><tr>
        <td className="heatButtonPhone" onClick={() => _this_.setState({status: -1})}>{'Start List'}</td>
        <td className="heatButtonPhone" onClick={() => _this_.setState({status: 0})}>{'Compiled'}</td>
        {_this_.state.divisions.split(',').length>1 && _this_.state.divisions.toUpperCase()!=="JV,Varsity" ? <td className="heatButtonPhone" onClick={() => _this_.setState({status: 1})}>{'By Division'}</td> : null}
    </tr></table>;
}

function getEntryHeader(_entries_){
    if(_entries_.length<=0){
        return <th className="phoneTeamXC"></th>;
    }
    if(_entries_[0][9]!==undefined && _entries_[0][9]!=="" && _entries_[0][9]!=="NULL" && _entries_[0][9]!==null){
        return <th className="phoneTeamXC"></th>;
    }else {
        return <th className="phoneTeamXC">Entry Mark</th>;
    }
}

function getEntry(_entry_, _team_){
      if(_entry_!==undefined && _entry_!=="" && _entry_!=="NULL" && _entry_!==null){
          return <td className="entryXC">{_entry_}</td>;
      }else{
          return <td className="entryXC"></td>;
      }
}

function getMarkConversion(_place_, _bestMark_, _system_){
    if(_place_==="" || _place_==="-" || _place_===null || _place_===undefined || _place_==="DNS" || _bestMark_.toUpperCase()==="JUMPOFF"){
        return;
    }
    if(_system_==="Metric"){
        let englishMark = Math.floor((parseFloat(_bestMark_) + 0.002) * 39.3700787402 * 100) / 100;
        let englishMarkString = "";

        let markFeet = parseInt(englishMark/12);
        let markInches = parseFloat(englishMark-markFeet*12).toFixed(2);

        if(markInches-parseInt(markInches)>0.75){
            markInches = markInches - (markInches - parseInt(markInches) - 0.75);
        }else if(markInches-parseInt(markInches)<0.75 && markInches-parseInt(markInches)>0.50){
            markInches = markInches - (markInches - parseInt(markInches) - 0.50);
        }else if(markInches-parseInt(markInches)<0.50 && markInches-parseInt(markInches)>0.25){
            markInches = markInches - (markInches - parseInt(markInches) - 0.25);
        }else if(markInches-parseInt(markInches)<0.25 && markInches-parseInt(markInches)>0.00){
            markInches = markInches - (markInches - parseInt(markInches));
        }

        englishMarkString = markFeet.toString() + "-" + parseFloat(markInches).toFixed(2);

        return englishMarkString;
    }
    return "";
}

function getDivisionPlace(_flightResults_, _place_){
    if(_place_==="-" || _place_==="DNS"){
        return _place_;
    }
    let place = 1;
    for(let i=0; i<_flightResults_.length; i++){
        if(_flightResults_[i][0]===_place_){
            return place;
        }else{
            place++;
        }
    }
    return _place_;
}

function getMark(_place_, _bestMark_){
    if(_place_==="" || _place_==="-" || _place_===null || _place_===undefined || _place_==="DNS"){
        return "NH";
    }
    return _bestMark_;
}

function filterName(_name_){
    return _name_;
    /*let updatedName = _name_;
    if(updatedName.includes("(M)")){
        updatedName = updatedName.replace("(M)", "Men's");
    }else if(updatedName.includes("(W)")){
        updatedName = updatedName.replace("(W)", "Women's");
    }else if(updatedName.includes("(B)")){
        updatedName = updatedName.replace("(B)", "Boys'");
    }else if(updatedName.includes("(G)")){
        updatedName = updatedName.replace("(G)", "Girls'");
    }
    if(updatedName.includes("PV")){
        updatedName = updatedName.replace("PV", "Pole Vault");
    }else if(updatedName.includes("HJ")){
        updatedName = updatedName.replace("HJ", "High Jump");
    }else if(updatedName.includes("P.V.")){
        updatedName = updatedName.replace("P.V.", "Pole Vault");
    }else if(updatedName.includes("H.J.")){
        updatedName = updatedName.replace("H.J.", "High Jump");
    }
    return updatedName + " - Results";*/
}

function filterData(_results_, _eventProgression_) {

    let filteredData = [];
    _results_.map( athlete => filteredData.push([getVerticalPlace(athlete[7]), athlete[10], athlete[12], athlete[11], getAttempts(athlete[7], athlete[13], _eventProgression_), getBestMark(athlete[7], athlete[13], _eventProgression_), athlete[9]]) );
    return filteredData;
}

function getVerticalPlace(_place_) {

    if(_place_===999999) {
        return "DNS";
    }else if(_place_===999998) {
        return "-";
    }else{
        return _place_;
    }
}

function getAttempts(_place_, _attempts_, _eventProgression_) {

    let updatedAttempts = [];
    let attempts = _attempts_.split(',');
    for(let i=0; i<_eventProgression_.length; i++){
        if(attempts[i]==="DNS" || _place_===999999){
            updatedAttempts.push("");
        }else{
            if(i<attempts.length){
                updatedAttempts.push(attempts[i])
            }else{
                updatedAttempts.push("");
            }
        }
    }
    return updatedAttempts;
}

function filterByFlight(_results_, _flight_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][1]===_flight_ || _flight_===0){
          updatedResults.push(_results_[i]);
        }
    }
    return updatedResults;
}

function filterByDivision(_results_, _division_, _teams_, _gender_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        if(checkTeamDivision(_results_[i][2], _teams_, _division_, _gender_)){
          updatedResults.push(_results_[i]);
        }
    }
    return updatedResults;
}

function checkTeamDivision(_team_, _teams_, _division_, _gender_){
    let gender = '';
    if(_gender_==="Women"){
        gender = 'F';
    }else if(_gender_==="Men"){
        gender = 'M';
    }
    for(let i=0; i<_teams_.length; i++){
        if(_teams_[i][0]===_team_ && _teams_[i][1]===gender && _teams_[i][2]===_division_){
            return true;
        }
    }
    return false;
}

function displayHeat(_heat_){
    if(document.getElementById(_heat_).style.display==="block"){
        document.getElementById(_heat_).style.display = "none";
    }else if(document.getElementById(_heat_).style.display==="none"){
        document.getElementById(_heat_).style.display = "block";
    }
    return;
}

function getBestMark(_place_, _attempts_, _eventProgression_) {

    let bestHeight = "";
    let attempts = _attempts_.split(',');
    if(_place_===999998 || _place_===999999){
        return bestHeight;
    }
    let _continue_ = true;
    for(let i=0; i<attempts.length; i++){
        if(attempts[i].includes('O') && _continue_){
            bestHeight = _eventProgression_[i];
        }
        if(attempts[i]==="XXX"){
            _continue_ = false;
        }
    }
    return bestHeight;
}

function getAthleteProgression(_progression_, _attempts_){
    let athleteProgression = [];
    let inCompetition = false;
    for(let i=0; i<_attempts_.length; i++){
        if(inCompetition && _attempts_[i]!==""){
            athleteProgression.push(_progression_[i]);
        }else if(_attempts_[i]!=="---" && _attempts_[i]!==""){
            athleteProgression.push(_progression_[i]);
            inCompetition = true;
        }
    }
    return athleteProgression;
}

function getAthleteAttempts(_attempts_){
    let athleteProgression = [];
    let inCompetition = false;
    for(let i=0; i<_attempts_.length; i++){
        if(inCompetition && _attempts_[i]!==""){
            athleteProgression.push(_attempts_[i]);
        }else if(_attempts_[i]!=="---" && _attempts_[i]!==""){
            athleteProgression.push(_attempts_[i]);
            inCompetition = true;
        }
    }
    return athleteProgression;
}

function getAttemptsTable(_place_, _progression_, _attempts_, _system_){
    if(_place_==="DNS"){
        return;
    }
    return <tr><td colspan="4"><table className="phoneVerticalAttempts">
        <tr>{getAthleteProgression(_progression_, _attempts_).map( height => <td className="phoneHeightVertical">{height}</td> )}</tr>
        <tr>{getAthleteProgression(_progression_, _attempts_).map( height => <td className="phoneHeightVertical">{getMarkConversion('1', height, _system_)}</td> )}</tr>
        <tr>{getAthleteAttempts(_attempts_).map( attempt => <td className="phoneHeightVerticalAttempts">{attempt}</td> )}</tr>
    </table></td></tr>;
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return "  (" + grade + ")";
        }
    }
    return "";
}

function getScore(_eventName_, _mark_){
    if(_mark_===undefined || _eventName_===undefined || _eventName_==="" || _mark_===""){
        return "";
    }
    if(!(_eventName_.toUpperCase().includes("DEC") || _eventName_.toUpperCase().includes("HEP") || _eventName_.toUpperCase().includes("PEN"))){
        return "";
    }
    let score = 0;
    if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && (_eventName_.toUpperCase().includes("P.V.") || _eventName_.toUpperCase().includes("PV") || _eventName_.toUpperCase().includes("POLE VAULT")) ){
        score = Math.floor( 0.2797 * Math.pow(parseFloat(_mark_)*100 - 100.00, 1.35) );
    }else if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && (_eventName_.toUpperCase().includes("H.J.") || _eventName_.toUpperCase().includes("HJ") || _eventName_.toUpperCase().includes("HIGH JUMP")) ){
        score = Math.floor( 0.8465 * Math.pow(parseFloat(_mark_)*100 - 75.00, 1.42) );
    }else if((_eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("(W)")) && (_eventName_.toUpperCase().includes("H.J.") || _eventName_.toUpperCase().includes("HJ") || _eventName_.toUpperCase().includes("HIGH JUMP")) ){
        score = Math.floor( 1.84523 * Math.pow(parseFloat(_mark_)*100 - 75.00, 1.348) );
    }
    if(score>0){
        return " (" + score + ")";
    }else{
        return "";
    }
}

function getCompiledButton(_this_, _eventName_){
    if(_eventName_.toUpperCase().includes("PEN") || _eventName_.toUpperCase().includes("HEP") || _eventName_.toUpperCase().includes("DEC"))
        return <div className="_phoneHeatButtons_">
            <button className="_phoneHeatButton_" onClick={() => _this_.setState({multiTable: false})}>Event Results</button>
            <button className="_phoneHeatButton_" onClick={() => _this_.setState({multiTable: true})}>Compiled Results</button>
        </div>;
    return;
}

function getMultisTable(_eventName_, _eventNumber_, _meetName_){
    if(_eventName_===undefined || _eventName_==="" || _eventNumber_===undefined || _eventNumber_==="" || _meetName_===undefined || _meetName_===""){
        return;
    }
    if(_eventName_.toUpperCase().includes("HEP") || _eventName_.toUpperCase().includes("PEN") || _eventName_.toUpperCase().includes("DEC")){
        return <MultisTablePhone meetName={_meetName_} eventNumber={_eventNumber_} eventName={_eventName_}/>;
    }else{
        return;
    }
}

export default PhoneResultsVertical;
