import React from 'react';
import axios from 'axios';
import './StartListPhone.css';

class StartListPhone extends React.Component {

    state = {
        meetName: this.props.match.params.id,
        session: 1,
        eventNumber: this.props.match.params.event,
        roundNumber: this.props.match.params.round,
        eventName: undefined,
        eventType: undefined,
        maxHeats: [],
        entries: [],
        roster: [],
        eventRoster: []
    }

    componentDidMount() {

        axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.state.meetName, event: this.state.eventNumber, round: this.state.roundNumber, session: this.state.session} }).then((response) => {
            if(response.data.length>0){
                this.setState({entries: response.data, eventName: response.data[0][4]});
                if(response.data[0][4].includes("CC") || response.data[0][4].includes("Cross Country")){
                    this.setState({eventType: "Cross Country"});
                }else if(response.data[0][4].includes("H.J.") || response.data[0][4].includes("High Jump") || response.data[0][4].includes("HJ")){
                    this.setState({eventType: "Field"});
                }else if(response.data[0][4].includes("P.V.") || response.data[0][4].includes("Pole Vault") || response.data[0][4].includes("PV")){
                    this.setState({eventType: "Field"});
                }else if(response.data[0][4].includes("L.J.") || response.data[0][4].includes("Long Jump") || response.data[0][4].includes("LJ")){
                    this.setState({eventType: "Field"});
                }else if(response.data[0][4].includes("T.J.") || response.data[0][4].includes("Triple Jump") || response.data[0][4].includes("TJ")){
                    this.setState({eventType: "Field"});
                }else if(response.data[0][4].includes("Discus")){
                    this.setState({eventType: "Field"});
                }else if(response.data[0][4].includes("Javelin")){
                    this.setState({eventType: "Field"});
                }else if(response.data[0][4].includes("Shot")){
                    this.setState({eventType: "Field"});
                }else if(response.data[0][4].includes("Hammer")){
                    this.setState({eventType: "Field"});
                }else if(response.data[0][4].includes("Weight") || response.data[0][4].includes("Weigth")){
                    this.setState({eventType: "Field"});
                }else{
                    this.setState({eventType: "Track"});
                }
            }else{
                  this.setState({eventType: "Cross Country"});
            }

            this.setState({maxHeats: getMaxHeats(response.data)})
        });

        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});
            this.setState({eventRoster: getEventRoster(response.data, this.state.eventNumber)});
        });

    }

    componentDidUpdate() {

    }

    componentWillUnmount() {

    }

    render() {
        console.log(this.state.eventRoster);

        if(this.state.eventType==="Cross Country"){
            if(this.state.eventRoster.length>0){
                return <div className="startListPhone">
                    <div className="meetHeaderPhone">
                        <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.state.meetName + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                        <div className="meetInfoPhone">
                            <p className="meetTitlePhone">{this.state.meetName.replaceAll('_', ' ')}</p>
                            <p className="meetHostPhone">{this.state.eventName}</p>
                            <p className="meetHostPhone">{"Entries"}</p>
                        </div>
                    </div>
                    <div className="meetHeaderPhoneEmpty"></div>
                    <table className="phoneTableXC">
                        <thead>
                            <th className="bibPhoneXC">Bib #</th>
                            <th className="logoColumnPhoneXC"></th>
                            <th className="athletePhoneXC">Athlete</th>
                            <th className="_phoneTeamXC_"></th>
                        </thead>
                        {this.state.eventRoster.map(meet => <tbody><tr>
                            <td className="bibPhoneXC">{meet[0]}</td>
                            <td className="logoColumnPhone"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + meet[3] + ".png"} alt="" className="logoPhone"></img></td>
                            <td className="phoneAthleteXC"><table>
                                <tr><td className="phoneXCRowName">{meet[1] + ", " + meet[2] + getGrade(meet[0], this.state.roster)}</td></tr>
                                <tr><td className="phoneXCRowTeam">{meet[3]}</td></tr>
                            </table></td>
                        </tr></tbody>)}
                    </table>
                </div>
            }else{
                return <div className="startListPhone">
                    <div className="meetHeaderPhone">
                        <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.state.meetName + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                        <div className="meetInfoPhone">
                            <p className="meetTitlePhone">{this.state.meetName.replaceAll('_', ' ')}</p>
                            <p className="meetHostPhone">{this.state.eventName}</p>
                            <p className="meetHostPhone">{"Entries"}</p>
                        </div>
                    </div>
                    <div className="meetHeaderPhoneEmpty"></div>
                    <table className="phoneTableXC">
                        <thead>
                            <th className="bibPhoneXC">Bib #</th>
                            <th className="logoColumnPhoneXC"></th>
                            <th className="athletePhoneXC">Athlete</th>
                            <th className="_phoneTeamXC_"></th>
                        </thead>
                        {this.state.roster.map(meet => <tbody><tr>
                            <td className="bibPhoneXC">{meet[0]}</td>
                            <td className="logoColumnPhone"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + meet[3] + ".png"} alt="" className="logoPhone"></img></td>
                            <td className="phoneAthleteXC"><table>
                                <tr><td className="phoneXCRowName">{meet[1] + ", " + meet[2] + getGrade(meet[0], this.state.roster)}</td></tr>
                                <tr><td className="phoneXCRowTeam">{meet[3]}</td></tr>
                            </table></td>
                        </tr></tbody>)}
                    </table>
                </div>
            }
        }

        else if(this.state.eventType==="Field"){

            return <div><div className="meetHeaderPhone">
                <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.state.meetName + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                <div className="meetInfoPhone">
                    <p className="meetTitlePhone">{this.state.meetName.replaceAll('_', ' ')}</p>
                    <p className="meetHostPhone">{this.state.eventName}</p>
                    <p className="meetHostPhone">{"Entries"}</p>
                </div>
            </div>
            <div className="meetHeaderPhoneEmpty"></div>
            {this.state.maxHeats.map( (heatResults, index) => <div><button className="flightButtonPhone" onClick={() => displayHeat(index)}>{'Flight ' + (index+1)}</button>
                <div className="heatResults" id={index} style={{display: 'block'}}>
                    <table className="phoneTableField">
                        <thead>
                            <th className="_flightPhone_">Flight</th>
                            <th className="logoColumnPhoneXC"></th>
                            <th className="athletePhoneXC">Athlete</th>
                            {getEntryHeader(this.state.entries)}
                        </thead>
                        {getHeatList(this.state.entries, index+1).map( meet => <tbody><tr>
                            <td className="_flightPhone_">{meet[3] + "-" + meet[6]}</td>
                            <td className="logoColumnPhone"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + meet[8] + ".png"} alt="" className="logoPhone"></img></td>
                            <td className="phoneAthleteXC"><table>
                                <tr><td className="phoneXCRowName">{meet[7] + getGrade(meet[5], this.state.roster)}</td></tr>
                                <tr><td className="phoneXCRowTeam">{meet[8]}</td></tr>
                            </table></td>
                            {getEntry(meet[9], meet[8])}
                        </tr></tbody>)}
                  </table></div></div>)}
              </div>


        }else if(this.state.eventType==="Track"){
            return <div><div className="meetHeaderPhone">
                <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.state.meetName + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                <div className="meetInfoPhone">
                    <p className="meetTitlePhone">{this.state.meetName.replaceAll('_', ' ')}</p>
                    <p className="meetHostPhone">{this.state.eventName}</p>
                    <p className="meetHostPhone">{"Entries"}</p>
                </div>
            </div>
            <div className="meetHeaderPhoneEmpty"></div>

            {this.state.maxHeats.map( (heatResults, index) => <div><button className="flightButtonPhone" onClick={() => displayHeat(index)}>{'Heat ' + (index+1)}</button>
                <div className="heatResults" id={index} style={{display: 'block'}}>
                    <table className="phoneTableField">
                        <thead>
                            <th className="_flightPhone_">Heat</th>
                            <th className="logoColumnPhoneXC"></th>
                            <th className="athletePhoneXC">Athlete</th>
                            {getEntryHeader(this.state.entries)}
                        </thead>
                        {getHeatList(this.state.entries, index+1).map( meet => <tbody><tr>
                            <td className="_flightPhone_">{meet[3] + "-" + meet[6]}</td>
                            <td className="logoColumnPhone"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + meet[8] + ".png"} alt="" className="logoPhone"></img></td>
                            <td className="phoneAthleteXC"><table>
                                <tr><td className="phoneXCRowName">{meet[7] + getGrade(meet[5], this.state.roster)}</td></tr>
                                <tr><td className="phoneXCRowTeam">{meet[8]}</td></tr>
                            </table></td>
                            {getEntry(meet[9], meet[8])}
                        </tr></tbody>)}
                  </table></div></div>)}
              </div>

        }else{   return <div className="startListPhone">
            <div className="meetHeaderPhone">
                <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.state.meetName + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                <div className="meetInfoPhone">
                    <p className="meetTitlePhone">{this.state.meetName.replaceAll('_', ' ')}</p>
                    <p className="meetHostPhone">{this.state.eventName}</p>
                    <p className="meetHostPhone">{"Entries"}</p>
                </div>
            </div>
        </div>}

    }

}

function getEventRoster(_roster_, _eventNumber_){
    let updatedRoster = [];
    for(let i=0; i<_roster_.length; i++){
        if(_roster_[i][5]===_eventNumber_){
            updatedRoster.push(_roster_[i]);
        }
    }
    return updatedRoster;
}

function getHeatList(_startList_, _heat_){
    let entries = [];
    for(let i=0; i<_startList_.length; i++){
        if(_startList_[i][3]===_heat_){
            entries.push(_startList_[i])
        }
    }
    return entries;
}

function displayHeat(_heat_){
    if(document.getElementById(_heat_).style.display==="block"){
        document.getElementById(_heat_).style.display = "none";
    }else if(document.getElementById(_heat_).style.display==="none"){
        document.getElementById(_heat_).style.display = "block";
    }
    return;
}

function getMaxHeats(_startList_){
    let maxHeat = 0;
    for(let i=0; i<_startList_.length; i++){
        if(_startList_[i][3]>maxHeat){
            maxHeat = _startList_[i][3];
        }
    }
    let heats = [];
    for(let i=0; i<maxHeat; i++){
        heats.push('');
    }
    return heats;
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return "  (" + grade + ")";
        }
    }
    return "";
}

function getEntryHeader(_entries_){
    if(_entries_.length<=0){
        return <th className="phoneTeamXC"></th>;
    }
    if(_entries_[0][9]!==undefined && _entries_[0][9]!=="" && _entries_[0][9]!=="NULL" && _entries_[0][9]!==null){
        return <th className="phoneTeamXC"></th>;
    }else {
        return <th className="phoneTeamXC"></th>;
    }
}

function getEntry(_entry_, _team_){
      if(_entry_!==undefined && _entry_!=="" && _entry_!=="NULL" && _entry_!==null){
          return <td className="entryXC">{_entry_}</td>;
      }else{
          return <td className="entryXC"></td>;
      }
}


export default StartListPhone;
