import React from 'react';
import axios from 'axios';
import './MultisTable.css';

class MultisTable extends React.Component {

    state = {
        meetName: this.props.meetName,
        eventNumber: this.props.eventNumber,
        eventName: this.props.eventName,
        roster: [],
        trackResults: [],
        fieldResults: []
    }

    componentDidMount() {

        axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.state.meetName, event: this.state.eventNumber, type: "Field"} }).then((response) => {
            if(response.data.length>0){
                this.setState({fieldResults: filterFieldData(response.data)});
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.state.meetName, event: this.state.eventNumber, type: "Track"} }).then((response) => {
            if(response.data.length>0){
                this.setState({trackResults: filterTrackData(response.data)});
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});
        });

        //set interval for live results updates
        this.interval = setInterval(() => {

            //get results
            axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.state.meetName, event: this.state.eventNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0){
                    this.setState({fieldResults: filterFieldData(response.data)});
                }
            });

            axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.state.meetName, event: this.state.eventNumber, type: "Track"} }).then((response) => {
                if(response.data.length>0){
                    this.setState({trackResults: filterTrackData(response.data)});
                }
            });

        }, 15000);

    }

    componentDidUpdate(prevProps) {
        if(prevProps.eventNumber!==this.props.eventNumber){

            this.setState({eventNumber: this.props.eventNumber, trackResults: [], fieldResults: [], eventName: this.props.eventName});

            axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.props.meetName, event: this.props.eventNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0){
                    this.setState({fieldResults: filterFieldData(response.data)});
                }
            });

            axios.get("https://liveresults.trxctiming.com/api/multisResults", { params: {id: this.props.meetName, event: this.props.eventNumber, type: "Track"} }).then((response) => {
                if(response.data.length>0){
                    this.setState({trackResults: filterTrackData(response.data)});
                }
            });

        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if(this.state.fieldResults.length<=0 && this.state.trackResults<=0){
            return <div></div>;
        }
        if(this.state.eventName.toUpperCase().includes("PEN")){
            return <table className="multisTable">
                <thead>
                    <th className="multisPlace">Place</th>
                    <th className="multisLogoColumn"></th>
                    <th className="multisAthlete">Athlete</th>
                    <th className="multisRound">60 M. H.</th>
                    <th className="multisRound">H. J.</th>
                    <th className="multisRound">Shot Put</th>
                    <th className="multisRound">L. J.</th>
                    <th className="multisRound">800 M.</th>
                    <th className="multisFinalScore">Score</th>
                </thead><tbody>{getCompiledResults(this.state.fieldResults, this.state.trackResults).map( athlete => <tr className="multisTableRow">
                    <td className="multisPlace">{getFinalPlace(athlete)}</td>
                    <td className="multisLogoColumn"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} className="multisLogo" alt=""></img></td>
                    <td className="multisAthlete"><table>
                        <tr><td className="athleteMultiRowName">{athlete[2] + getGrade(athlete[1], this.state.roster)}</td></tr>
                        <tr><td className="athleteMultiRowTeam">{athlete[3]}</td></tr>
                    </table></td>
                    <td className="multisRound">{getRoundResult(athlete[4][0], athlete[4][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[5][0], athlete[5][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[6][0], athlete[6][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[7][0], athlete[7][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[8][0], athlete[8][1])}</td>
                    <td className="multisFinalScore">{getFinalScore(athlete)}</td>
                </tr>)}</tbody>
            </table>;
        }else if(this.state.eventName.toUpperCase().includes("HEP") && (this.state.eventName.toUpperCase().includes("(M)") || this.state.eventName.toUpperCase().includes("MEN"))){
            return <table className="multisTable">
                <thead>
                    <th className="multisPlace">Place</th>
                    <th className="multisLogoColumn"></th>
                    <th className="multisAthlete">Athlete</th>
                    <th className="multisRound">60 M. D.</th>
                    <th className="multisRound">L. J.</th>
                    <th className="multisRound">Shot Put</th>
                    <th className="multisRound">H. J.</th>
                    <th className="multisRound">60 M. H.</th>
                    <th className="multisRound">P. V.</th>
                    <th className="multisRound">1000 M.</th>
                    <th className="multisFinalScore">Score</th>
                </thead><tbody>{getCompiledResults(this.state.fieldResults, this.state.trackResults).map( athlete => <tr className="multisTableRow">
                    <td className="multisPlace">{getFinalPlace(athlete)}</td>
                    <td className="multisLogoColumn"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} className="multisLogo" alt=""></img></td>
                    <td className="multisAthlete"><table>
                        <tr><td className="athleteMultiRowName">{athlete[2] + getGrade(athlete[1], this.state.roster)}</td></tr>
                        <tr><td className="athleteMultiRowTeam">{athlete[3]}</td></tr>
                    </table></td>
                    <td className="multisRound">{getRoundResult(athlete[4][0], athlete[4][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[5][0], athlete[5][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[6][0], athlete[6][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[7][0], athlete[7][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[8][0], athlete[8][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[9][0], athlete[9][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[10][0], athlete[10][1])}</td>
                    <td className="multisFinalScore">{getFinalScore(athlete)}</td>
                </tr>)}</tbody>
            </table>;
        }else if(this.state.eventName.toUpperCase().includes("HEP") && (this.state.eventName.toUpperCase().includes("(W)") || this.state.eventName.toUpperCase().includes("WOMEN"))){
            return <table className="multisTable">
                <thead>
                    <th className="multisPlace">Place</th>
                    <th className="multisLogoColumn"></th>
                    <th className="multisAthlete">Athlete</th>
                    <th className="multisRound">100 M. H.</th>
                    <th className="multisRound">H. J.</th>
                    <th className="multisRound">Shot Put</th>
                    <th className="multisRound">200 M. D.</th>
                    <th className="multisRound">L. J.</th>
                    <th className="multisRound">Javelin</th>
                    <th className="multisRound">800 M.</th>
                    <th className="multisFinalScore">Score</th>
                </thead><tbody>{getCompiledResults(this.state.fieldResults, this.state.trackResults).map( athlete => <tr className="multisTableRow">
                    <td className="multisPlace">{getFinalPlace(athlete)}</td>
                    <td className="multisLogoColumn"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} className="multisLogo" alt=""></img></td>
                    <td className="multisAthlete"><table>
                        <tr><td className="athleteMultiRowName">{athlete[2] + getGrade(athlete[1], this.state.roster)}</td></tr>
                        <tr><td className="athleteMultiRowTeam">{athlete[3]}</td></tr>
                    </table></td>
                    <td className="multisRound">{getRoundResult(athlete[4][0], athlete[4][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[5][0], athlete[5][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[6][0], athlete[6][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[7][0], athlete[7][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[8][0], athlete[8][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[9][0], athlete[9][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[10][0], athlete[10][1])}</td>
                    <td className="multisFinalScore">{getFinalScore(athlete)}</td>
                </tr>)}</tbody>
            </table>;
        }else if(this.state.eventName.toUpperCase().includes("DEC")){
            return <table className="multisTable">
                <thead>
                    <th className="multisPlace">Place</th>
                    <th className="multisLogoColumn"></th>
                    <th className="multisAthlete">Athlete</th>
                    <th className="multisRound">100 M. D.</th>
                    <th className="multisRound">L. J.</th>
                    <th className="multisRound">Shot Put</th>
                    <th className="multisRound">H. J.</th>
                    <th className="multisRound">400 M. D.</th>
                    <th className="multisRound">110 M. H.</th>
                    <th className="multisRound">Discus</th>
                    <th className="multisRound">P. V.</th>
                    <th className="multisRound">Javelin</th>
                    <th className="multisRound">1500 M.</th>
                    <th className="multisFinalScore">Score</th>
                </thead><tbody>{getCompiledResults(this.state.fieldResults, this.state.trackResults).map( athlete => <tr className="multisTableRow">
                    <td className="multisPlace">{getFinalPlace(athlete)}</td>
                    <td className="multisLogoColumn"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} className="multisLogo" alt=""></img></td>
                    <td className="multisAthlete"><table>
                        <tr><td className="athleteMultiRowName">{athlete[2] + getGrade(athlete[1], this.state.roster)}</td></tr>
                        <tr><td className="athleteMultiRowTeam">{athlete[3] + ' - ' + athlete[1]}</td></tr>
                    </table></td>
                    <td className="multisRound">{getRoundResult(athlete[4][0], athlete[4][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[5][0], athlete[5][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[6][0], athlete[6][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[7][0], athlete[7][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[8][0], athlete[8][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[9][0], athlete[9][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[10][0], athlete[10][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[11][0], athlete[11][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[12][0], athlete[12][1])}</td>
                    <td className="multisRound">{getRoundResult(athlete[13][0], athlete[13][1])}</td>
                    <td className="multisFinalScore">{getFinalScore(athlete)}</td>
                </tr>)}</tbody>
            </table>;
        }
        return <div></div>;
    }
}

function getFinalPlace(_athlete_){
    for(let i=4; i<_athlete_.length-1; i++){
        if(_athlete_[i]!=="-"){
            if(_athlete_[i][0]==="DNS"){
                return "-";
            }
        }
    }
    return _athlete_[0];
}

function getFinalScore(_athlete_){
    for(let i=4; i<_athlete_.length-1; i++){
        if(_athlete_[i]!=="-"){
            if(_athlete_[i][0]==="DNS"){
                return "0";
            }
        }
    }
    return _athlete_[14];
}

function filterFieldData(_results_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        let bestMark = getBestMark(_results_[i][6], _results_[i][13]);
        updatedResults.push([_results_[i][2], _results_[i][9], _results_[i][11], _results_[i][12], bestMark, getFieldScore(_results_[i][8], bestMark, _results_[i][4])]);
    }
    return updatedResults;
}

function filterTrackData(_results_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        let time = roundTime(_results_[i][13]);
        updatedResults.push([_results_[i][2], _results_[i][9], _results_[i][11], _results_[i][12], time, getTrackScore(_results_[i][8], time, _results_[i][4])]);
    }
    return updatedResults;
}

function roundTime(_time_){

    if(_time_.substring(_time_.indexOf('.')+1).length<=2){
        return _time_;
    }
    if(_time_ === "99:99:99.999") {
        return "DNS";
    }else if(_time_ === "99:99:99.998") {
        return "DNF";
    }else if(_time_ === "99:99:99.997") {
        return "DQ";
    }else if(_time_ === "99:99:99.996") {
        return "";
    }else {
        let adjustedTime = _time_.substring(0, _time_.indexOf('.')+1);
        let decimals = _time_.substring(_time_.indexOf('.')+1);
        if(decimals>990){
            if(adjustedTime.includes(':')){
                let mins = adjustedTime.substring(0, adjustedTime.indexOf(':')+1);
                let seconds = adjustedTime.substring(adjustedTime.indexOf(':')+1);
                if(!seconds.includes(':')){
                    decimals = "000";
                    if(parseInt(seconds)<10){
                        adjustedTime = (mins + '0' + (parseInt(seconds)+1) + '.');
                    }else {
                        adjustedTime = (mins + (parseInt(seconds)+1) + '.');
                    }
                }

            }else{
                decimals = "000";
                adjustedTime = (parseInt(adjustedTime)+1) + '.';
            }
        }
        if(decimals[2]==='0'){
            decimals = decimals[0] + '.' + decimals[1] + decimals[2];
        }else if(decimals[2]!=='0'){
            decimals = decimals[0] + '.' + decimals[1] + '9';
        }
        decimals = parseFloat(decimals).toFixed(1);
        decimals = decimals[0] + decimals[2];
        return adjustedTime + decimals;
    }
}

function getFieldScore(_place_, _mark_, _eventName_) {
    let score = 0;
    if(_place_===999995 || _place_===999996 || _place_===999997 || _place_===999998 || _place_===999999 || _place_==="" || _mark_==="" || _mark_==="X" || _mark_==="NH"){
        return score;
    }
    if(_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("MAN") || _eventName_.toUpperCase().includes("(M)") || _eventName_.toUpperCase().includes("(B)") || _eventName_.toUpperCase().includes("BOY")){
        if(_eventName_.toUpperCase().includes("P.V.") || _eventName_.toUpperCase().includes("PV") || _eventName_.toUpperCase().includes("POLE VAULT")){
            score = Math.floor( 0.2797 * Math.pow(parseFloat(_mark_)*100 - 100.00, 1.35) );
        }else if(_eventName_.toUpperCase().includes("H.J.") || _eventName_.toUpperCase().includes("HJ") || _eventName_.toUpperCase().includes("HIGH JUMP")){
            score = Math.floor( 0.8465 * Math.pow(parseFloat(_mark_)*100 - 75.00, 1.42) );
        }else if(_eventName_.toUpperCase().includes("SHOT") || _eventName_.toUpperCase().includes("SP") || _eventName_.toUpperCase().includes("S. P.") || _eventName_.toUpperCase().includes("S.P.")){
            score = Math.floor( 51.39 * Math.pow(parseFloat(_mark_) - 1.50, 1.05) );
        }else if(_eventName_.toUpperCase().includes("LONG") || _eventName_.toUpperCase().includes("LJ") || _eventName_.toUpperCase().includes("L. J.") || _eventName_.toUpperCase().includes("L.J.")){
            score = Math.floor( 0.14354 * Math.pow(parseFloat(_mark_)*100 - 220.00, 1.40) );
        }else if(_eventName_.toUpperCase().includes("DISC")){
            score = Math.floor( 12.91 * Math.pow(parseFloat(_mark_) - 4.00, 1.10) );
        }else if(_eventName_.toUpperCase().includes("JAV")){
            score = Math.floor( 10.14 * Math.pow(parseFloat(_mark_) - 7.00, 1.08) );
        }
    }else if(_eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("WOMAN") || _eventName_.toUpperCase().includes("(W)") || _eventName_.toUpperCase().includes("(G)") || _eventName_.toUpperCase().includes("GIRL")){
        if(_eventName_.toUpperCase().includes("H.J.") || _eventName_.toUpperCase().includes("HJ") || _eventName_.toUpperCase().includes("HIGH JUMP")){
            score = Math.floor( 1.84523 * Math.pow(parseFloat(_mark_)*100 - 75.00, 1.348) );
        }else if(_eventName_.toUpperCase().includes("SHOT") || _eventName_.toUpperCase().includes("SP") || _eventName_.toUpperCase().includes("S. P.") || _eventName_.toUpperCase().includes("S.P.")){
            score = Math.floor( 56.0211 * Math.pow(parseFloat(_mark_) - 1.50, 1.05) );
        }else if(_eventName_.toUpperCase().includes("LONG") || _eventName_.toUpperCase().includes("LJ") || _eventName_.toUpperCase().includes("L. J.") || _eventName_.toUpperCase().includes("L.J.")){
            score = Math.floor( 0.188807 * Math.pow(parseFloat(_mark_)*100 - 210.00, 1.41) );
        }else if(_eventName_.toUpperCase().includes("JAV")){
            score = Math.floor( 15.9803 * Math.pow(parseFloat(_mark_) - 3.80, 1.04) );
        }
    }
    if(isNaN(score)){
      score = 0;
    }
    return score;
}

function getTrackScore(_place_, _mark_, _eventName_) {
    let score = 0;
    if(_place_===999995 || _place_===999996 || _place_===999997 || _place_===999998 || _place_===999999 || _place_==="" || _mark_===""){
        return score;
    }
    let timeInSeconds = getSeconds(_mark_);
    if(_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("MAN") || _eventName_.toUpperCase().includes("(M)") || _eventName_.toUpperCase().includes("(B)") || _eventName_.toUpperCase().includes("BOY")){
        if(_eventName_.toUpperCase().includes("60 METER HURDLES") || _eventName_.toUpperCase().includes("60 M. H.") || _eventName_.toUpperCase().includes("60 M.H.") || _eventName_.toUpperCase().includes("60 M. HURDLES")){
            score = Math.floor( 20.5173 * Math.pow(15.50 - parseFloat(timeInSeconds), 1.92) );
        }else if(_eventName_.toUpperCase().includes("60 METER DASH") || _eventName_.toUpperCase().includes("60 M. D.") || _eventName_.toUpperCase().includes("60 M.D.")){
            score = Math.floor( 58.0150 * Math.pow(11.50 - parseFloat(timeInSeconds), 1.81) );
        }else if(_eventName_.toUpperCase().includes("1000")){
            score = Math.floor( 0.08713 * Math.pow(305.50 - parseFloat(timeInSeconds), 1.85) );
        }else if(_eventName_.toUpperCase().includes("100 METER DASH") || _eventName_.toUpperCase().includes("100 M. D.") || _eventName_.toUpperCase().includes("100 M.D.") || _eventName_.toUpperCase().includes("100 METER D.") || _eventName_.toUpperCase().includes("100 M. DASH")){
            score = Math.floor( 25.4347 * Math.pow(18.00 - parseFloat(timeInSeconds), 1.81) );
        }else if(_eventName_.toUpperCase().includes("400 METER DASH") || _eventName_.toUpperCase().includes("400 M. D.") || _eventName_.toUpperCase().includes("400 M.D.") || _eventName_.toUpperCase().includes("400 METER D.") || _eventName_.toUpperCase().includes("400 M. DASH")){
            score = Math.floor( 1.53775 * Math.pow(82.00 - parseFloat(timeInSeconds), 1.81) );
        }else if(_eventName_.toUpperCase().includes("110 METER HURDLES") || _eventName_.toUpperCase().includes("110 M. H.") || _eventName_.toUpperCase().includes("110 M.H.") || _eventName_.toUpperCase().includes("110 METER H.") || _eventName_.toUpperCase().includes("110 M. HURDLES")){
            score = Math.floor( 5.74352 * Math.pow(28.50 - parseFloat(timeInSeconds), 1.92) );
        }else if(_eventName_.toUpperCase().includes("1500")){
            score = Math.floor( 0.03768 * Math.pow(480.00 - parseFloat(timeInSeconds), 1.85) );
        }
    }else if(_eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("WOMAN") || _eventName_.toUpperCase().includes("(W)") || _eventName_.toUpperCase().includes("(G)") || _eventName_.toUpperCase().includes("GIRL")){
        if(_eventName_.toUpperCase().includes("60 METER HURDLES") || _eventName_.toUpperCase().includes("60 M. H.") || _eventName_.toUpperCase().includes("60 M.H.") || _eventName_.toUpperCase().includes("60 M. HURDLES")){
            score = Math.floor( 20.0479 * Math.pow(17.00 - parseFloat(timeInSeconds), 1.835) );
        }else if(_eventName_.toUpperCase().includes("800")){
            score = Math.floor( 0.11193 * Math.pow(254.00 - parseFloat(timeInSeconds), 1.88) );
        }else if(_eventName_.toUpperCase().includes("100 METER HURDLES") || _eventName_.toUpperCase().includes("100 M. H.") || _eventName_.toUpperCase().includes("100 M.H.") || _eventName_.toUpperCase().includes("100 M. H")){
            score = Math.floor( 9.23076 * Math.pow(26.70 - parseFloat(timeInSeconds), 1.835) );
        }else if(_eventName_.toUpperCase().includes("200 M") || _eventName_.toUpperCase().includes("200 M. D.") || _eventName_.toUpperCase().includes("200 M.D.")){
            score = Math.floor( 4.99087 * Math.pow(42.50 - parseFloat(timeInSeconds), 1.81) );
        }
    }
    if(isNaN(score)){
      score = 0;
    }
    return score;
}

function getSeconds(_mark_){
    let updatedTime = _mark_;
    if(updatedTime.includes(" ")){
        updatedTime = updatedTime.substring(0, updatedTime.indexOf(" "));
    }
    if(updatedTime.includes(":")){
        let minutes = updatedTime.substring(0, updatedTime.indexOf(":"));
        let seconds = updatedTime.substring(updatedTime.indexOf(":")+1);
        updatedTime = (parseInt(minutes)*60 + parseFloat(seconds)).toFixed(2);
    }
    return updatedTime;
}

function getBestMark(_heightProgression_, _attempts_){
    let bestMark = "0.00";
    if(_attempts_==="DNS"){
        return "DNS";
    }else if(_attempts_===undefined || _attempts_==="" || _attempts_==="NULL"){
        return bestMark;
    }
    if(_heightProgression_==="NULL" || _heightProgression_===""){
        let attempts = _attempts_.split(',');
        for(let i=0; i<attempts.length; i+=2){
            if(parseFloat(attempts[i])>parseFloat(bestMark)){
                bestMark = attempts[i];
            }
        }
        if(bestMark==="0.00"){
            bestMark = "X";
        }
    }else{
        let attempts = _attempts_.split(',');
        let progression = _heightProgression_.split(',');
        progression.pop();
        progression.shift();
        for(let i=0; i<attempts.length; i++){
            if(attempts[i].includes("O") && progression.length>i){
                bestMark = progression[i];
            }
        }
        if(bestMark==="0.00"){
            bestMark = "NH";
        }
    }
    return bestMark;
}

function getCompiledResults(_fieldResults_, _trackResults_){
    let updatedResults = [];
    for(let i=0; i<_fieldResults_.length; i++){
        if(!checkAthlete(_fieldResults_[i][1], updatedResults)){
            updatedResults.push([0, _fieldResults_[i][1], _fieldResults_[i][2], _fieldResults_[i][3],"-","-","-","-","-","-","-","-","-","-",0]);
        }
        for(let j=0; j<updatedResults.length; j++){
            if(parseInt(updatedResults[j][1])===parseInt(_fieldResults_[i][1])){
                updatedResults[j][parseInt(_fieldResults_[i][0])+3] = [_fieldResults_[i][4], _fieldResults_[i][5]];
                updatedResults[j][14] = parseInt(updatedResults[j][14]) + parseInt(_fieldResults_[i][5]);
                if(_fieldResults_[i][4]==="DNS"){
                    updatedResults[j][14] = 0;
                }
            }
        }
    }
    for(let i=0; i<_trackResults_.length; i++){
        if(!checkAthlete(_trackResults_[i][1], updatedResults)){
            updatedResults.push([0, _trackResults_[i][1], _trackResults_[i][2], _trackResults_[i][3],"-","-","-","-","-","-","-","-","-","-",0]);
        }
        for(let j=0; j<updatedResults.length; j++){
            if(parseInt(updatedResults[j][1])===parseInt(_trackResults_[i][1])){
                updatedResults[j][parseInt(_trackResults_[i][0])+3] = [_trackResults_[i][4], _trackResults_[i][5]];
                updatedResults[j][14] = parseInt(updatedResults[j][14]) + parseInt(_trackResults_[i][5]);
                if(_trackResults_[i][4]==="DNS"){
                    updatedResults[j][14] = 0;
                }
            }
        }
    }
    for(let i=0; i<updatedResults.length-1; i++){
        for(let j=i+1; j<updatedResults.length; j++){
            if(parseInt(updatedResults[j][14])>parseInt(updatedResults[i][14])){
                let tempArray = updatedResults[i];
                updatedResults[i] = updatedResults[j];
                updatedResults[j] = tempArray;
            }
        }
    }
    for(let i=0; i<updatedResults.length; i++){
        if(parseInt(updatedResults[i][14])>0){
            updatedResults[i][0] = i+1;
        }else{
            updatedResults[i][0] = "-";
        }
    }
    return updatedResults;
}

function checkAthlete(_bib_, _results_){
    for(let i=0; i<_results_.length; i++){
        if(parseInt(_results_[i][1])===parseInt(_bib_)){
            return true;
        }
    }
    return false;
}

function getRoundResult(_mark_, _score_){
    if(parseInt(_score_)>0){
        return _mark_ + " (" + _score_ + ")";
    }else{
        if(_mark_==="DNS" || _mark_==="DNF" || _mark_==="NH" || _mark_==="DQ" || _mark_==="X" || _mark_==="FS"){
            return _mark_;
        }else{
            return "-";
        }
    }
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return "  (" + grade + ")";
        }
    }
    return "";
}

export default MultisTable;
