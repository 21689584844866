import './App.css';
import React from 'react';
import {BrowserRouter, Route} from 'react-router-dom';
import HomeScreen from './HomeScreen.js';
import MeetScreen from './MeetScreen.js';
import StartListPhone from './StartListPhone.js';
import ResultsPhone from './ResultsPhone.js';
import TeamResultsPhone from './TeamResultsPhone.js';
import MultisTablePhone from './MultisTablePhone.js';

function App() {
    return (

        <BrowserRouter>

            <main className="main">
                <Route path="/" exact={true} component={HomeScreen}/>
                <Route path="/meets/:id" component={MeetScreen}/>
                <Route path="/StartList/:id/:session/:event/:round" component={StartListPhone}/>
                <Route path="/Results/:type/:id/:session/:event/:round" component={ResultsPhone}/>
                <Route path="/TeamResults/:id/:team/:event" component={TeamResultsPhone}/>
                <Route path="/MultiTable/:id/:event" component={MultisTablePhone}/>
            </main>

        </BrowserRouter>
    );
}

export default App;
