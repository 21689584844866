import React from 'react';
import axios from 'axios';
import './PhoneResultsField.css';
import './StartListPhone.css';
import MultisTablePhone from './MultisTablePhone.js';

class PhoneResultsField extends React.Component {

    state = {
        meetName: this.props.meetName,
        session: this.props.session,
        eventNumber: this.props.eventNumber,
        roundNumber: this.props.roundNumber,
        eventName: this.props.eventName,
        divisions: this.props.divisions,
        eventGender: this.props.eventGender,
        totalFlights: [],
        teams: [],
        status: 0,
        system: undefined,
        headerAttempts : ["Attempt 1", "Attempt 2", "Attempt 3"],
        results: [],
        roster: [],
        entries: [],
        multiTable: false
    }

    componentDidMount() {
        this.setState({eventName: filterName(this.state.eventName)});

        axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.state.meetName, event: this.state.eventNumber, round: this.state.roundNumber, session: this.state.session} }).then((response) => {
            if(response.data.length>0){
                this.setState({entries: response.data});
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Field"} }).then((response) => {
            if(response.data.length>0){
                this.setState({system: response.data[0][5]});
                let maxAttempts = getMaxAttempts(response.data);
                let totalFlights = getFlights(response.data);
                if(maxAttempts>3){
                    let updatedAttempts = [];
                    for(let i=0; i<maxAttempts; i++){
                        updatedAttempts[i] = "Attempt " + (i+1);
                    }
                    this.setState({headerAttempts: updatedAttempts})
                }
                this.setState({results: filterData(response.data, maxAttempts), totalFlights: totalFlights});
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});
        });

        axios.get("https://liveresults.trxctiming.com/api/teams", { params: {id: this.state.meetName} }).then(response => {
            this.setState({teams: response.data});
        });

        //set interval for live results updates
        this.interval = setInterval(() => {

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0 && response.data.length>=this.state.results.length){
                      this.setState({system: response.data[0][5]});
                      let maxAttempts = getMaxAttempts(response.data);
                      let totalFlights = getFlights(response.data);
                      if(maxAttempts>3){
                          let updatedAttempts = [];
                          for(let i=0; i<maxAttempts; i++){
                              updatedAttempts[i] = "Attempt " + (i+1);
                          }
                          this.setState({headerAttempts: updatedAttempts})
                      }
                      this.setState({results: filterData(response.data, maxAttempts), totalFlights: totalFlights});
                }
            });

        }, 7000); //7s

    }

    componentDidUpdate(prevProps) {
        if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber){

            this.setState({session: this.props.session, eventNumber: this.props.eventNumber, roundNumber: this.props.roundNumber, eventName: filterName(this.props.eventName), headerAttempts : ["Attempt 1", "Attempt 2", "Attempt 3"], results: [], totalFlights: 0, status: 0, eventGender: this.props.eventGender});

            axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.props.meetName, event: this.props.eventNumber, round: this.props.roundNumber, session: this.props.session} }).then((response) => {
                if(response.data.length>0){
                    this.setState({entries: response.data});
                }
            });

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.props.meetName, session: this.props.session, event: this.props.eventNumber, round: this.props.roundNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0){
                    this.setState({system: response.data[0][5]});
                    let maxAttempts = getMaxAttempts(response.data);
                    let totalFlights = getFlights(response.data);
                    if(maxAttempts>3){
                        let updatedAttempts = [];
                        for(let i=0; i<maxAttempts; i++){
                            updatedAttempts[i] = "Attempt " + (i+1);
                        }
                        this.setState({headerAttempts: updatedAttempts})
                    }
                    this.setState({results: filterData(response.data, maxAttempts), totalFlights: totalFlights});
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if(!this.state.multiTable){
            return <div className="eventResultField">
                <div className="meetHeaderPhone">
                    <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.props.meetName + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                    <div className="meetInfoPhone">
                        <p className="meetTitlePhone">{this.state.meetName.replaceAll('_', ' ')}</p>
                        <p className="meetHostPhone">{this.state.eventName}</p>
                    </div>
                </div>
                <div className="meetHeaderPhoneEmpty"></div>
                {getCompiledButton(this, this.state.eventName)}
                {getHeaderButtons(this)}
                {this.state.status===-1 ? getStartListTable(this.state.entries, this.state.roster) : getResultsTable(this.state.headerAttempts, this.state.results, this.state.status, this.state.roster, this.state.system, this.state.eventName, this.state.totalFlights, this.state.teams, this.state.eventGender, this.state.divisions)}
            </div>}
        if(this.state.multiTable){ return <div className="eventResultField">
            <div className="meetHeaderPhone">
                <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.props.meetName + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                <div className="meetInfoPhone">
                    <p className="meetTitlePhone">{this.state.meetName.replaceAll('_', ' ')}</p>
                    <p className="meetHostPhone">{this.state.eventName}</p>
                </div>
            </div>
            <div className="meetHeaderPhoneEmpty"></div>
            {getCompiledButton(this, this.state.eventName)}
            {getMultisTable(this.state.eventName, this.state.eventNumber, this.state.meetName)}
        </div>}
    }

}

function getResultsTable(_headerAttempts_, _results_, _status_, _roster_, _system_, _eventName_, _totalFlights_, _teams_, _gender_, _divisions_){
    if(_status_===0){
        return <table className="phoneTableField">
            <thead>
                <th className="phonePlaceField">Pl.</th>
                <th className="phoneFlightField">Flight</th>
                <th className="phoneLogoColumnField"></th>
                <th className="phoneAthleteField">Athlete</th>
                <th className="phoneMarkField">Mark</th>
            </thead>
            {_results_.map( athlete => <tbody><tr>
                <td className="phonePlaceField">{athlete[0]}</td>
                <td className="phoneFlightField">{athlete[1]}</td>
                <td className="phoneLogoColumnField"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} className="phoneLogoField" alt=""></img></td>
                <td className="phoneAthleteXC"><table>
                    <tr><td className="phoneXCRowName">{athlete[4] + getGrade(athlete[7], _roster_)}</td></tr>
                    <tr><td className="phoneXCRowTeam">{athlete[3]}</td></tr>
                </table></td>
                <td><table>
                    <tr><td className="phoneMarkField">{getMark(athlete[0], athlete[6]) + getScore(_eventName_, athlete[6])}</td></tr>
                    <tr><td className="phoneMarkFieldConversion">{getMarkConversion(athlete[0], athlete[6], _system_, _eventName_)}</td></tr>
                </table></td></tr>
                {getAttemptsTable(athlete[0], athlete[5], _system_, _eventName_, getMark(athlete[0], athlete[6]))}
            </tbody>)}
        </table>;
    }else if(_status_===1){
        let _byFlightResults_ = [];
        for(let i=1; i<=_totalFlights_; i++){
            _byFlightResults_[i-1] = filterByFlight(_results_, i);
        }
        return _byFlightResults_.map( (flightResults, index) => <div><button className="flightButtonPhone" onClick={() => displayHeat(index)}>{'Flight ' + (index+1)}</button>
            <div className="heatResults" id={index} style={{display: 'block'}}>
                <table className="phoneTableField">
                    <thead>
                        <th className="phonePlaceField">Pl.</th>
                        <th className="phoneLogoColumnField"></th>
                        <th className="phoneAthleteField">Athlete</th>
                        <th className="phoneMarkField">Mark</th>
                    </thead>
                    {flightResults.map( athlete => <tbody><tr>
                        <td className="phonePlaceField">{athlete[0]}</td>
                        <td className="phoneLogoColumnField"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} className="phoneLogoField" alt=""></img></td>
                        <td className="phoneAthleteXC"><table>
                            <tr><td className="phoneXCRowName">{athlete[4] + getGrade(athlete[7], _roster_)}</td></tr>
                            <tr><td className="phoneXCRowTeam">{athlete[3]}</td></tr>
                        </table></td>
                        <td><table>
                            <tr><td className="phoneMarkField">{getMark(athlete[0], athlete[6]) + getScore(_eventName_, athlete[6])}</td></tr>
                            <tr><td className="phoneMarkFieldConversion">{getMarkConversion(athlete[0], athlete[6], _system_, _eventName_)}</td></tr>
                        </table></td></tr>
                        {getAttemptsTable(athlete[0], athlete[5], _system_, _eventName_, getMark(athlete[0], athlete[6]))}
                    </tbody>)}
                </table>
            </div>
        </div>);
    }else if(_status_===2){
      let _byDivisionResults_ = [];
      for(let i=1; i<=_divisions_.split(',').length; i++){
          _byDivisionResults_[i-1] = filterByDivision(_results_, i, _teams_, _gender_);
      }
      return _byDivisionResults_.map( (flightResults, index) => <div><button className="flightButtonPhone" onClick={() => displayHeat(index)}>{_divisions_.split(',')[index]}</button>
          <div className="heatResults" id={index} style={{display: 'block'}}>
              <table className="phoneTableField">
                  <thead>
                      <th className="phonePlaceField">Pl.</th>
                      <th className="phoneFlightField">Flight</th>
                      <th className="phoneLogoColumnField"></th>
                      <th className="phoneAthleteField">Athlete</th>
                      <th className="phoneMarkField">Mark</th>
                  </thead>
                  {flightResults.map( athlete => <tbody><tr>
                      <td className="phonePlaceField">{getDivisionPlace(flightResults, athlete[0])}</td>
                      <td className="phoneFlightField">{athlete[1]}</td>
                      <td className="phoneLogoColumnField"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[3] + ".png"} className="phoneLogoField" alt=""></img></td>
                      <td className="phoneAthleteXC"><table>
                          <tr><td className="phoneXCRowName">{athlete[4] + getGrade(athlete[7], _roster_)}</td></tr>
                          <tr><td className="phoneXCRowTeam">{athlete[3]}</td></tr>
                      </table></td>
                      <td><table>
                          <tr><td className="phoneMarkField">{getMark(athlete[0], athlete[6]) + getScore(_eventName_, athlete[6])}</td></tr>
                          <tr><td className="phoneMarkFieldConversion">{getMarkConversion(athlete[0], athlete[6], _system_, _eventName_)}</td></tr>
                      </table></td></tr>
                      {getAttemptsTable(athlete[0], athlete[5], _system_, _eventName_, getMark(athlete[0], athlete[6]))}
                  </tbody>)}
              </table>
          </div>
      </div>);
    }
}

function getStartListTable(_entries_, _roster_){
    return <table className="phoneTableXC">
        <thead>
            <th className="_flightPhone_">Flight</th>
            <th className="logoColumnPhoneXC"></th>
            <th className="athletePhoneXC">Athlete</th>
            {getEntryHeader(_entries_)}
        </thead>
        {_entries_.map(meet => <tbody><tr>
            <td className="_flightPhone_">{meet[3] + "-" + meet[6]}</td>
            <td className="logoColumnPhone"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + meet[8] + ".png"} alt="" className="logoPhone"></img></td>
            <td className="phoneAthleteXC"><table>
                <tr><td className="phoneXCRowName">{meet[7] + getGrade(meet[5], _roster_)}</td></tr>
                <tr><td className="phoneXCRowTeam">{meet[8]}</td></tr>
            </table></td>
            {getEntry(meet[9], meet[8])}
        </tr></tbody>)}
    </table>;
}

function getHeaderButtons(_this_){
    return <table className="heatButtonsPhone"><tr>
        <td className="heatButtonPhone" onClick={() => _this_.setState({status: -1})}>{'Start List'}</td>
        <td className="heatButtonPhone" onClick={() => _this_.setState({status: 0})}>{'Compiled'}</td>
        {_this_.state.totalFlights>1 ? <td className="heatButtonPhone" onClick={() => _this_.setState({status: 1})}>{'By Flight'}</td> : null}
        {_this_.state.divisions.split(',').length>1 && _this_.state.divisions.toUpperCase()!=="JV,Varsity" ? <td className="heatButtonPhone" onClick={() => _this_.setState({status: 2})}>{'By Division'}</td> : null}
    </tr></table>;
}

function getEntryHeader(_entries_){
    if(_entries_.length<=0){
        return <th className="phoneTeamXC"></th>;
    }
    if(_entries_[0][9]!==undefined && _entries_[0][9]!=="" && _entries_[0][9]!=="NULL" && _entries_[0][9]!==null){
        return <th className="phoneTeamXC"></th>;
    }else {
        return <th className="phoneTeamXC">Entry Mark</th>;
    }
}

function getEntry(_entry_, _team_){
      if(_entry_!==undefined && _entry_!=="" && _entry_!=="NULL" && _entry_!==null){
          return <td className="entryXC">{_entry_}</td>;
      }else{
          return <td className="entryXC"></td>;
      }
}

function getDivisionPlace(_flightResults_, _place_){
    if(_place_==="-" || _place_==="DNS"){
        return _place_;
    }
    let place = 1;
    for(let i=0; i<_flightResults_.length; i++){
        if(_flightResults_[i][0]===_place_){
            return place;
        }else{
            place++;
        }
    }
    return _place_;
}

function filterByFlight(_results_, _flight_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][1]===_flight_ || _flight_===0){
          updatedResults.push(_results_[i]);
        }
    }
    return updatedResults;
}

function filterByDivision(_results_, _division_, _teams_, _gender_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        if(checkTeamDivision(_results_[i][3], _teams_, _division_, _gender_)){
          updatedResults.push(_results_[i]);
        }
    }
    return updatedResults;
}

function displayHeat(_heat_){
    if(document.getElementById(_heat_).style.display==="block"){
        document.getElementById(_heat_).style.display = "none";
    }else if(document.getElementById(_heat_).style.display==="none"){
        document.getElementById(_heat_).style.display = "block";
    }
    return;
}

function getFlights(_results_){
    let totalFlights = 0;
    //let flightArray = [];
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][3]>totalFlights){
            totalFlights = _results_[i][3];
        }
    }
    /*flightArray.push("Start List");
    flightArray.push("Compiled");
    for (let i=1; i<=totalFlights; i++){
        flightArray.push("Flight " + i);
    }*/
    return totalFlights;
}

function getMarkConversion(_place_, _bestMark_, _system_, _eventName_){
    if(_place_==="" || _place_==="-" || _place_===null || _place_===undefined || _place_==="DNS"){
        return;
    }
    if(_system_==="Metric"){
        let englishMark = Math.floor((parseFloat(_bestMark_) + 0.004999) * 39.3700787402 * 100) / 100;
        let englishMarkString = "";

        let markFeet = parseInt(englishMark/12);
        let markInches = parseFloat(englishMark-markFeet*12).toFixed(2);

        if(markInches-parseInt(markInches)>0.75){
            markInches = markInches - (markInches - parseInt(markInches) - 0.75);
        }else if(markInches-parseInt(markInches)<0.75 && markInches-parseInt(markInches)>0.50){
            markInches = markInches - (markInches - parseInt(markInches) - 0.50);
        }else if(markInches-parseInt(markInches)<0.50 && markInches-parseInt(markInches)>0.25){
            markInches = markInches - (markInches - parseInt(markInches) - 0.25);
        }else if(markInches-parseInt(markInches)<0.25 && markInches-parseInt(markInches)>0.00){
            markInches = markInches - (markInches - parseInt(markInches));
        }

        if(!isNaN(markFeet) && !isNaN(markInches)){
            if(_eventName_.toUpperCase().includes("DISC") || _eventName_.toUpperCase().includes("JAV") || _eventName_.toUpperCase().includes("HAMMER")){
                englishMarkString = markFeet.toString() + "-" + parseFloat(markInches).toFixed(0);
                return englishMarkString;
            }else{
                englishMarkString = markFeet.toString() + "-" + parseFloat(markInches).toFixed(2);
                return englishMarkString;
            }
        }

        return englishMarkString;
    }
    return "";
}

function checkTeamDivision(_team_, _teams_, _division_, _gender_){
    let gender = '';
    if(_gender_==="Women"){
        gender = 'F';
    }else if(_gender_==="Men"){
        gender = 'M';
    }
    for(let i=0; i<_teams_.length; i++){
        if(_teams_[i][0]===_team_ && _teams_[i][1]===gender && _teams_[i][2]===_division_){
            return true;
        }
    }
    return false;
}

function getMark(_place_, _bestMark_){
    if(_place_==="" || _place_==="-" || _place_===null || _place_===undefined || _place_==="DNS"){
        return "NM";
    }
    return _bestMark_;
}

function filterName(_name_){
    return _name_;
    /*let updatedName = _name_;
    if(updatedName.includes("(M)")){
        updatedName = updatedName.replace("(M)", "Men's");
    }else if(updatedName.includes("(W)")){
        updatedName = updatedName.replace("(W)", "Women's");
    }else if(updatedName.includes("(B)")){
        updatedName = updatedName.replace("(B)", "Boys'");
    }else if(updatedName.includes("(G)")){
        updatedName = updatedName.replace("(G)", "Girls'");
    }
    return updatedName + " - Results";*/
}

function filterData(_results_, _maxAttempts_) {

    let filteredData = [];
    _results_.map( athlete => filteredData.push([getFieldPlace(athlete[7]), athlete[3], athlete[10], athlete[12], athlete[11], getAttempts(athlete[7], athlete[13], _maxAttempts_), getBestMark(athlete[7], athlete[13], athlete[5]), athlete[9]]) );
    return filteredData;
}

function getFieldPlace(_place_) {

    if(_place_===999999) {
        return "DNS";
    }else if(_place_===999998) {
        return "-";
    }else{
        return _place_;
    }
}

function getAttempts(_place_, _attempts_, _maxAttempts_) {

    let updatedAttempts = [];
    if(_place_===999999){
        for(let i=0; i<_maxAttempts_; i++){
            updatedAttempts[i] = "";
        }
        return updatedAttempts;
    }else{
        let attempts = _attempts_.replaceAll('F', 'X').split(',');
        for(let i=0; i<attempts.length; i+=2){
            if(attempts[i+1]==="-0.0" || attempts[i]==="F" || attempts[i]==="X" || attempts[i]==="-" || attempts[i]==="P"){
                if(attempts[i]!==undefined){
                    updatedAttempts[i/2] = attempts[i];
                }
            }else{
                if(attempts[i]!==undefined && attempts[i+1]!==undefined){
                    updatedAttempts[i/2] = attempts[i] + " (" + attempts[i+1] + ")";
                }
            }
        }
        if(updatedAttempts.length<_maxAttempts_){
            for(let i=updatedAttempts.length; i<_maxAttempts_; i++){
                updatedAttempts[i] = "";
            }
        }
    }
    return updatedAttempts;
}

function getBestMark(_place_, _attempts_, _system_) {
    if(_system_!=="English"){
        let bestMark = "0";
        let attempts = _attempts_.split(',');
        if(_place_===999999){
            return "";
        }
        for(let i=0; i<attempts.length; i+=2){
            let cAttempt = parseFloat(attempts[i]);
            let best =  parseFloat(bestMark);
            if(cAttempt > best){
                bestMark = attempts[i];
            }
        }
        if(bestMark==="0"){
            return "";
        }
        return bestMark;
    }else{
        let bestMark = "0";
        let attempts = _attempts_.split(',');
        if(_place_===999999){
            return "";
        }
        for(let i=0; i<attempts.length; i+=2){
            if(attempts[i]!=="F" && attempts[i]!=="-" && attempts[i]!=="P" && attempts[i]!=="X" && attempts[i]!==undefined && attempts[i]!==""){
                let cFeet = parseInt(attempts[i].substring(0, attempts[i].indexOf("-")));
                let cInches =  parseFloat(attempts[i].substring(attempts[i].indexOf("-")+1));
                let bFeet = parseInt(bestMark.substring(0, bestMark.indexOf("-")));
                let bInches =  parseFloat(bestMark.substring(bestMark.indexOf("-")+1));
                if(cFeet>bFeet || bestMark==="0"){
                    bestMark = attempts[i];
                }else if(cFeet===bFeet && cInches>bInches){
                    bestMark = attempts[i];
                }
            }
        }
        if(bestMark==="0"){
            return "";
        }
        return bestMark;
    }
}

function getMaxAttempts(_results_){
    let maxAttempts = 3;
    for(let i=0; i<_results_.length; i++){
        let attempts = _results_[i][13].split(',');
        if(attempts.length/2>maxAttempts && attempts.length/2>3 && attempts.length/2<7){
            maxAttempts = attempts.length/2;
        }
    }
    return maxAttempts;
}

function getAttemptsTable(_place_, _attempts_, _system_, _eventName_, _bestMark_){
    if(_place_==="DNS"){
        return;
    }
    return <tr><td colspan="5"><table className="phoneAttemptsTable"><tr>{getAthleteAttempts(_attempts_).map( attempt => <td style={getHighlight(attempt, _bestMark_)}><table className="phoneAttemptTable">
        <tr><td className="phoneAttemptRow">{attempt}</td></tr>
        <tr><td className="phoneAttemptRowConversion">{getMarkConversion(_place_, attempt, _system_, _eventName_)}</td></tr>
    </table></td> )}</tr></table></td></tr>;
}

function getHighlight(_attempt_, _bestMark_){
    if(_attempt_.substring(0, _attempt_.indexOf(' '))===_bestMark_){
        return {'background-color': "#FFBC00"};
    }
    return {'background-color': "#FFFFF0"};
}

function getAthleteAttempts(_attempts_){
    let updatedAttempts = [];
    for(let i=0; i<_attempts_.length; i++){
        if(_attempts_[i]!==""){
            updatedAttempts.push(_attempts_[i]);
        }
    }
    return updatedAttempts;
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return "  (" + grade + ")";
        }
    }
    return "";
}

function getScore(_eventName_, _bestMark_){
    if(_bestMark_===undefined || _eventName_===undefined || _eventName_==="" || _bestMark_===""){
        return "";
    }
    if(!(_eventName_.toUpperCase().includes("DEC") || _eventName_.toUpperCase().includes("HEP") || _eventName_.toUpperCase().includes("PEN"))){
        return "";
    }
    let score = 0;
    if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && (_eventName_.toUpperCase().includes("SHOT") || _eventName_.toUpperCase().includes("SP") || _eventName_.toUpperCase().includes("S. P.") || _eventName_.toUpperCase().includes("S.P.")) ){
        score = Math.floor( 51.39 * Math.pow(parseFloat(_bestMark_) - 1.50, 1.05) );
    }else if((_eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("(W)")) && (_eventName_.toUpperCase().includes("SHOT") || _eventName_.toUpperCase().includes("SP") || _eventName_.toUpperCase().includes("S. P.") || _eventName_.toUpperCase().includes("S.P.")) ){
        score = Math.floor( 56.0211 * Math.pow(parseFloat(_bestMark_) - 1.50, 1.05) );
    }else if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && (_eventName_.toUpperCase().includes("LONG") || _eventName_.toUpperCase().includes("LJ") || _eventName_.toUpperCase().includes("L. J.") || _eventName_.toUpperCase().includes("L.J.")) ){
        score = Math.floor( 0.14354 * Math.pow(parseFloat(_bestMark_)*100 - 220.00, 1.40) );
    }else if((_eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("(W)")) && (_eventName_.toUpperCase().includes("LONG") || _eventName_.toUpperCase().includes("LJ") || _eventName_.toUpperCase().includes("L. J.") || _eventName_.toUpperCase().includes("L.J.")) ){
        score = Math.floor( 0.188807 * Math.pow(parseFloat(_bestMark_)*100 - 210.00, 1.41) );
    }else if((_eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("(W)")) && _eventName_.toUpperCase().includes("JAV")){
        score = Math.floor( 15.9803 * Math.pow(parseFloat(_bestMark_) - 3.80, 1.04) );
    }else if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && _eventName_.toUpperCase().includes("DISC")){
        score = Math.floor( 12.91 * Math.pow(parseFloat(_bestMark_) - 4.00, 1.10) );
    }else if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && _eventName_.toUpperCase().includes("JAV")){
        score = Math.floor( 10.14 * Math.pow(parseFloat(_bestMark_) - 7.00, 1.08) );
    }
    if(score>0){
        return " (" + score + ")";
    }else{
        return "";
    }
}

function getCompiledButton(_this_, _eventName_){
    if(_eventName_.toUpperCase().includes("PEN") || _eventName_.toUpperCase().includes("HEP") || _eventName_.toUpperCase().includes("DEC"))
        return <div className="_phoneHeatButtons_">
            <button className="_phoneHeatButton_" onClick={() => _this_.setState({multiTable: false})}>Event Results</button>
            <button className="_phoneHeatButton_" onClick={() => _this_.setState({multiTable: true})}>Compiled Results</button>
        </div>;
    return;
}

function getMultisTable(_eventName_, _eventNumber_, _meetName_){
    if(_eventName_===undefined || _eventName_==="" || _eventNumber_===undefined || _eventNumber_==="" || _meetName_===undefined || _meetName_===""){
        return;
    }
    if(_eventName_.toUpperCase().includes("HEP") || _eventName_.toUpperCase().includes("PEN") || _eventName_.toUpperCase().includes("DEC")){
        return <MultisTablePhone meetName={_meetName_} eventNumber={_eventNumber_} eventName={_eventName_}/>;
    }else{
        return;
    }
}

export default PhoneResultsField;
