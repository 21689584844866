import React from 'react';
import axios from 'axios';
import './TeamResultsPhone.css';

class TeamResultsPhone extends React.Component {

    state = {
        meetName: this.props.match.params.id,
        teamName: this.props.match.params.team,
        eventNumber: this.props.match.params.event,
        eventName: '',
        results: [],
        roster: []
    }

    componentDidMount() {

        //get meet roster
        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});

            //get results
            axios.get("https://liveresults.trxctiming.com/api/teamResults", { params: {id: this.state.meetName, team: this.state.teamName, event: this.state.eventNumber} }).then((response) => {
                if(response.data.length>0){
                    this.setState({eventName: response.data[0][4]})
                    this.setState({results: getResults(filterData(response.data), this.state.roster)});
                }
            });
        });

        //set interval for live results updates
        this.interval = setInterval(() => {
            //get results
            axios.get("https://liveresults.trxctiming.com/api/teamResults", { params: {id: this.state.meetName, team: this.state.teamName, event: this.state.eventNumber} }).then((response) => {
                if(response.data.length>0){
                    this.setState({results: getResults(filterData(response.data), this.state.roster)});
                }
            });

        }, 17000);

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return <div className="teamResultsPhone">
            <div className="meetHeaderPhone">
                <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.props.match.params.id + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                <div className="meetInfoPhone">
                    <p className="meetTitlePhone">{this.props.match.params.id.replaceAll('_', ' ')}</p>
                    <p className="meetHostPhone">{this.props.match.params.team}</p>
                    <p className="meetHostPhone">{this.state.eventName}</p>
                </div>
            </div>
            <div className="meetHeaderPhoneEmpty"></div>

            <div className="teamLogoByTeamDivPhone">
                <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" +  this.state.teamName + ".png"} className="teamLogoByTeamPhone" alt=""></img>
                <div>
                    {getTeamTop5Avg(this.state.results)}
                    {getTop5Spread(this.state.results)}
                </div>
            </div>

            <table className="phoneTableXC">
                <thead>
                    <th className="phonePlaceXC">Pl.</th>
                    <th className="phoneLogoColumnXC"></th>
                    <th className="phoneAthleteXC">Athlete</th>
                    <th className="phoneMarkXC">Time</th>
                </thead>
                {this.state.results.map( athlete => <tbody><tr>
                    <td className="phonePlaceXC">{athlete[0]}</td>
                    <td className="phoneLogoColumnXC"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[2] + ".png"} className="phoneLogoXC" alt=""></img></td>
                    <td className="phoneAthleteXC"><table>
                        <tr><td className="phoneXCRowName">{athlete[3] + getGrade(athlete[1], this.state.roster)}</td></tr>
                        <tr><td className="phoneXCRowTeam">{athlete[2]}</td></tr>
                    </table></td>
                    <td className="phoneMarkXC">{athlete[4]}</td></tr>
                </tbody>)}
            </table>
        </div>
    }

}

function getTop5Spread(_teamResults_){
    if(_teamResults_.length<5){
      return <div className="top5AvgDesktop">
          <p>{"Top 5 Spread Time: NTS"}</p>
      </div>;
    }
    /////////////////////////////////////////////////
    let firstTime = 0.0;
    if(_teamResults_[0][4].length<=0){
      return <div className="top5AvgDesktop">
          <p>{"Top 5 Spread Time: NTS"}</p>
      </div>;
    }
    let h=0, m=0, s=0, d=0;
    let time = _teamResults_[0][4];
    if(time.includes(':', time.indexOf(':')+1)){
        h = parseInt(time.substring(0, time.indexOf(':')));
        firstTime += h*3600;
        time = time.substring(time.indexOf(':')+1);
    }
    if(time.includes(':')){
        m = parseInt(time.substring(0, time.indexOf(':')));
        firstTime += m*60;
        time = time.substring(time.indexOf(':')+1);
    }
    if(time.includes('.')){
        s = parseInt(time.substring(0, time.indexOf('.')));
        d = parseInt(time.substring(time.indexOf('.')+1));
        firstTime += (s+d/100);
    }
    ////////////////////////////////////////////////////
    let fifthTime = 0.0;
    if(_teamResults_[4][4].length<=0){
      return <div className="top5AvgDesktop">
          <p>{"Top 5 Spread Time: NTS"}</p>
      </div>;
    }
    h=0; m=0; s=0; d=0;
    time = _teamResults_[4][4]
    if(time.includes(':', time.indexOf(':')+1)){
        h = parseInt(time.substring(0, time.indexOf(':')));
        fifthTime += h*3600;
        time = time.substring(time.indexOf(':')+1);
    }
    if(time.includes(':')){
        m = parseInt(time.substring(0, time.indexOf(':')));
        fifthTime += m*60;
        time = time.substring(time.indexOf(':')+1);
    }
    if(time.includes('.')){
        s = parseInt(time.substring(0, time.indexOf('.')));
        d = parseInt(time.substring(time.indexOf('.')+1));
        fifthTime += (s+d/100);
    }
    ////////////////////////////////////////////////////////
    let timeGap = fifthTime - firstTime;
    if(timeGap<=0){
        return;
    }
    h=0; m=0; s=0.0;
    if(timeGap>=3600){
        h = parseInt(timeGap/3600);
        timeGap = timeGap-3600*h;
    }
    if(timeGap>=60){
        m = parseInt(timeGap/60);
        timeGap = timeGap-60*m;
    }
    s = timeGap;
    let gapTimeString = '';
    if(h>0){
        gapTimeString += (h.toString() + ':');
    }
    if(m>0 && h<=0){
        gapTimeString += (m.toString() + ':');
    }else if(m>0 && m<10 && h>0){
        gapTimeString += ('0' + m.toString() + ':');
    }
    if(s<10 && (m>0 || h>0)){
        gapTimeString += '0';
    }
    gapTimeString += s.toFixed(2);
    return <div className="top5AvgDesktop">
        <p>{"Top 5 Spread Time: " + gapTimeString}</p>
    </div>;
}

function getTeamTop5Avg(_teamResults_){
    if(_teamResults_.length<5){
      return <div className="top5AvgDesktop">
          <p>{"Top 5 Average Time: NTS"}</p>
      </div>;
    }
    let totalTime = 0.0;
    for(let i=0; i<5 && i<_teamResults_.length; i++){
        if(_teamResults_[i][4].length<=0){
          return <div className="top5AvgDesktop">
              <p>{"Top 5 Average Time: NTS"}</p>
          </div>;
        }
        let h=0, m=0, s=0, d=0;
        let athleteTime = 0.0;
        let time = _teamResults_[i][4]
        if(time.includes(':', time.indexOf(':')+1)){
            h = parseInt(time.substring(0, time.indexOf(':')));
            athleteTime += h*3600;
            time = time.substring(time.indexOf(':')+1);
        }
        if(time.includes(':')){
            m = parseInt(time.substring(0, time.indexOf(':')));
            athleteTime += m*60;
            time = time.substring(time.indexOf(':')+1);
        }
        if(time.includes('.')){
            s = parseInt(time.substring(0, time.indexOf('.')));
            d = parseInt(time.substring(time.indexOf('.')+1));
            athleteTime += (s+d/100);
        }
        totalTime += athleteTime;
    }
    let avgTimeDouble = (totalTime/5);
    let h=0, m=0, s=0.0;
    if(avgTimeDouble>=3600){
        h = parseInt(avgTimeDouble/3600);
        avgTimeDouble = avgTimeDouble-3600*h;
    }
    if(avgTimeDouble>=60){
        m = parseInt(avgTimeDouble/60);
        avgTimeDouble = avgTimeDouble-60*m;
    }
    s = avgTimeDouble;
    let avgTimeString = '';
    if(h>0){
        avgTimeString += (h.toString() + ':');
    }
    if(m>0 && h<=0){
        avgTimeString += (m.toString() + ':');
    }else if(m>0 && m<10 && h>0){
        avgTimeString += ('0' + m.toString() + ':');
    }
    if(s<10){
        avgTimeString += '0';
    }
    avgTimeString += s.toFixed(2);
    return <div className="top5AvgDesktop">
        <p>{"Top 5 Average Time: " + avgTimeString}</p>
    </div>;
}

function filterData(_results_) {

    let filteredData = [];
    _results_.map( athlete => filteredData.push([getTrackPlace(athlete[8]), athlete[9], athlete[12], athlete[11], getTime(athlete[8], athlete[13]), athlete[15]]) );
    return filteredData;
}

function getTrackPlace(_place_) {

    if(_place_===999999 || _place_==="DNS") {
        return "DNS";
    }else if(_place_===999998 || _place_==="DNF") {
        return "DNF";
    }else if(_place_===999997 || _place_==="DQ") {
        return "DQ";
    }else if(_place_===999996 || _place_==="FS") {
        return "FS";
    }else if(_place_===999995 || _place_==="SCR") {
        return "SCR";
    }else if(_place_===999994 || _place_==="ADV") {
        return "ADV";
    }else if(_place_===999993){
        return "-";
    }else {
        return _place_;
    }
}

function getTime(_place_, _time_) {

    if(_place_>=999993){
        return "";
    }else if(_time_ === "99:99:99.999") {
        return "";
    }else if(_time_ === "99:99:99.998") {
        return "";
    }else if(_time_ === "99:99:99.997") {
        return "";
    }else if(_time_ === "99:99:99.996") {
        return "";
    }else {
        return roundTime(_time_);
    }
}

function roundTime(_time_){

    if(_time_.substring(_time_.indexOf('.')+1).length<=2){
        return _time_;
    }
    if(_time_ === "99:99:99.999") {
        return "";
    }else if(_time_ === "99:99:99.998") {
        return "";
    }else if(_time_ === "99:99:99.997") {
        return "";
    }else if(_time_ === "99:99:99.996") {
        return "";
    }else {
        let adjustedTime = _time_.substring(0, _time_.indexOf('.')+1);
        let decimals = _time_.substring(_time_.indexOf('.')+1);
        if(decimals>990){
            if(adjustedTime.includes(':')){
                let mins = adjustedTime.substring(0, adjustedTime.indexOf(':')+1);
                let seconds = adjustedTime.substring(adjustedTime.indexOf(':')+1);
                if(!seconds.includes(':')){
                    decimals = "000";
                    if(parseInt(seconds)<10){
                        adjustedTime = (mins + '0' + (parseInt(seconds)+1) + '.');
                    }else {
                        adjustedTime = (mins + (parseInt(seconds)+1) + '.');
                    }
                }

            }else{
                decimals = "000";
                adjustedTime = (parseInt(adjustedTime)+1) + '.';
            }
        }
        if(decimals[2]==='0'){
            decimals = decimals[0] + '.' + decimals[1] + decimals[2];
        }else if(decimals[2]!=='0'){
            decimals = decimals[0] + '.' + decimals[1] + '9';
        }
        decimals = parseFloat(decimals).toFixed(1);
        decimals = decimals[0] + decimals[2];
        return adjustedTime + decimals;
    }
}

function getResults(_results_, _roster_){
    let results = [];
    //Get final times
    for(let i=0; i<_results_.length; i++){
        results.push(_results_[i]);
    }
    return results;
}

/*function checkGender(_athlete_, _roster_, _gender_){
    for(let i=0; i<_roster_.length; i++){
        if(_roster_[i][0]===_athlete_[1]){
            if(_roster_[i][4]===_gender_){
                return true;
            }else{
                return false;
            }
        }
    }
    return false;
}*/

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return " (" + grade + ")";
        }
    }
    return "";
}

export default TeamResultsPhone;
