import React from 'react';
import axios from 'axios';
import './LiveResultsVerticalMulti.css';
import MultisTable from './MultisTable.js';

class LiveResultsVerticalMulti extends React.Component {

    state = {
        meetName: this.props.meetName,
        session: this.props.session,
        eventNumber: this.props.eventNumber,
        roundNumber: this.props.roundNumber,
        eventName: this.props.eventName,
        eventGender: this.props.eventGender,
        eventRecord: this.props.eventRecord,
        eventProgression: [],
        system: undefined,
        results: [],
        roster: []
    }

    componentDidMount() {
        this.setState({eventName: filterName(this.state.eventName)});

        axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Field"} }).then((response) => {
            if(response.data.length>0){
                this.setState({system: response.data[0][5]});
                let eventProgression = response.data[0][6].split(',');
                eventProgression.pop();
                eventProgression.shift();
                if(eventProgression!==undefined){
                    this.setState({results: filterData(response.data, eventProgression, this.state.eventGender), eventProgression: eventProgression});
                }
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});
        });

        //set interval for live results updates
        this.interval = setInterval(() => {

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0 && response.data.length>=this.state.results.length){
                    this.setState({system: response.data[0][5]});
                    let eventProgression = response.data[0][6].split(',');
                    eventProgression.pop();
                    eventProgression.shift();
                    if(eventProgression!==undefined){
                        this.setState({results: filterData(response.data, eventProgression, this.state.eventGender), eventProgression: eventProgression});
                    }
                }
            });

        }, 7000); //15s

    }

    componentDidUpdate(prevProps) {
        if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber){

            this.setState({session: this.props.session, eventNumber: this.props.eventNumber, roundNumber: this.props.roundNumber, eventName: filterName(this.props.eventName), eventProgression: [], results: [], eventRecord: this.state.eventRecord});

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.props.meetName, session: this.props.session, event: this.props.eventNumber, round: this.props.roundNumber, type: "Field"} }).then((response) => {
                if(response.data.length>0){
                    this.setState({system: response.data[0][5]});
                    let eventProgression = response.data[0][6].split(',');
                    eventProgression.pop();
                    eventProgression.shift();
                    if(eventProgression!==undefined){
                        this.setState({results: filterData(response.data, eventProgression, this.state.eventGender), eventProgression: eventProgression});
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if(this.state.results.length<=0){
            return <div className="eventResultVertical">
                <p className="eventNameVertical">{this.state.eventName}</p>
                <div className="eventRecordXC" style={{whiteSpace: 'pre-line'}}>{filterRecord(this.state.eventRecord)}</div>
            </div>
        }
        return <div className="eventResultVertical">
            <p className="eventNameVertical">{this.state.eventName}</p>
            <div className="eventRecordXC" style={{whiteSpace: 'pre-line'}}>{filterRecord(this.state.eventRecord)}</div>
            <table className="_resultTableVertical_">
                <thead>
                    <th className="placeVertical">Place</th>
                    <th className="orderVertical">Order</th>
                    <th className="logoColumnVertical"></th>
                    <th className="athleteVertical">Athlete</th>
                    {this.state.eventProgression.map( height => <th className="heightVerticalHeader"><table>
                        <tr><td>{height}</td></tr>
                        <tr><td>{getMarkConversion('1', height, this.state.system)}</td></tr>
                    </table></th>)}
                    <th className="markVertical">Mark</th>
                    <th className="scoreVertical">Score</th>
                </thead>
                <tbody>{this.state.results.map( athlete => <tr className="_resultTableVerticalRow_">
                    <td className="placeVertical">{athlete[0]}</td>
                    <td className="orderVertical">{athlete[1]}</td>
                    <td className="logoColumnVertical"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[2] + ".png"} className="logoVertical" alt=""></img></td>
                    <td className="athleteVertical"><table>
                        <tr><td className="athleteVerticalRowName">{athlete[3] + getGrade(athlete[6], this.state.roster)}</td></tr>
                        <tr><td className="athleteVerticalRowTeam">{athlete[2]}</td></tr>
                    </table></td>
                    {athlete[4].map( attempt => <td className="heightVertical">{attempt}</td>)}
                    <td><table>
                        <tr><td className="markVertical">{getMark(athlete[0], athlete[5])}</td></tr>
                        <tr><td className="markVerticalConversion">{getMarkConversion(athlete[0], athlete[5], this.state.system)}</td></tr>
                    </table></td>
                    <td className="scoreVertical">{athlete[6]}</td>
                </tr>)}</tbody>
            </table>

            <MultisTable meetName={this.props.meetName} eventNumber={this.props.eventNumber} eventName={this.props.eventName}/>

        </div>
    }

}

function filterRecord(_record_){
    let updatedRecord = "";
    if(_record_===undefined || _record_==="" || _record_==="NULL"){
        return updatedRecord;
    }
    if(_record_[_record_.length-1]===';'){
        updatedRecord = _record_.substring(0, _record_.length-1);
    }
    return updatedRecord.replaceAll(';', '\n');
}

function getMark(_place_, _bestMark_){
    if(_place_==="" || _place_==="-" || _place_===null || _place_===undefined || _place_==="DNS"){
        return "NH";
    }
    return _bestMark_;
}

function getMarkConversion(_place_, _bestMark_, _system_){
    if(_place_==="" || _place_==="-" || _place_===null || _place_===undefined || _place_==="DNS"){
        return;
    }
    if(_system_==="Metric"){
        let englishMark = Math.floor((parseFloat(_bestMark_) + 0.002) * 39.3700787402 * 100) / 100;

        let englishMarkString = "";

        let markFeet = parseInt(englishMark/12);
        let markInches = parseFloat(englishMark-markFeet*12).toFixed(2);

        if(markInches-parseInt(markInches)>0.75){
            markInches = markInches - (markInches - parseInt(markInches) - 0.75);
        }else if(markInches-parseInt(markInches)<0.75 && markInches-parseInt(markInches)>0.50){
            markInches = markInches - (markInches - parseInt(markInches) - 0.50);
        }else if(markInches-parseInt(markInches)<0.50 && markInches-parseInt(markInches)>0.25){
            markInches = markInches - (markInches - parseInt(markInches) - 0.25);
        }else if(markInches-parseInt(markInches)<0.25 && markInches-parseInt(markInches)>0.00){
            markInches = markInches - (markInches - parseInt(markInches));
        }

        englishMarkString = markFeet.toString() + "-" + parseFloat(markInches).toFixed(2);

        return englishMarkString;
    }
    return "";
}

function filterName(_name_){

    let updatedName = _name_;
    if(updatedName.includes("(M)")){
        updatedName = updatedName.replace("(M)", "Men's");
    }else if(updatedName.includes("(W)")){
        updatedName = updatedName.replace("(W)", "Women's");
    }else if(updatedName.includes("(B)")){
        updatedName = updatedName.replace("(B)", "Boys'");
    }else if(updatedName.includes("(G)")){
        updatedName = updatedName.replace("(G)", "Girls'");
    }
    if(updatedName.includes("PV")){
        updatedName = updatedName.replace("PV", "Pole Vault");
    }else if(updatedName.includes("HJ")){
        updatedName = updatedName.replace("HJ", "High Jump");
    }else if(updatedName.includes("P.V.")){
        updatedName = updatedName.replace("P.V.", "Pole Vault");
    }else if(updatedName.includes("H.J.")){
        updatedName = updatedName.replace("H.J.", "High Jump");
    }
    return updatedName + " - Results";
}

function filterData(_results_, _eventProgression_, _gender_) {

    let filteredData = [];
    _results_.map( athlete => filteredData.push([getVerticalPlace(athlete[7]), athlete[10], athlete[12], athlete[11], getAttempts(athlete[7], athlete[13], _eventProgression_), getBestMark(athlete[7], athlete[13], _eventProgression_), getScore(athlete[7], athlete[13], _eventProgression_, _gender_, athlete[4])]) );
    return filteredData;
}

function getVerticalPlace(_place_) {

    if(_place_===999999) {
        return "DNS";
    }else if(_place_===999998) {
        return "-";
    }else{
        return _place_;
    }
}

function getAttempts(_place_, _attempts_, _eventProgression_) {

    let updatedAttempts = [];
    let attempts = _attempts_.split(',');
    for(let i=0; i<_eventProgression_.length; i++){
        if(attempts[i]==="DNS" || _place_===999999){
            updatedAttempts.push("");
        }else{
            if(i<attempts.length){
                updatedAttempts.push(attempts[i])
            }else{
                updatedAttempts.push("");
            }
        }
    }
    return updatedAttempts;
}

function getBestMark(_place_, _attempts_, _eventProgression_) {

    let bestHeight = "";
    let attempts = _attempts_.split(',');
    if(_place_===999998 || _place_===999999){
        return bestHeight;
    }
    for(let i=0; i<attempts.length; i++){
        if(attempts[i].includes('O')){
            bestHeight = _eventProgression_[i];
        }
    }
    return bestHeight;
}

function getScore(_place_, _attempts_, _eventProgression_, _gender_, _eventName_) {
    let score = 0;
    let bestHeight = 0;
    let attempts = _attempts_.split(',');
    if(_place_===999998 || _place_===999999){
        return score;
    }
    for(let i=0; i<attempts.length; i++){
        if(attempts[i].includes('O')){
            bestHeight = _eventProgression_[i];
        }
    }
    if( _gender_==="Men" && (_eventName_.toUpperCase().includes("P.V.") || _eventName_.toUpperCase().includes("PV") || _eventName_.toUpperCase().includes("POLE VAULT")) ){
        score = Math.floor( 0.2797 * Math.pow(parseFloat(bestHeight)*100 - 100.00, 1.35) );
    }else if( _gender_==="Men" && (_eventName_.toUpperCase().includes("H.J.") || _eventName_.toUpperCase().includes("HJ") || _eventName_.toUpperCase().includes("HIGH JUMP")) ){
        score = Math.floor( 0.8465 * Math.pow(parseFloat(bestHeight)*100 - 75.00, 1.42) );
    }else if( _gender_==="Women" && (_eventName_.toUpperCase().includes("H.J.") || _eventName_.toUpperCase().includes("HJ") || _eventName_.toUpperCase().includes("HIGH JUMP")) ){
        score = Math.floor( 1.84523 * Math.pow(parseFloat(bestHeight)*100 - 75.00, 1.348) );
    }
    //console.log(bestHeight + " --> " + score);
    return score;
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return "  (" + grade + ")";
        }
    }
    return "";
}

export default LiveResultsVerticalMulti;
