import React from 'react';
import axios from 'axios';
import './PhoneResultsTrack.css';
import './StartListPhone.css';
import MultisTablePhone from './MultisTablePhone.js';
import PhoneLiveClock from './PhoneLiveClock.js';

class PhoneResultsTrack extends React.Component {

    state = {
        meetName: this.props.meetName,
        session: this.props.session,
        eventNumber: this.props.eventNumber,
        roundNumber: this.props.roundNumber,
        eventGender: this.props.eventGender,
        divisions: this.props.divisions,
        status: 0,
        totalHeats: 0,
        windReadings: [],
        wind: undefined,
        eventName: this.props.eventName,
        eventQs: this.props.eventQs,
        eventqs: this.props.eventqs,
        results: [],
        roster: [],
        entries: [],
        teams: [],
        multiTable: false,
        timeZone: this.props.timeZone
    }

    componentDidMount() {

        this.setState({eventName: filterName(this.state.eventName)});

        axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.state.meetName, event: this.state.eventNumber, round: this.state.roundNumber, session: this.state.session} }).then((response) => {
            if(response.data.length>0){
                this.setState({entries: response.data});
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Track"} }).then((response) => {
            if(response.data.length>0){
                let filteredResults = filterData(response.data, this.state.eventQs, this.state.eventqs, 0);
                let totalHeats = getHeats(filteredResults);
                let windReadings = getWindReadings(response.data);
                this.setState({results: filteredResults, totalHeats: totalHeats, windReadings: windReadings});
            }
        });

        axios.get("https://liveresults.trxctiming.com/api/roster", { params: {id: this.state.meetName} }).then((response) => {
            this.setState({roster: response.data});
        });

        axios.get("https://liveresults.trxctiming.com/api/teams", { params: {id: this.state.meetName} }).then(response => {
            this.setState({teams: response.data});
        });


        //set interval for live results updates
        this.interval = setInterval(() => {

          axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.state.meetName, session: this.state.session, event: this.state.eventNumber, round: this.state.roundNumber, type: "Track"} }).then((response) => {
              if(response.data.length>0 && response.data.length>=this.state.results.length){
                  let filteredResults = filterData(response.data, this.state.eventQs, this.state.eventqs, 0);
                  let totalHeats = getHeats(filteredResults);
                  if(totalHeats > this.state.totalHeats){
                      this.setState({totalHeats: totalHeats});
                  }
                  let windReadings = getWindReadings(response.data);
                  this.setState({windReadings: windReadings});
                  this.setState({results: filteredResults});
              }
          });

        }, 5000); //5s

    }

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber || prevState.heatNumber!==this.state.heatNumber){

            if(prevProps.eventNumber!==this.props.eventNumber || prevProps.roundNumber!==this.props.roundNumber){
                //heatNumber and totalHeats set separately to avoid wrong buttons displayed on next event clicked
                this.setState({heatNumber: 0, totalHeats: []});
            }

            this.setState({session: this.props.session, eventNumber: this.props.eventNumber, roundNumber: this.props.roundNumber, eventName: filterName(this.props.eventName), eventQs: this.props.eventQs, eventqs: this.props.eventqs, results: [], wind: undefined, status:0, eventGender: this.props.eventGender});

            axios.get("https://liveresults.trxctiming.com/api/startList", { params: {id: this.props.meetName, event: this.props.eventNumber, round: this.props.roundNumber, session: this.props.session} }).then((response) => {
                if(response.data.length>0){
                    this.setState({entries: response.data});
                }
            });

            axios.get("https://liveresults.trxctiming.com/api/results", { params: {id: this.props.meetName, session: this.props.session, event: this.props.eventNumber, round: this.props.roundNumber, type: "Track"} }).then((response) => {
                if(response.data.length>0){
                    let filteredResults = filterData(response.data, this.state.eventQs, this.state.eventqs, 0);
                    let totalHeats = getHeats(filteredResults);
                    if(totalHeats.length > this.state.totalHeats.length){
                        this.setState({totalHeats: totalHeats});
                    }
                    let windReadings = getWindReadings(response.data);
                    this.setState({windReadings: windReadings});
                    this.setState({results: filteredResults});
                }
            });
        }

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        if(!this.state.multiTable){
            return <div className="eventResultsTrack">
                <div className="meetHeaderPhoneTrack">
                    <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.props.meetName + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                    <div className="meetInfoPhone">
                        <p className="meetTitlePhone">{this.state.meetName.replaceAll('_', ' ')}</p>
                        <p className="meetHostPhone">{this.state.eventName}</p>
                        <p className="meetHostPhone"></p>
                    </div>
                </div>
                <div className="meetHeaderPhoneEmpty"></div>
                {getRunningClock(this.state.eventName, this.state.meetName, this.state.eventNumber, this.state.roundNumber, this.state.timeZone)}
                {getCompiledButton(this, this.state.eventName)}
                {getHeaderButtons(this)}
                {this.state.status===-1 ? getStartListTable(this.state.entries, this.state.roster) : getResultsTable(this.state.status, this.state.results, this.state.roster, this.state.eventName, this.state.windReadings, this.state.totalHeats, this.state.divisions, this.state.teams, this.state.eventGender)}
            </div>;
        }
        if(this.state.multiTable){ return <div className="eventResultsTrack">
            <div className="meetHeaderPhoneTrack">
                <img src={"https://trxctiming.com/liveresults.trxctiming.com/images/meets/" +  this.props.meetName + ".png"} className="meetHeaderPicturePhone" alt=""></img>
                <div className="meetInfoPhone">
                    <p className="meetTitlePhone">{this.state.meetName.replaceAll('_', ' ')}</p>
                    <p className="meetHostPhone">{this.state.eventName}</p>
                </div>
            </div>
            <div className="meetHeaderPhoneEmpty"></div>
            {getCompiledButton(this, this.state.eventName)}
            {getMultisTable(this.state.eventName, this.state.eventNumber, this.state.meetName)}
        </div>;}
    }

}

function getResultsTable(_status_, _results_, _roster_, _eventName_, _windReadings_, _totalHeats_, _divisions_, _teams_, _gender_){
    if(_status_===0){
        return <table className="phoneResultTableTrack">
            <thead>
                <th className="phonePlaceTrack">Pl.</th>
                <th className="phoneLogoColumnTrack"></th>
                <th className="phoneAthleteTrack">Athlete</th>
                <th className="phoneMarkTrack">Time</th>
            </thead>
            {_results_.map( athlete => <tbody><tr>
                <td className="phonePlaceTrack">{getResultsPlace(athlete[0], athlete[6])}</td>
                <td className="phoneLogoColumnTrack"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[4] + ".png"} className="phoneLogoTrack" alt=""></img></td>
                <td className="phoneAthleteTrack"><table>
                    <tr><td className="phoneXCRowName">{athlete[5] + getGrade(athlete[8], _roster_)}</td></tr>
                    <tr><td className="phoneXCRowTeam">{athlete[4]}</td></tr>
                </table></td>
                <td className="phoneMarkTrack"><table>
                    <tr><td className="phoneMarkTrackTime">{athlete[6] + getScore(_eventName_, athlete[6])}</td></tr>
                    <tr><td className="phoneMarkTrackWind">{getWind(_eventName_, _windReadings_, athlete[1], athlete[6])}</td></tr>
                </table></td></tr>
                {getSplits(_eventName_, athlete[7], athlete[6])}
            </tbody>)}
        </table>;
    }else if(_status_===1){
        let _byFlightResults_ = [];
        for(let i=1; i<=_totalHeats_; i++){
            _byFlightResults_[i-1] = filterByHeat(_results_, i);
        }
        return _byFlightResults_.map( (flightResults, index) => <div><button className="flightButtonPhone" onClick={() => displayHeat(index)}>{'Heat ' + (index+1)}</button>
            <div className="heatResults" id={index} style={{display: 'block'}}>
                <table className="phoneTableField">
                    <thead>
                        <th className="phonePlaceTrack">Pl.</th>
                        <th className="phoneLogoColumnTrack"></th>
                        <th className="phoneAthleteTrack">Athlete</th>
                        <th className="phoneMarkTrack">Time</th>
                    </thead>
                    {flightResults.map( athlete => <tbody><tr>
                        <td className="phonePlaceTrack">{getResultsPlace(athlete[0], athlete[6])}</td>
                        <td className="phoneLogoColumnTrack"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[4] + ".png"} className="phoneLogoTrack" alt=""></img></td>
                        <td className="phoneAthleteXC"><table>
                            <tr><td className="phoneXCRowName">{athlete[5] + getGrade(athlete[8], _roster_)}</td></tr>
                            <tr><td className="phoneXCRowTeam">{athlete[4]}</td></tr>
                        </table></td>
                        <td className="phoneMarkTrack"><table>
                            <tr><td className="phoneMarkTrackTime">{athlete[6] + getScore(_eventName_, athlete[6])}</td></tr>
                            <tr><td className="phoneMarkTrackWind">{getWind(_eventName_, _windReadings_, athlete[1], athlete[6])}</td></tr>
                        </table></td></tr>
                        {getSplits(_eventName_, athlete[7], athlete[6])}
                    </tbody>)}
                </table>
            </div>
        </div>);
    }else if(_status_===2){
      let _byDivisionResults_ = [];
      for(let i=1; i<=_divisions_.split(',').length; i++){
          _byDivisionResults_[i-1] = filterByDivision(_results_, i, _teams_, _gender_);
      }
      return _byDivisionResults_.map( (divisionResults, index) => <div><button className="flightButtonPhone" onClick={() => displayHeat(index)}>{_divisions_.split(',')[index]}</button>
          <div className="heatResults" id={index} style={{display: 'block'}}>
              <table className="phoneTableField">
                  <thead>
                      <th className="phonePlaceTrack">Pl.</th>
                      <th className="phoneLogoColumnTrack"></th>
                      <th className="phoneAthleteTrack">Athlete</th>
                      <th className="phoneMarkTrack">Time</th>
                  </thead>
                  {divisionResults.map( athlete => <tbody><tr>
                      <td className="phonePlaceTrack">{getDivisionPlace(divisionResults, getResultsPlace(athlete[0], athlete[6]))}</td>
                      <td className="phoneLogoColumnTrack"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[4] + ".png"} className="phoneLogoTrack" alt=""></img></td>
                      <td className="phoneAthleteXC"><table>
                          <tr><td className="phoneXCRowName">{athlete[5] + getGrade(athlete[8], _roster_)}</td></tr>
                          <tr><td className="phoneXCRowTeam">{athlete[4]}</td></tr>
                      </table></td>
                      <td className="phoneMarkTrack"><table>
                          <tr><td className="phoneMarkTrackTime">{athlete[6] + getScore(_eventName_, athlete[6])}</td></tr>
                          <tr><td className="phoneMarkTrackWind">{getWind(_eventName_, _windReadings_, athlete[1], athlete[6])}</td></tr>
                      </table></td></tr>
                      {getSplits(_eventName_, athlete[7], athlete[6])}
                  </tbody>)}
              </table>
          </div>
      </div>);
    }
    return <table className="phoneResultTableTrack">
        <thead>
            <th className="phonePlaceTrack">Pl.</th>
            <th className="phoneLogoColumnTrack"></th>
            <th className="phoneAthleteTrack">Athlete</th>
            <th className="phoneMarkTrack">Time</th>
        </thead>
        {_results_.map( athlete => <tbody><tr>
            <td className="phonePlaceTrack">{getResultsPlace(athlete[0], athlete[6])}</td>
            <td className="phoneLogoColumnTrack"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + athlete[4] + ".png"} className="phoneLogoTrack" alt=""></img></td>
            <td className="phoneAthleteXC"><table>
                <tr><td className="phoneXCRowName">{athlete[5] + getGrade(athlete[8], _roster_)}</td></tr>
                <tr><td className="phoneXCRowTeam">{athlete[4]}</td></tr>
            </table></td>
            <td className="phoneMarkTrack">{athlete[6] + getScore(_eventName_, athlete[6])}</td></tr>
            {getSplits(_eventName_, athlete[7], athlete[6])}
        </tbody>)}
    </table>;
}

function getStartListTable(_entries_, _roster_){
    return <table className="phoneTableXC">
        <thead>
            <th className="_flightPhone_">Heat</th>
            <th className="logoColumnPhoneXC"></th>
            <th className="athletePhoneXC">Athlete</th>
            {getEntryHeader(_entries_)}
        </thead>
        {_entries_.map(meet => <tbody><tr>
            <td className="_flightPhone_">{meet[3] + "-" + meet[6]}</td>
            <td className="logoColumnPhoneXC"><img src={"https://trxctiming.com/liveresults.trxctiming.com/images/logo/" + meet[8] + ".png"} alt={meet[8]} className="logoPhone"></img></td>
            <td className="phoneAthleteXC"><table>
                <tr><td className="phoneXCRowName">{meet[7] + getGrade(meet[5], _roster_)}</td></tr>
                <tr><td className="phoneXCRowTeam">{meet[8]}</td></tr>
            </table></td>
            {getEntry(meet[9], meet[8])}
        </tr></tbody>)}
    </table>;
}

function getHeaderButtons(_this_){
    return <table className="heatButtonsPhone"><tr>
        <td className="heatButtonPhone" onClick={() => _this_.setState({status: -1})}>{'Start List'}</td>
        <td className="heatButtonPhone" onClick={() => _this_.setState({status: 0})}>{'Compiled'}</td>
        {_this_.state.totalHeats>1 ? <td className="heatButtonPhone" onClick={() => _this_.setState({status: 1})}>{'By Heat'}</td> : null}
        {_this_.state.divisions.split(',').length>1 && _this_.state.divisions.toUpperCase()!=="JV,Varsity" ? <td className="heatButtonPhone" onClick={() => _this_.setState({status: 2})}>{'By Division'}</td> : null}
    </tr></table>;
}

function getEntryHeader(_entries_){
    if(_entries_.length<=0){
        return <th className="phoneTeamXC"></th>;
    }
    if(_entries_[0][9]!==undefined && _entries_[0][9]!=="" && _entries_[0][9]!=="NULL" && _entries_[0][9]!==null){
        return <th className="phoneTeamXC"></th>;
    }else {
        return <th className="phoneTeamXC">Entry Time</th>;
    }
}

function getEntry(_entry_, _team_){
      if(_entry_!==undefined && _entry_!=="" && _entry_!=="NULL" && _entry_!==null){
          return <td className="entryXC">{_entry_}</td>;
      }else{
          return <td className="entryXC"></td>;
      }
}

function displayHeat(_heat_){
    if(document.getElementById(_heat_).style.display==="block"){
        document.getElementById(_heat_).style.display = "none";
    }else if(document.getElementById(_heat_).style.display==="none"){
        document.getElementById(_heat_).style.display = "block";
    }
    return;
}

function getDivisionPlace(_flightResults_, _place_){
    if(_place_==="-" || _place_==="DNS" || _place_==="DQ" || _place_==="DNF" || _place_==="FS" || _place_===""){
        return _place_;
    }
    let place = 1;
    for(let i=0; i<_flightResults_.length; i++){
        if(_flightResults_[i][0]===_place_){
            return place;
        }else{
            place++;
        }
    }
    return _place_;
}

function filterByDivision(_results_, _division_, _teams_, _gender_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        if(checkTeamDivision(_results_[i][4], _teams_, _division_, _gender_)){
          updatedResults.push(_results_[i]);
        }
    }
    return updatedResults;
}

function checkTeamDivision(_team_, _teams_, _division_, _gender_){
    let gender = '';
    if(_gender_==="Women"){
        gender = 'F';
    }else if(_gender_==="Men"){
        gender = 'M';
    }
    for(let i=0; i<_teams_.length; i++){
        if(_teams_[i][0]===_team_ && _teams_[i][1]===gender && _teams_[i][2]===_division_){
            return true;
        }
    }
    return false;
}

function filterByHeat(_results_, _heat_){
    let updatedResults = [];
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][1]===_heat_ || _heat_===0){
          updatedResults.push(_results_[i]);
        }
    }
    return updatedResults;
}

function getRunningClock(_eventName_, _meetName_, _eventNumber_, _roundNumber_, _timeZone_){
    if(_eventName_.toUpperCase().includes("60 M") || _eventName_.toUpperCase().includes("100 M") || (_eventName_.toUpperCase().includes("200 M") && !_eventName_.toUpperCase().includes("3200 M")) || _eventName_.toUpperCase().includes("55 M")){
        return;
    }
    return <PhoneLiveClock meetName={_meetName_} eventNumber={_eventNumber_} roundNumber={_roundNumber_} timeZone={_timeZone_}/>;
}

function filterData(_results_, _Qs_, _qs_, _currentHeat_) {

    let filteredData = [];
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][3]===_currentHeat_ || _currentHeat_===0){
            filteredData.push( [getTrackPlace(_results_[i][8], _results_[i][13], _results_), _results_[i][3], _results_[i][10], _results_[i][9], _results_[i][12], _results_[i][11], getTime(_results_[i][8], _results_[i][13], _results_, _Qs_, _qs_), _results_[i][15], _results_[i][9]]);
        }
    }
    return filteredData;
}

function getTrackPlace(_place_, _time_, _results_) {

    if(_place_===999999 || _place_==="DNS") {
        return "DNS";
    }else if(_place_===999998 || _place_==="DNF") {
        return "DNF";
    }else if(_place_===999997 || _place_==="DQ") {
        return "DQ";
    }else if(_place_===999996 || _place_==="FS") {
        return "FS";
    }else if(_place_===999995 || _place_==="SCR") {
        return "SCR";
    }else if(_place_===999994 || _place_==="ADV") {
        return "ADV";
    }else if(_place_===999993){
        return "-";
    }else {
        for(let i=0; i<_results_.length; i++){
            if(_results_[i][13]===_time_){
                return i+1;
            }
        }
        return "-";
    }
}

function getTime(_place_, _time_, _results_, _Qs_, _qs_) {

    if(_place_>=999993){
        return "";
    }else if(_time_ === "99:99:99.999") {
        return "";
    }else if(_time_ === "99:99:99.998") {
        return "";
    }else if(_time_ === "99:99:99.997") {
        return "";
    }else if(_time_ === "99:99:99.996") {
        return "";
    }else if(_time_.substring(0, 8) === "99:99:99") {
        return "";
    }else {
        let timeCount = 0;
        for(let i=0; i<_results_.length; i++){
            if(roundTime(_results_[i][13])===roundTime(_time_)){
                timeCount++;
            }
        }
        if(timeCount>1){
            if(_place_<=_Qs_){
                return roundTime(_time_) + " (" + _time_ + ")Q";
            }else if(getTimeQualifer(_time_, _results_, _Qs_, _qs_)){
                return roundTime(_time_) + " (" + _time_ + ")q";
            }else{
                return roundTime(_time_) + " (" + _time_ + ")";
            }
        }else{
            if(_place_<=_Qs_){
                return roundTime(_time_) + "Q";
            }else if(getTimeQualifer(_time_, _results_, _Qs_, _qs_)){
                return roundTime(_time_) + "q";
            }else{
                return roundTime(_time_);
            }
        }
    }
}

function roundTime(_time_){

    if(_time_.substring(_time_.indexOf('.')+1).length<=2){
        return _time_;
    }
    if(_time_ === "99:99:99.999") {
        return "";
    }else if(_time_ === "99:99:99.998") {
        return "";
    }else if(_time_ === "99:99:99.997") {
        return "";
    }else if(_time_ === "99:99:99.996") {
        return "";
    }else {
        let adjustedTime = _time_.substring(0, _time_.indexOf('.')+1);
        let decimals = _time_.substring(_time_.indexOf('.')+1);
        if(decimals>990){
            if(adjustedTime.includes(':')){
                let mins = adjustedTime.substring(0, adjustedTime.indexOf(':')+1);
                let seconds = adjustedTime.substring(adjustedTime.indexOf(':')+1);
                if(!seconds.includes(':')){
                    decimals = "000";
                    if(parseInt(seconds)<10){
                        adjustedTime = (mins + '0' + (parseInt(seconds)+1) + '.');
                    }else {
                        adjustedTime = (mins + (parseInt(seconds)+1) + '.');
                    }
                }

            }else{
                decimals = "000";
                adjustedTime = (parseInt(adjustedTime)+1) + '.';
            }
        }
        if(decimals[2]==='0'){
            decimals = decimals[0] + '.' + decimals[1] + decimals[2];
        }else if(decimals[2]!=='0'){
            decimals = decimals[0] + '.' + decimals[1] + '9';
        }
        decimals = parseFloat(decimals).toFixed(1);
        decimals = decimals[0] + decimals[2];
        return adjustedTime + decimals;
    }
}

function getSplits(_eventName_, _splits_, _finalTime_){
    if(_splits_==="NULL" || _splits_===null || _splits_===undefined){
        return;
    }
    if(_eventName_.toUpperCase().includes("60 M") || _eventName_.toUpperCase().includes("100 M") || (_eventName_.toUpperCase().includes("200 M") && !_eventName_.toUpperCase().includes("3200 M") )){
        return;
    }
    let splits = _splits_.split(',');
    if(splits.length<1 || !_splits_.includes('.') || (_finalTime_.length>0 && splits.length<=1) ){
        return;
    }else{
        for(let i=0; i<splits.length; i++){
            let sumTime = splits[i].substring(0, splits[i].indexOf(' '));
            let splitTime = splits[i].substring(splits[i].indexOf(' ')+2, splits[i].length-1);
            splits[i] = roundTime(sumTime) + ' (' + roundTime(splitTime) + ')';
        }
    }
    return <tr><td colSpan="5"><div className="splitsDivPhoneTrack"><table align="center"  className="splitsTablePhoneTrack"><tbody><tr>{splits.map( (split, index) => <td className="splitBoxPhoneTrack">{split}</td>)}</tr></tbody></table></div></td></tr>;
}

function filterName(_name_){
    let updatedName = _name_;
    /*if(updatedName.includes("(M)")){
        updatedName = updatedName.replace("(M)", "Men's");
    }else if(updatedName.includes("(W)")){
        updatedName = updatedName.replace("(W)", "Women's");
    }else if(updatedName.includes("(B)")){
        updatedName = updatedName.replace("(B)", "Boys'");
    }else if(updatedName.includes("(G)")){
        updatedName = updatedName.replace("(G)", "Girls'");
    }*/
    return updatedName;
}

function getTimeQualifer(_time_, _results_, _Qs_, _qs_){
    let timeQualifiers = 0;
    let lastTimeIn = null;
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][8]>_Qs_){
            if(_results_[i][13]===_time_){
                if(timeQualifiers<_qs_ || lastTimeIn===_time_){
                    return true;
                }else{
                    return false;
                }
            }else{
                timeQualifiers++;
                lastTimeIn = _results_[i][13];
            }
        }
    }
    return false;
}

function getHeats(_results_){
    let totalHeats = 0;
    for(let i=0; i<_results_.length; i++){
        if(_results_[i][1]>totalHeats){
            totalHeats = _results_[i][1];
        }
    }
    return totalHeats;
}

function getWindReadings(_results_){
    let windReadings = [];
    if(_results_===undefined || _results_.length<=0){
      return windReadings;
    }
    for(let i=0; i<_results_.length; i++){
        windReadings[_results_[i][3]] = _results_[i][5];
    }
    return windReadings;
}

function getWind(_eventName_, _windReadings_, _cHeat_, _time_){
    if(_time_===undefined || _time_===null || _time_.length<=0){
      return;
    }
    if(_eventName_.includes("300") || _eventName_.includes("400")){
        return;
    }else if(_eventName_.includes("500") || _eventName_.includes("600") || _eventName_.includes("800")){
        return;
    }else if(_eventName_.includes("1000") || _eventName_.includes("1500")){
        return;
    }else if(_eventName_.includes("1600") || _eventName_.includes("1609") || _eventName_.includes("Mile")){
        return;
    }else if(_eventName_.includes("3000") || _eventName_.includes("3200")){
        return;
    }else if(_eventName_.includes("5000") || _eventName_.includes("10000")){
        return;
    }else if(_eventName_.includes("4x100") || _eventName_.includes("4x200")){
        return;
    }
    return _windReadings_[_cHeat_];
}

function getGrade(_bib_, _roster_){
    for(let i=0; i<_roster_.length; i++){
        if(_bib_===_roster_[i][0] && _roster_[i][6]!=="NULL" && _roster_[i][6]!==undefined && _roster_[i][6]!==""){
            let grade = _roster_[i][6];
            if(grade==="9"){
                grade = "FR";
            }else if(grade==="10"){
                grade = "SO";
            }else if(grade==="11"){
                grade = "JR";
            }else if(grade==="12"){
                grade = "SR";
            }
            return "  (" + grade + ")";
        }
    }
    return "";
}

function getResultsPlace(_place_, _time_){
    if(_place_==="DNS" || _place_==="DNF" || _place_==="FS" || _place_==="DQ" || _place_==="SCR"){
        return _place_;
    }
    if(_time_===""){
        return "-";
    }else{
        return _place_;
    }
}

function getScore(_eventName_, _bestMark_){
    if(_bestMark_===undefined || _eventName_===undefined || _eventName_==="" || _bestMark_===""){
        return "";
    }
    if(!(_eventName_.toUpperCase().includes("DEC") || _eventName_.toUpperCase().includes("HEP") || _eventName_.toUpperCase().includes("PEN"))){
        return "";
    }
    let score = 0;
    let timeInSeconds = getSeconds(_bestMark_);
    if((_eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("(W)")) && (_eventName_.toUpperCase().includes("60 METER HURDLES") || _eventName_.toUpperCase().includes("60 M. H.") || _eventName_.toUpperCase().includes("60 M.H.")) ){
        score = Math.floor( 20.0479 * Math.pow(17.00 - parseFloat(timeInSeconds), 1.835) );
    }else if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && (_eventName_.toUpperCase().includes("60 METER HURDLES") || _eventName_.toUpperCase().includes("60 M. H.") || _eventName_.toUpperCase().includes("60 M.H.")) ){
        score = Math.floor( 20.5173 * Math.pow(15.50 - parseFloat(timeInSeconds), 1.92) );
    }else if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && (_eventName_.toUpperCase().includes("60 METER DASH") || _eventName_.toUpperCase().includes("60 M. D.") || _eventName_.toUpperCase().includes("60 M.D.")) ){
        score = Math.floor( 58.0150 * Math.pow(11.50 - parseFloat(timeInSeconds), 1.81) );
    }else if((_eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("(W)")) && (_eventName_.toUpperCase().includes("800")) ){
        score = Math.floor( 0.11193 * Math.pow(254.00 - parseFloat(timeInSeconds), 1.88) );
    }else if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && (_eventName_.toUpperCase().includes("1000")) ){
        score = Math.floor( 0.08713 * Math.pow(305.50 - parseFloat(timeInSeconds), 1.85) );
    }else if((_eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("(W)")) && (_eventName_.toUpperCase().includes("100 METER H.") || _eventName_.toUpperCase().includes("100 METER HURDLES") || _eventName_.toUpperCase().includes("100 M. H.") || _eventName_.toUpperCase().includes("100 M.H.")) ){
        score = Math.floor( 9.23076 * Math.pow(26.70 - parseFloat(timeInSeconds), 1.835) );
    }else if((_eventName_.toUpperCase().includes("WOMEN") || _eventName_.toUpperCase().includes("(W)")) && (_eventName_.toUpperCase().includes("200 METER DASH") || _eventName_.toUpperCase().includes("200 M. D.") || _eventName_.toUpperCase().includes("200 M.D.")) ){
        score = Math.floor( 4.99087 * Math.pow(42.50 - parseFloat(timeInSeconds), 1.81) );
    }else if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && (_eventName_.toUpperCase().includes("100 METER DASH") || _eventName_.toUpperCase().includes("100 M. D.") || _eventName_.toUpperCase().includes("100 M.D.") || _eventName_.toUpperCase().includes("100 METER D.")) ){
        score = Math.floor( 25.4347 * Math.pow(18.00 - parseFloat(timeInSeconds), 1.81) );
    }else if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && (_eventName_.toUpperCase().includes("400 METER DASH") || _eventName_.toUpperCase().includes("400 M. D.") || _eventName_.toUpperCase().includes("400 M.D.") || _eventName_.toUpperCase().includes("400 METER D.") || _eventName_.toUpperCase().includes("400 M. DASH")) ){
        score = Math.floor( 1.53775 * Math.pow(82.00 - parseFloat(timeInSeconds), 1.81) );
    }else if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && (_eventName_.toUpperCase().includes("110 METER HURDLES") || _eventName_.toUpperCase().includes("110 M. H.") || _eventName_.toUpperCase().includes("110 M.H.") || _eventName_.toUpperCase().includes("110 METER H.")) ){
        score = Math.floor( 5.74352 * Math.pow(28.50 - parseFloat(timeInSeconds), 1.92) );
    }else if((_eventName_.toUpperCase().includes("MEN") || _eventName_.toUpperCase().includes("(M)")) && _eventName_.toUpperCase().includes("1500")){
        score = Math.floor( 0.03768 * Math.pow(480.00 - parseFloat(timeInSeconds), 1.85) );
    }
    if(score>0){
        return " (" + score + ")";
    }else{
        return "";
    }
}

function getSeconds(_mark_){
    let updatedTime = _mark_;
    if(updatedTime.includes(" ")){
        updatedTime = updatedTime.substring(0, updatedTime.indexOf(" "));
    }
    if(updatedTime.includes(":")){
        let minutes = updatedTime.substring(0, updatedTime.indexOf(":"));
        let seconds = updatedTime.substring(updatedTime.indexOf(":")+1);
        updatedTime = (parseInt(minutes)*60 + parseFloat(seconds)).toFixed(2);
    }
    return updatedTime;
}

function getCompiledButton(_this_, _eventName_){
    if(_eventName_.toUpperCase().includes("PEN") || _eventName_.toUpperCase().includes("HEP") || _eventName_.toUpperCase().includes("DEC"))
        return <div className="_phoneHeatButtons_">
            <button className="_phoneHeatButton_" onClick={() => _this_.setState({multiTable: false})}>Event Results</button>
            <button className="_phoneHeatButton_" onClick={() => _this_.setState({multiTable: true})}>Compiled Results</button>
        </div>;
    return;
}

function getMultisTable(_eventName_, _eventNumber_, _meetName_){
    if(_eventName_===undefined || _eventName_==="" || _eventNumber_===undefined || _eventNumber_==="" || _meetName_===undefined || _meetName_===""){
        return;
    }
    if(_eventName_.toUpperCase().includes("HEP") || _eventName_.toUpperCase().includes("PEN") || _eventName_.toUpperCase().includes("DEC")){
        return <MultisTablePhone meetName={_meetName_} eventNumber={_eventNumber_} eventName={_eventName_}/>;
    }else{
        return;
    }
}

export default PhoneResultsTrack;
